import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  FormControl,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  Snackbar,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { logException, appInsights } from '../../services/appInsights';
import GeneralInformation from './SeniorPlanning2Components/GeneralInformation';
import SpiritualInformation from './SeniorPlanning2Components/SpiritualInformation';
import HealthInsurancePlan from './SeniorPlanning2Components/HealthInsurancePlan';
import ExpandingList from '../ExpandingList/ExpandingList';
import TransportationPlan from './SeniorPlanning2Components/TransportationPlan';
import SummerHousingPlan from './SeniorPlanning2Components/SummerHousingPlan';
import SummerEmploymentPlan from './SeniorPlanning2Components/SummerEmploymentPlan';
import FallPlan from './SeniorPlanning2Components/FallPlan';
import BackupPlan from './SeniorPlanning2Components/BackupPlan';
import Scoring from './SeniorPlanning2Components/Scoring';
import { callApi } from '../../util/apiWrapper';
import { getCurrentDateTime } from '../../util/dateUtil';
import { createPrintedList } from '../../util/stringUtil';
import { btNavyBlueColor } from '../../assets/jss/nextjs-material-kit-pro';
import { titleize } from '../../util/stringUtil';
import RequiredInfoDialog from '../Shared/RequiredInfoDialog';
import ConfirmSubmitDialog from './SeniorPlanning2Dialogs/ConfirmSubmitDialog';
import CloseIcon from '@material-ui/icons/Close';
import PleaseWait from '../Shared/PleaseWait';
import useAuthRedirect from '../../hooks/authRedirectHook';

const formStyles = makeStyles((theme) => ({
  subTitle: {
    margin: '15px 0',
    fontSize: '1.05rem',
    fontWeight: 500,
  },
  firstSectionSpacer: {
    height: 40,
  },
  sectionSpacer: {
    height: 60,
    padding: '10px 0',
  },
  rowSpacer: {
    height: 30,
  },
  textRowSpacer: {
    [theme.breakpoints.up('sm')]: {
      height: 50,
    },
  },
  textArea5RowSpacer: {
    [theme.breakpoints.down('sm')]: {
      height: 85,
    },
    [theme.breakpoints.up('sm')]: {
      height: 75,
    },
  },
  textArea10RowSpacer: {
    height: 185,
  },
  textRowLabelSpacer: {
    [theme.breakpoints.down('md')]: {
      height: 90,
      paddingTop: 5,
      overflow: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      height: 50,
      paddingTop: 8,
      overflow: 'auto',
    },
  },
  textRowLargeLabelSpacer: {
    [theme.breakpoints.down('sm')]: {
      height: 60,
      paddingTop: 5,
    },
    [theme.breakpoints.up('sm')]: {
      height: 50,
      paddingTop: 8,
    },
  },
  buttonWidth: {
    width: 110,
  },
  buttonSpacer: {
    height: 50,
    marginTop: 20,
  },
  firstSectionTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.00rem',
      fontWeight: 600,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.10rem',
      fontWeight: 600,
    },
  },
  sectionTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.00rem',
      fontWeight: 600,
      margin: '8px 0',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.10rem',
      fontWeight: 600,
      margin: '15px 0',
    },
  },
  sectionSubTitle: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.80rem',
      fontWeight: 600,
      margin: '2px 0',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.90rem',
      fontWeight: 600,
      margin: '5px 0',
    },
  },
  disabledSectionTitle: {
    color: 'gray',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.00rem',
      fontWeight: 600,
      margin: '8px 0',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.10rem',
      fontWeight: 600,
      margin: '15px 0',
    },
  },
  hideForXs: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  hideForMd: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  radioLabel: {
    fontSize: '0.85rem',
    fontWeight: 500,
    '& svg': {
      width: '1.25rem',
      height: '1.25rem',
    },
  },
  radioRow: {
    width: '100%',
    justifyContent: 'space-between',
  },
  datepicker: {
    width: '230px',
    '& .MuiFilledInput-root': {
      background: 'rgb(255, 255, 255)',
    },
  },
  datePickerRowSpacer: {
    height: 50,
    paddingTop: 8,
    marginBottom: 10,
  },
  reminder: {
    color: 'blue',
    fontSize: '1.05rem',
    margin: '10px 0',
  },
  [theme.breakpoints.down('xs')]: {
    textField: {
      fontSize: '0.75rem',
    },
    root: {
      '& .MuiFormLabel-root': {
        fontSize: '0.75rem',
      },
    },
  },
  bigLabel: {
    fontSize: '0.85rem',
  },
  addButton: {
    padding: 0,
    color: btNavyBlueColor,
  },
  topMargin10: {
    marginTop: 10,
  },
  printedContent: {
    '@media print': {
      marginBottom: 10,
    },
    '@media screen': {
      display: 'none',
    },
  },
  unprintedContent: {
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  alignPrintedContent: {
    lineHeight: '25px',
    verticalAlign: 'top',
  },
  commentSaveNote: {
    fontSize: '0.90rem',
    fontWeight: 600,
    color: 'blue',
  },
  meetingSaveNote: {
    fontSize: '0.90rem',
    fontWeight: 600,
    color: '#808080',
  },
  floatingSaveButton: {
    width: 210,
    position: 'absolute',
    right: 15,
    bottom: 15,
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  floatingSubmitButton: {
    width: 210,
    position: 'absolute',
    left: 15,
    bottom: 15,
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  note: {
    color: 'blue',
  },
  warning: {
    backgroundColor: '#fdb515',
  },
  space10After: {
    marginBottom: 10,
  },
  totalScore: {
    color: btNavyBlueColor,
    fontSize: '1.00rem',
    fontWeight: 600,
    paddingBottom: 25,
  },
  tasksHeader: {
    fontSize: '1.05rem',
    fontWeight: 600,
    margin: '10px, 0, 5px 0',
  },
  automaticTaskHeading: {
    fontSize: '1.00rem',
    fontWeight: 500,
    margin: '5px 0',
  },
  automaticTasksList: {
    height: 23,
    margin: '2px 0',
  },
  printedTasksList: {
    height: 25,
  },
  errorAlert: {
    backgroundColor: '#fa0000',
    width: '900px',
  },
}));

const SeniorPlanning2 = inject(
  'SeniorStore',
  'AuthStore'
)(
  observer((props) => {
    const store = props.SeniorStore;
    const auth = props.AuthStore;

    const commentRef = useRef();
    const meetingCommentRef = useRef();

    const { handleSaveReturn } = props;

    const formClasses = formStyles();

    // prettier-ignore
    const [form, setForm] = useState({
      spiritualPlan: '',
      church: '',
      healthInsPlan: '',
      healthInsCover: '',
      primaryDoctor: '',
      dentist: '',
      therapist: '',
      support: [{ "name": "" }, { "name": "" }, { "name": "" }],
      activities: [{ "name": "" }, { "name": "" }, { "name": "" }],
      driversLicense: '',
      learnersPermit: '',
      completedDriversEd: '',
      enrolledDriversEd: '',
      completeDriversEdDate: '',
      vehicle: '',
      vehicleCostPerMonth: 0,
      vehiclePaid: '',
      transportationPlans: '',
      autoInsurance: '',
      autoInsurancePaid: '',
      autoInsuranceCoverage: '',
      autoInsuranceCostPerMonth: '',
      usePublicTransportation: '',
      publicTransportation: '',
      publicTransportationCost: 0,
      publicTransportationPaid: '',
      summerHousingPlan: '',
      summerHousingPlanCost: 0,
      summerHousingExpectations: '',
      summerEmployWork: '',
      summerEmployApply: '',
      summerEmployHours: 0,
      fallPlan: '',
      fallEmploymentPlace: '',
      fallLivingArrangements: '',
      fallTransportationRequired: '',
      fallCCTradeNameLocation: '',
      fallCCTradeAccepted: '',
      fallTradeOrMajor: '',
      fallCCTradeStartDate: '',
      fallCCTradeCareerGoal: '',
      fallCCTradeLivingArrangements: '',
      fallCCTradeTransportationRequirements: '',
      fallCCTradePaymentPlan: '',
      fall4YearNameLocation: '',
      fall4YearMajor: '',
      fall4YearStartDate: '',
      fall4YearCareerGoal: '',
      fall4YearLivingArrangements: '',
      fall4YearTransportationRequirements: '',
      fall4YearFafsaDate: '',
      fall4YearCostTuition: 0,
      fall4YearCostBooks: 0,
      fall4YearCostHousing: 0,
      fall4YearCostOther: 0,
      fall4YearCostMealPlan: 0,
      fall4YearFamilyAssistance: '',
      fallMilitaryBranch: '',
      fallMilitaryMepsDate: '',
      fallMilitaryBasicTrainingDate: '',
      fallMilitaryRecruiter: '',
      fallJobCorpsLocation: '',
      fallJobCorpsProgram: '',
      fallJobCorpsStartDate: '',
      fallJobCorpsTransportation: '',
      backupPlan: '',
      backupPlanPreparations: '',
      backupPlanLiving: '',
      backupPlanCost: '',
      backupPlanNeed: '',
      backupPlanHelp: '',
      tasks: [{ "name": "" }, { "name": "" }, { "name": "" }, { "name": "" }, { "name": "" }, { "name": "" }, { "name": "" }, { "name": "" }, { "name": "" }, { "name": "" }],
      scholarships: [{ "name": "" }, { "name": "" }, { "name": "" }],
      loans: [{ "name": "" }, { "name": "" }, { "name": "" }],
      scholarshipReceivedInitial: '',
      understandWhatINeedToDoInitial: '',
      comments: [],
      fall4YearAccepted: '',
      fallCCTradeScholarships: [{ "name": "" }, { "name": "" }, { "name": "" }],
      fallCCTradeLoans: [{ "name": "" }, { "name": "" }, { "name": "" }],
      fallCCTradeFamilyAssistance: '',
      meetingComments: [],
      status: 'created',
      dispositionReason: '',
      followup: false,
      gradingSheet2Completed: -1,
      gradingSheet2Submitted: -1,
      gradingSheet2Clarity: -1,
      module4Submitted: -1,
      module4Completed: -1,
      module5Submitted: -1,
      module5Completed: -1,
      module6Submitted: -1,
      module6Completed: -1,
      fallEmploymentFullPartTime: '',
      fallMilitaryMepsDateDoNotKnow: false,
      fallMilitaryBasicTrainingDateDoNotKnow: false,
      fallJobCorpsStartDateDoNotKnow: false,
      fallMilitaryMepsDateDoNotKnowReason: '',
      fallMilitaryBasicTrainingDateDoNotKnowReason: '',
      fallJobCorpsStartDateDoNotKnowReason: '',
      automaticTasks: [],
      rubricMotivation: 0,
      rubricMotivationExplain: '',
      rubricFuture: 0,
      rubricFutureExplain: '',
      rubricSFTeam: 0,
      rubricSFTeamExplain: '',
      rubricMotivationScore: 0,
      rubricSuccess: 0
    });

    const writeSeniorPlan2 = async (closeInd) => {
      try {
        store.setLoading(false);

        if (originalStatus !== 'reopened' && form.status === 'reopened') {
          sendPlanningForm2ReopenedEmail(store.senior.id);
        }

        let updatedPlanningForm2 = null;

        if (store.planform2Obj && store.planform2Obj.id && store.planform2Obj.id > 0) {
          updatedPlanningForm2 = store.planform2Obj;
          updatedPlanningForm2.planning_form_2_data = form;
          updatedPlanningForm2.updated_at = new Date();
          updatedPlanningForm2.updated_by = auth.user.result.id;
        }

        if (updatedPlanningForm2) {
          updatedPlanningForm2 = await callApi(auth, 'planningform2', 'PUT', {
            item: updatedPlanningForm2,
          });
        } else {
          try {
            store.setLoading(true);

            const res = await callApi(auth, 'planningform2', 'POST', {
              item: {
                student_id: store.senior.id,
                created_at: new Date(),
                created_by: auth.user.result.id,
                updated_at: new Date(),
                updated_by: auth.user.result.id,
                planning_form_2_data: form,
              },
            });

            if (res.result) updatedPlanningForm2 = res.result;
            setFetchSuccess(true);

            // PUT to update planning_form_2_data in correct format
            updatedPlanningForm2.planning_form_2_data = form;
            const c = await callApi(auth, 'planningform2', 'PUT', {
              item: updatedPlanningForm2,
            });

            if (c.result) {
              updatedPlanningForm2 = res.result;
              store.setPlanform2Obj(updatedPlanningForm2);
            }

            store.setLoading(false);
          } catch (error) {
            logException(error);
            store.setLoading(false);
            console.log('Error submiting Planning Form 1:', error);
          }
        }

        appInsights.trackTrace({ message: `Planning Form 2 saved by ${auth.user.result.email}` });

        store.setLoading(false);
        closeInd && handleSaveReturn();
      } catch (error) {
        logException(error);
        store.setLoading(false);
        console.log('Error writing updated planning form 2:', error);
      }
    };

    const [addNote, setAddNote] = useState('');
    const [addMeetingNote, setAddMeetingNote] = useState('');

    const [originalStatus, setOriginalStatus] = useState('');

    const [requiredFields, setRequiredFields] = useState(null);
    const [requiredFieldsIntro, setRequiredFieldsIntro] = useState('');
    const [showRequiredFieldsDialog, setShowRequiredFieldsDialog] = useState(false);
    const [continueSubmit, setContinueSubmit] = useState(false);
    const [showConfirmSubmitDialog, setShowConfirmSubmitDialog] = useState(false);

    const [warning, setWarning] = useState('');
    const [showWarning, setShowWarning] = useState(false);

    const [showIncomplete, setShowIncomplete] = useState(false);

    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);

    const [fetchSuccess, setFetchSuccess] = useState(false);

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setShowWarning(false);
    };

    const handleErrorClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setShowError(false);
    };

    const action = (
      <React.Fragment>
        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    );

    const errorAction = (
      <React.Fragment>
        <IconButton size="small" aria-label="close" color="inherit" onClick={handleErrorClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    );

    const handleConfirmSubmitDialogClose = () => {
      setShowConfirmSubmitDialog(false);
    };

    const handleHaveVehicle = (haveVehicle) => {
      if (haveVehicle === 'yes') {
        setForm({
          ...form,
          vehicle: haveVehicle,
          usePublicTransportation: '',
          publicTransportation: '',
          publicTransportationCost: '',
          publicTransportationPaid: '',
          transportationPlans: '',
        });
      } else {
        setForm({
          ...form,
          vehicle: haveVehicle,
          vehicleCostPerMonth: '',
          vehiclePaid: '',
          autoInsurance: '',
          autoInsuranceCoverage: '',
          autoInsuranceCostPerMonth: '',
        });
      }
    };

    const displayStatus = (status) => {
      // submitted, reopened, approved
      if (status === 'reopened') {
        return 'Re-Opened';
      }
      return titleize(status);
    };

    // Redirect user to login page if token is now longer available.
    useAuthRedirect(auth);

    useEffect(() => {
      const fetchPlanningForm2 = async (studentId) => {
        try {
          store.setLoading(true);
          store.setSearching(true);

          const planningform2 = await callApi(auth, 'planningform2/' + studentId, 'GET');

          if (planningform2 && planningform2.result) {
            if (planningform2.result.id > 0) {
              store.setPlanform2Obj(planningform2.result);
              setFetchSuccess(true);

              let adjustArraysForm = JSON.parse(planningform2.result.planning_form_2_data);

              if (!adjustArraysForm.hasOwnProperty('support')) {
                adjustArraysForm.support = [{ 'name': '' }, { 'name': '' }, { 'name': '' }];
              }
              if (!adjustArraysForm.hasOwnProperty('activities')) {
                adjustArraysForm.activities = [{ 'name': '' }, { 'name': '' }, { 'name': '' }];
              }
              if (!adjustArraysForm.hasOwnProperty('fallCCTradeScholarships')) {
                adjustArraysForm.fallCCTradeScholarships = [{ 'name': '' }, { 'name': '' }, { 'name': '' }];
              }
              if (!adjustArraysForm.hasOwnProperty('fallCCTradeLoans')) {
                adjustArraysForm.fallCCTradeLoans = [{ 'name': '' }, { 'name': '' }, { 'name': '' }];
              }
              if (!adjustArraysForm.hasOwnProperty('scholarships')) {
                adjustArraysForm.scholarships = [{ 'name': '' }, { 'name': '' }, { 'name': '' }];
              }
              if (!adjustArraysForm.hasOwnProperty('loans')) {
                adjustArraysForm.loans = [{ 'name': '' }, { 'name': '' }, { 'name': '' }];
              }
              if (!adjustArraysForm.hasOwnProperty('tasks')) {
                adjustArraysForm.tasks = [{ 'name': '' }, { 'name': '' }, { 'name': '' }];
              }
              if (!adjustArraysForm.hasOwnProperty('meetingComments')) {
                adjustArraysForm.meetingComments = [];
              }
              if (!adjustArraysForm.hasOwnProperty('automaticTasks')) {
                adjustArraysForm.automaticTasks = [];
              }

              if (!adjustArraysForm.hasOwnProperty('rubricMotivation')) {
                adjustArraysForm.rubricMotivation = 0;
              }
              if (!adjustArraysForm.hasOwnProperty('rubricFuture')) {
                adjustArraysForm.rubricFuture = 0;
              }
              if (!adjustArraysForm.hasOwnProperty('rubricSFTeam')) {
                adjustArraysForm.rubricSFTeam = 0;
              }
              if (!adjustArraysForm.hasOwnProperty('rubricMotivationScore')) {
                adjustArraysForm.rubricMotivationScore = 0;
              } else {
                const avg = (adjustArraysForm.rubricMotivation + adjustArraysForm.rubricFuture + adjustArraysForm.rubricSFTeam) / 3;
                adjustArraysForm.rubricMotivationScore = avg;
              }
              if (!adjustArraysForm.hasOwnProperty('rubricSuccess')) {
                adjustArraysForm.rubricSuccess = 0;
              }

              setOriginalStatus(adjustArraysForm.status);
              setForm(adjustArraysForm);
            } else {
              writeSeniorPlan2(false);
            }
          } else {
            writeSeniorPlan2(false);
          }
          store.setLoading(false);
          store.setSearching(false);
        } catch (error) {
          setFetchSuccess(false);
          store.setLoading(false);
          store.setSearching(false);
          console.warn('Error fetching planning form2:', error);
          setError('Your Planning Form 2 did not load successfully. Please close your browser and retry. Failure to do so may result in data loss!');
          appInsights.trackTrace({ message: 'Planning Form 2 data failed to load. Error displayed to user.' });
          setShowError(true);
          logException(error);
        }
      };

      if (store.senior.id > 0) {
        fetchPlanningForm2(store.senior.id);
      }
    }, [auth, store, store.senior]);

    // AUTO-SAVE
    useEffect(() => {
      if (auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')) {
        const interval = setInterval(() => {
          if (fetchSuccess) {
            handleSavePlanningForm2(false);
            appInsights.trackTrace({ message: `Auto saved Planning Form 2 form for ${auth.user.result.email}` });
          } else {
            appInsights.trackTrace({ message: 'Planning Form 2 data failed to load. Error displayed to user.' });
            setError(
              'Your Planning Form 2 cannot be saved, because it was not successfully loaded. Please close your browser and retry. Failure to do so may result in data loss!'
            );
            appInsights.trackTrace({ message: 'Planning Form 2 data failed to load. Error displayed to user on auto-save.' });
            setShowError(true);
          }
        }, 300000);
        return () => clearInterval(interval);
      }
    }, [auth, auth.roles, form]);

    const isEditDisabled = () => {
      let rc = true;
      if (auth.roles && (auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI'))) {
        if (form.status === 'created' || form.status === 'reopened') {
          rc = false;
        }
      }
      return rc;
    };

    const showDisposition = () => {
      let rc = false;
      if (auth.roles.includes('ADMIN') || auth.roles.includes('AFTERCARE_SPECIALIST')) {
        if (originalStatus === 'submitted' || originalStatus === 'incomplete' || showIncomplete) {
          rc = true;
        }
      }
      return rc;
    };

    const handleAddComment = () => {
      const updatedComments = [
        ...form.comments,
        {
          timestamp: getCurrentDateTime(),
          text: addNote,
          user: auth.user.result.first_name + ' ' + auth.user.result.last_name,
        },
      ];
      setForm({ ...form, comments: updatedComments });
      setAddNote('');
      document.getElementById('comment').value = ''; // clear comment input after adding to array
    };

    const handleAddMeetingComment = () => {
      const updatedMeetingComments = [
        ...form.meetingComments,
        {
          timestamp: getCurrentDateTime(),
          text: addMeetingNote,
          user: auth.user.result.first_name + ' ' + auth.user.result.last_name,
        },
      ];
      setForm({ ...form, meetingComments: updatedMeetingComments });
      setAddMeetingNote('');
      document.getElementById('meetingComment').value = ''; // clear comment input after adding to array
    };

    const shouldDisplayStatusNotes = () => {
      if (form.dispositionReason && form.dispositionReason.length > 0) return true;
      return false;
    };

    const markFormIncomplete = () => {
      setShowIncomplete(!showIncomplete);
      setForm({ ...form, status: 'incomplete' });
    };

    const canDisposition = () => {
      let rc = true;
      let requiredFields = [];

      // if (form.status === 'approved' || form.status === 'incomplete') {
      if (form.status === 'approved') {
        if (
          form.gradingSheet2Completed === undefined ||
          form.gradingSheet2Submitted === undefined ||
          form.gradingSheet2Clarity === undefined ||
          form.module4Submitted === undefined ||
          form.module4Completed === undefined ||
          form.module5Submitted === undefined ||
          form.module5Completed === undefined ||
          form.module6Submitted === undefined ||
          form.module6Completed === undefined ||
          (form.gradingSheet2Completed && form.gradingSheet2Completed < 0) ||
          (form.gradingSheet2Submitted && form.gradingSheet2Submitted < 0) ||
          (form.gradingSheet2Clarity && form.gradingSheet2Clarity < 0) ||
          (form.module4Submitted && form.module4Submitted < 0) ||
          (form.module4Completed && form.module4Completed < 0) ||
          (form.module5Submitted && form.module5Submitted < 0) ||
          (form.module5Completed && form.module5Completed < 0) ||
          (form.module6Submitted && form.module6Submitted < 0) ||
          (form.module6Completed && form.module6Completed < 0)
        ) {
          requiredFields = [
            ...requiredFields,
            { requirement: 'You must score the application before approving. Please score each item before changing the status to approved.' },
          ];
          rc = false;
        }
      }

      setRequiredFieldsIntro('Please make these adjustments before continuing.');
      setRequiredFields(requiredFields);
      return rc;
    };

    const sendPlanningForm2ReopenedEmail = async (id) => {
      try {
        store.setLoading(true);
        await callApi(auth, 'planningform2reopenemail', 'POST', { student_id: id });
        appInsights.trackTrace({ message: `PlanningForm2 reopened email sent to ${auth.user.result.email}` });
        store.setLoading(false);
      } catch (error) {
        logException(error);
        store.setLoading(false);
        console.log('Error sending Planning Form 2 reopened email:', error);
      }
    };

    const handleSavePlanningForm2 = (closeInd) => {
      if (!fetchSuccess) {
        setError(
          'Your Planning Form 2 cannot be saved, because it was not successfully loaded. Please close your browser and retry. Failure to do so may result in data loss!'
        );
        setShowError(true);
        appInsights.trackTrace({ message: 'Planning Form 2 data failed to load. Error displayed to user on save.' });
        return;
      }

      // Requirements if Dispositioning by Admin/Specialist
      if ((auth.roles.includes('ADMIN') || auth.roles.includes('AFTERCARE_SPECIALIST')) && canDisposition() === false) {
        setShowRequiredFieldsDialog(true);
        return;
      }

      // if admin is returning the planning form, require a reason.
      if (auth.roles.includes('ADMIN') || auth.roles.includes('AFTERCARE_SPECIALIST')) {
        if (form.status === 'reopened') {
          if (!form.dispositionReason || form.dispositionReason.length === 0) {
            setWarning('You must provide a reason for returning the planning form.');
            setShowWarning(true);
            return;
          }
        }
      }

      writeSeniorPlan2(closeInd);
    };

    useEffect(() => {
      const canSubmit = () => {
        let rc = true;
        let requiredFields = [];

        if (!form.healthInsPlan || form.healthInsPlan.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please select the type of health insurance plan you will have after graduation.' }];
          rc = false;
        }

        if (form.healthInsPlan !== 'None') {
          if (!form.healthInsCover || form.healthInsCover.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please describe the coverage your health insurance plan will provide.' }];
            rc = false;
          }
        }

        if (!form.primaryDoctor || form.primaryDoctor.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please enter the name of your primary doctor.' }];
          rc = false;
        }

        if (!form.dentist || form.dentist.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please enter the name of your dentist.' }];
          rc = false;
        }

        if (!form.therapist || form.therapist.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please enter the name of your mental health provider.' }];
          rc = false;
        }

        let peopleInList = 0;
        form.support.forEach((t) => {
          if (t.name.length > 0) {
            peopleInList += 1;
          }
        });
        if (peopleInList < 1) {
          requiredFields = [
            ...requiredFields,
            {
              requirement: 'Please enter the name of at least one individual you can contact for guidance and support.',
            },
          ];
          rc = false;
        }

        let activitiesInList = 0;
        form.activities.forEach((t) => {
          if (t.name.length > 0) {
            activitiesInList += 1;
          }
        });
        if (activitiesInList < 1) {
          requiredFields = [
            ...requiredFields,
            {
              requirement: 'Please enter the names of at least one activity you participate in and will continue to participate in after graduation.',
            },
          ];
          rc = false;
        }

        if (!form.driversLicense || form.driversLicense.length === 0) {
          requiredFields = [...requiredFields, { requirement: "Please indicate whether you have a driver's license." }];
          rc = false;
        }

        if (form.driversLicense === 'no') {
          if (!form.completedDriversEd || form.completedDriversEd.length === 0) {
            requiredFields = [...requiredFields, { requirement: "Please indicate whether you have completed a driver's education course." }];
            rc = false;
          }
          if (form.completedDriversEd === 'no') {
            if (!form.learnersPermit || form.learnersPermit.length === 0) {
              requiredFields = [...requiredFields, { requirement: "Please indicate whether you have a learner's permit." }];
              rc = false;
            }
            if (!form.enrolledDriversEd || form.enrolledDriversEd.length === 0) {
              requiredFields = [...requiredFields, { requirement: "Please indicate whether you are enrolled in a driver's education course." }];
              rc = false;
            }
          }
        }

        if (!form.vehicle || form.vehicle.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please indicate whether you will have a vehicle.' }];
          rc = false;
        }

        if (form.vehicle === 'yes') {
          if (!form.vehicleCostPerMonth || form.vehicleCostPerMonth.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate the monthly cost of the vehicle.' }];
            rc = false;
          }
          if (!form.vehiclePaid || form.vehiclePaid.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate who will pay for the vehicle.' }];
            rc = false;
          }
          if (!form.autoInsurance || form.autoInsurance.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate whether you will have auto insurance.' }];
            rc = false;
          }
        }

        if (form.autoInsurance === 'yes') {
          if (!form.autoInsuranceCoverage || form.autoInsuranceCoverage.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please explain the auto insurance coverage.' }];
            rc = false;
          }
          if (!form.autoInsuranceCostPerMonth || form.autoInsuranceCostPerMonth.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate the cost per month for auto insurance.' }];
            rc = false;
          }
          if (!form.autoInsurancePaid || form.autoInsurancePaid.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate who will pay for auto insurance.' }];
            rc = false;
          }
        }

        if (form.vehicle === 'no') {
          if (!form.usePublicTransportation || form.usePublicTransportation.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate whether you will be using public transportation.' }];
            rc = false;
          }
          if (form.usePublicTransportation === 'yes') {
            if (!form.publicTransportation || form.publicTransportation.length === 0) {
              requiredFields = [...requiredFields, { requirement: 'Please indicate the type of public transportation you will use.' }];
              rc = false;
            }
            if (!form.publicTransportationCost || form.publicTransportationCost.length === 0) {
              requiredFields = [...requiredFields, { requirement: 'Please estimate the monthly cost for public transportation.' }];
              rc = false;
            }
            if (!form.publicTransportationPaid || form.publicTransportationPaid.length === 0) {
              requiredFields = [...requiredFields, { requirement: 'Please explain who will pay for the public transportation.' }];
              rc = false;
            }
          }

          if (form.usePublicTransportation === 'no') {
            if (!form.transportationPlans || form.transportationPlans.length === 0) {
              requiredFields = [...requiredFields, { requirement: 'Please indicate what you will use for transportation.' }];
              rc = false;
            }
          }
        }

        if (!form.summerHousingPlan || form.summerHousingPlan.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please explain where you will go the day of graduation.' }];
          rc = false;
        }

        if (!form.summerHousingPlanCost || form.summerHousingPlanCost.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please indicate the cost of your summer housing.' }];
          rc = false;
        }

        if (!form.summerHousingExpectations || form.summerHousingExpectations.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please explain the responsibilities you will have at your summer housing.' }];
          rc = false;
        }

        if (!form.summerEmployWork || form.summerEmployWork.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please explain where you will work this summer.' }];
          rc = false;
        }

        if (!form.summerEmployApply || form.summerEmployApply.length === 0) {
          requiredFields = [
            ...requiredFields,
            { requirement: 'Please indicate whether you have applied or talked to a manager about summer employment.' },
          ];
          rc = false;
        }

        if (!form.summerEmployHours || form.summerEmployHours.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please indicate how many hours per week you will be available to work this summer.' }];
          rc = false;
        }

        if (!form.fallPlan || form.fallPlan.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please indicate your plan for the fall.' }];
          rc = false;
        }

        if (form.fallPlan === 'Community College/Trade') {
          if (!form.fallCCTradeNameLocation || form.fallCCTradeNameLocation.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate the name of the college and/or location.' }];
            rc = false;
          }
          if (!form.fallCCTradeAccepted || form.fallCCTradeAccepted.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate whether you have been accepted at the community college/trade.' }];
            rc = false;
          }
          if (!form.fallTradeOrMajor || form.fallTradeOrMajor.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate your trade and/or major.' }];
            rc = false;
          }
          if (!form.fallCCTradeStartDate || form.fallCCTradeStartDate.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate your start date for community college/trade.' }];
            rc = false;
          }
          if (!form.fallCCTradeCareerGoal || form.fallCCTradeCareerGoal.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate your career goals for community college/trade.' }];
            rc = false;
          }
          if (!form.fallCCTradeFamilyAssistance || form.fallCCTradeFamilyAssistance.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate your expected family assistance for community college/trade.' }];
            rc = false;
          }
          if (!form.fallCCTradeLivingArrangements || form.fallCCTradeLivingArrangements.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate your living arrangements for fall.' }];
            rc = false;
          }
          if (!form.fallCCTradeTransportationRequirements || form.fallCCTradeTransportationRequirements.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate your transportation requirements for fall.' }];
            rc = false;
          }
        }

        if (form.fallPlan === '4-Year College') {
          if (!form.fall4YearNameLocation || form.fall4YearNameLocation.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate the name of the college and/or location.' }];
            rc = false;
          }
          if (!form.fall4YearAccepted || form.fall4YearAccepted.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate whether you have been accepted at the community college/trade.' }];
            rc = false;
          }
          if (!form.fall4YearMajor || form.fall4YearMajor.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate your trade and/or major.' }];
            rc = false;
          }
          if (!form.fall4YearCareerGoal || form.fall4YearCareerGoal.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate your career goals for community college/trade.' }];
            rc = false;
          }
          if (!form.fall4YearStartDate || form.fall4YearStartDate.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate your start date for community college/trade.' }];
            rc = false;
          }
          if (!form.fall4YearCostTuition || form.fall4YearCostTuition.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate your tuition costs for the fall.' }];
            rc = false;
          }
          if (!form.fall4YearCostBooks || form.fall4YearCostBooks.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate your costs for books for the fall.' }];
            rc = false;
          }
          if (!form.fall4YearCostHousing || form.fall4YearCostHousing.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate your housing costs for the fall.' }];
            rc = false;
          }
          if (!form.fall4YearCostOther || form.fall4YearCostOther.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate your costs for other fees for the fall.' }];
            rc = false;
          }
          if (!form.fall4YearCostMealPlan || form.fall4YearCostMealPlan.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate your meal plan costs for the fall.' }];
            rc = false;
          }

          const calculate4YearCosts =
            form.fall4YearCostTuition + form.fall4YearCostBooks + form.fall4YearCostHousing + form.fall4YearCostOther + form.fall4YearCostMealPlan;

          if (calculate4YearCosts === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate your expected costs for the fall.' }];
            rc = false;
          }
          if (!form.fall4YearFamilyAssistance || form.fall4YearFamilyAssistance.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate your expected family assistance for 4-year college.' }];
            rc = false;
          }
          if (!form.fall4YearLivingArrangements || form.fall4YearLivingArrangements.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate your living arrangements requirements for fall.' }];
            rc = false;
          }
          if (!form.fall4YearTransportationRequirements || form.fall4YearTransportationRequirements.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate your transportation requirements for fall.' }];
            rc = false;
          }
        }

        if (form.fallPlan === 'Job Corps') {
          if (!form.fallJobCorpsLocation || form.fallJobCorpsLocation.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate the Job Corps site location.' }];
            rc = false;
          }
          if (!form.fallJobCorpsProgram || form.fallJobCorpsProgram.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate the Job Corps program.' }];
            rc = false;
          }
          if ((!form.fallJobCorpsStartDate || form.fallJobCorpsStartDate.length === 0) && !form.fallJobCorpsStartDateDoNotKnow) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate the Job Corps start date or indicate you do not yet know it.' }];
            rc = false;
          }
          if (
            form.fallJobCorpsStartDateDoNotKnow &&
            (!form.fallJobCorpsStartDateDoNotKnowReason || form.fallJobCorpsStartDateDoNotKnowReason.length === 0)
          ) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate the reason you do not know your Job Corps start date.' }];
            rc = false;
          }

          if (!form.fallJobCorpsTransportation || form.fallJobCorpsTransportation.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate the Job Corps transportation requirements.' }];
            rc = false;
          }
        }

        if (form.fallPlan === 'Military') {
          if (!form.fallMilitaryBranch || form.fallMilitaryBranch.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate the military branch.' }];
            rc = false;
          }
          if ((!form.fallMilitaryMepsDate || form.fallMilitaryMepsDate.length === 0) && !form.fallMilitaryMepsDateDoNotKnow) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate the MEPS date or indicate you do not yet know it.' }];
            rc = false;
          }
          if (
            form.fallMilitaryMepsDateDoNotKnow &&
            (!form.fallMilitaryMepsDateDoNotKnowReason || form.fallMilitaryMepsDateDoNotKnowReason.length === 0)
          ) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate the reason you do not know your MEPS date.' }];
            rc = false;
          }
          if (
            (!form.fallMilitaryBasicTrainingDate || form.fallMilitaryBasicTrainingDate.length === 0) &&
            !form.fallMilitaryBasicTrainingDateDoNotKnow
          ) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate the basic training date or indicate you do not yet know it.' }];
            rc = false;
          }
          if (
            form.fallMilitaryBasicTrainingDateDoNotKnow &&
            (!form.fallMilitaryBasicTrainingDateDoNotKnowReason || form.fallMilitaryBasicTrainingDateDoNotKnowReason.length === 0)
          ) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate the reason you do not know your basic training date.' }];
            rc = false;
          }
          if (!form.fallMilitaryRecruiter || form.fallMilitaryRecruiter.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate the name of the military recruiter.' }];
            rc = false;
          }
        }

        if (form.fallPlan === 'Continue Employment') {
          if (!form.fallEmploymentPlace || form.fallEmploymentPlace.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate your place of employment in the fall.' }];
            rc = false;
          }
          if (!form.fallEmploymentFullPartTime || form.fallEmploymentFullPartTime.length === 0) {
            requiredFields = [
              ...requiredFields,
              { requirement: 'Please indicate whether your employment in the fall will be full-time or part-time.' },
            ];
            rc = false;
          }
          if (!form.fallLivingArrangements || form.fallLivingArrangements.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate your fall living arrangements.' }];
            rc = false;
          }
          if (!form.fallTransportationRequired || form.fallTransportationRequired.length === 0) {
            requiredFields = [...requiredFields, { requirement: 'Please indicate your fall transportation requirements.' }];
            rc = false;
          }
        }

        if (!form.backupPlan || form.backupPlan.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please describe your fall backup plan.' }];
          rc = false;
        }
        if (!form.backupPlanPreparations || form.backupPlanPreparations.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please describe your fall backup plan preparations in order to be successful.' }];
          rc = false;
        }
        if (!form.backupPlanLiving || form.backupPlanLiving.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please describe where you will live as part of your backup plan.' }];
          rc = false;
        }
        if (!form.backupPlanCost || form.backupPlanCost.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please describe how you will pay for your backup plan.' }];
          rc = false;
        }
        if (!form.backupPlanNeed || form.backupPlanNeed.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please describe what items you would need for your backup plan.' }];
          rc = false;
        }
        if (!form.backupPlanHelp || form.backupPlanHelp.length === 0) {
          requiredFields = [...requiredFields, { requirement: 'Please describe who would help you with your backup plan.' }];
          rc = false;
        }

        let tasksCompleted = 0;
        form.tasks.forEach((t) => {
          if (t.name.length > 0) {
            tasksCompleted += 1;
          }
        });
        if (tasksCompleted < 3) {
          requiredFields = [...requiredFields, { requirement: 'Please list at least three tasks you need to complete to move toward your plan.' }];
          rc = false;
        }

        setRequiredFieldsIntro(
          'You have not completed all of the required information on your Planning Meeting 2 form. Please make these adjustments before submitting your form.'
        );
        setRequiredFields(requiredFields);
        setContinueSubmit(false);
        return rc;
      };

      if (continueSubmit) {
        if (!fetchSuccess) {
          setError(
            'Your Planning Form 2 cannot be saved, because it was not successfully loaded. Please close your browser and retry. Failure to do so may result in data loss!'
          );
          setShowError(true);
          appInsights.trackTrace({ message: 'Planning Form 2 data failed to load. Error displayed to user on submit.' });
          return;
        }

        if (canSubmit()) {
          async function submitPlanningForm2() {
            try {
              store.setLoading(true);

              let updatedPlanningForm2 = null;

              form.status = 'submitted';
              form.dispositionReason = '';
              form.submitted_date = getCurrentDateTime();

              if (store.planform2Obj && store.planform2Obj.id && store.planform2Obj.id > 0) {
                updatedPlanningForm2 = store.planform2Obj;
                updatedPlanningForm2.planning_form_2_data = form;
                updatedPlanningForm2.updated_at = new Date();
                updatedPlanningForm2.updated_by = auth.user.result.id;
              }

              if (updatedPlanningForm2) {
                updatedPlanningForm2 = await callApi(auth, 'planningform2', 'PUT', {
                  item: updatedPlanningForm2,
                });
              } else {
                const res = await callApi(auth, 'planningform2', 'POST', {
                  item: {
                    student_id: store.senior.id,
                    created_at: new Date(),
                    created_by: store.senior.userId,
                    updated_at: new Date(),
                    updated_by: store.senior.userId,
                    planning_form_2_data: null,
                  },
                });

                updatedPlanningForm2 = res.result;

                // PUT to update planning_form_2_data in correct format
                updatedPlanningForm2.planning_form_2_data = form;
                const c = await callApi(auth, 'planningform2', 'PUT', {
                  item: updatedPlanningForm2,
                });

                if (c.result) {
                  updatedPlanningForm2 = res.result;
                  store.setPlanform2Obj(updatedPlanningForm2);
                }
              }

              appInsights.trackTrace({ message: `Planning Form 2 submitted by ${auth.user.result.email}` });
              store.setLoading(false);
              handleSaveReturn();
            } catch (error) {
              logException(error);
              store.setLoading(false);
              console.log('Error submitting updated planning form 2:', error);
            }
          }
          submitPlanningForm2();
        } else {
          setShowRequiredFieldsDialog(true);
        }
      }
    }, [continueSubmit, auth, store, form, handleSaveReturn]);

    const handleRequiredInfoClose = () => {
      setShowRequiredFieldsDialog(false);
    };

    const showSaveButton = () => {
      let rc = false;
      // Status Values: submitted, reopened, approved
      if (auth.roles.includes('ADMIN') || auth.roles.includes('AFTERCARE_SPECIALIST')) {
        return true;
      }
      if (auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')) {
        if (form.status === 'created' || form.status === 'reopened') {
          return true;
        }
      }
      return rc;
    };

    const saveHealthInsuranceTask = (val) => {
      const tasks = form.automaticTasks;
      const taskName = 'Health Insurance Plan - ‘None’';
      const idxToDelete = tasks.findIndex((r) => r === taskName);
      if (idxToDelete > -1) {
        tasks.splice(idxToDelete, 1);
      }
      if (val === 'None') {
        tasks.push(taskName);
      }
      setForm({ ...form, healthInsPlan: val, automaticTasks: tasks });
    };

    const saveDriversLicenseTask = (val) => {
      const tasks = form.automaticTasks;
      const taskName = 'I have a driver’s license - ‘No’';
      const idxToDelete = tasks.findIndex((r) => r === taskName);
      if (idxToDelete > -1) {
        tasks.splice(idxToDelete, 1);
      }
      if (val === 'no') {
        tasks.push(taskName);
      }
      setForm({ ...form, driversLicense: val, automaticTasks: tasks });
    };

    const saveDriversEdTask = (val) => {
      const tasks = form.automaticTasks;
      const taskName = 'I have completed a driver’s education course - ‘No’';
      const idxToDelete = tasks.findIndex((r) => r === taskName);
      if (idxToDelete > -1) {
        tasks.splice(idxToDelete, 1);
      }
      if (val === 'no') {
        tasks.push(taskName);
      }
      setForm({ ...form, completedDriversEd: val, automaticTasks: tasks });
    };

    const saveLearnersPermitTask = (val) => {
      const tasks = form.automaticTasks;
      const taskName = 'I have a learner’s permit - ‘No’';
      const idxToDelete = tasks.findIndex((r) => r === taskName);
      if (idxToDelete > -1) {
        tasks.splice(idxToDelete, 1);
      }
      if (val === 'no') {
        tasks.push(taskName);
      }
      setForm({ ...form, learnersPermit: val, automaticTasks: tasks });
    };

    const saveAutoInsuranceTask = (val) => {
      const tasks = form.automaticTasks;
      const taskName = 'I will have auto insurance - ‘No’';
      const idxToDelete = tasks.findIndex((r) => r === taskName);
      if (idxToDelete > -1) {
        tasks.splice(idxToDelete, 1);
      }
      if (val === 'no') {
        tasks.push(taskName);
      }
      setForm({ ...form, autoInsurance: val, automaticTasks: tasks });
    };

    const saveTalkedToManagerTask = (val) => {
      const tasks = form.automaticTasks;
      const taskName = 'Have I applied or talked to a manager? - ‘No’';
      const idxToDelete = tasks.findIndex((r) => r === taskName);
      if (idxToDelete > -1) {
        tasks.splice(idxToDelete, 1);
      }
      if (val === 'no') {
        tasks.push(taskName);
      }
      setForm({ ...form, summerEmployApply: val, automaticTasks: tasks });
    };

    const saveJobCorpsStartDateTask = (val) => {
      const tasks = form.automaticTasks;
      const taskName = 'Job Corps: Start Date = ‘Do Not Know’';
      const idxToDelete = tasks.findIndex((r) => r === taskName);
      if (idxToDelete > -1) {
        tasks.splice(idxToDelete, 1);
      }
      if (val === true) {
        tasks.push(taskName);
      }
      setForm({ ...form, fallJobCorpsStartDateDoNotKnow: val, automaticTasks: tasks });
    };

    const saveMilitaryMEPSDateTask = (val) => {
      const tasks = form.automaticTasks;
      const taskName = 'Military: MEPS Date Date = ‘Do Not Know’';
      const idxToDelete = tasks.findIndex((r) => r === taskName);
      if (idxToDelete > -1) {
        tasks.splice(idxToDelete, 1);
      }
      if (val === true) {
        tasks.push(taskName);
      }
      setForm({ ...form, fallMilitaryMepsDateDoNotKnow: val, automaticTasks: tasks });
    };

    const saveMilitaryBasicTrainingDateTask = (val) => {
      const tasks = form.automaticTasks;
      const taskName = 'Military: Basic Training Date = ‘Do Not Know’';
      const idxToDelete = tasks.findIndex((r) => r === taskName);
      if (idxToDelete > -1) {
        tasks.splice(idxToDelete, 1);
      }
      if (val === true) {
        tasks.push(taskName);
      }
      setForm({ ...form, fallMilitaryBasicTrainingDateDoNotKnow: val, automaticTasks: tasks });
    };

    const saveFallPlanAndTasks = (plan) => {
      const tasks = form.automaticTasks;
      if (plan === 'Military') {
        const basicTrainingTask = 'Military: Basic Training Date = ‘Do Not Know’';
        const basicTrainingIdx = tasks.findIndex((r) => r === basicTrainingTask);
        if (basicTrainingIdx > -1) {
          tasks.splice(basicTrainingIdx, 1);
        }
        const mepsTask = 'Military: MEPS Date Date = ‘Do Not Know’';
        const mepsIdx = tasks.findIndex((r) => r === mepsTask);
        if (mepsIdx > -1) {
          tasks.splice(mepsIdx, 1);
        }
        if (form.fallMilitaryBasicTrainingDateDoNotKnow) {
          tasks.push(basicTrainingTask);
        }
        if (form.fallMilitaryMepsDateDoNotKnow) {
          tasks.push(mepsTask);
        }
      } else {
        const basicTrainingTask = 'Military: Basic Training Date = ‘Do Not Know’';
        const basicTrainingIdx = tasks.findIndex((r) => r === basicTrainingTask);
        if (basicTrainingIdx > -1) {
          tasks.splice(basicTrainingIdx, 1);
        }
        const mepsTask = 'Military: MEPS Date Date = ‘Do Not Know’';
        const mepsIdx = tasks.findIndex((r) => r === mepsTask);
        if (mepsIdx > -1) {
          tasks.splice(mepsIdx, 1);
        }
      }
      if (plan === 'Job Corps') {
        const jobCorpsStartDateTask = 'Job Corps: Start Date = ‘Do Not Know’';
        const jobCorpsStartDateIdx = tasks.findIndex((r) => r === jobCorpsStartDateTask);
        if (jobCorpsStartDateIdx > -1) {
          tasks.splice(jobCorpsStartDateIdx, 1);
        }
        if (form.fallJobCorpsStartDateDoNotKnow) {
          tasks.push(jobCorpsStartDateTask);
        }
      } else {
        const jobCorpsStartDateTask = 'Job Corps: Start Date = ‘Do Not Know’';
        const jobCorpsStartDateIdx = tasks.findIndex((r) => r === jobCorpsStartDateTask);
        if (jobCorpsStartDateIdx > -1) {
          tasks.splice(jobCorpsStartDateIdx, 1);
        }
      }
      setForm({ ...form, fallPlan: plan, automaticTasks: tasks });
    };

    return (
      <React.Fragment>
        <PleaseWait isLoading={store.loading} />
        {store.senior && (
          <summary>
            <section align="left" className={formClasses.unprintedContent} style={{ textAlign: 'center', marginTop: 15 }}>
              NOTE: This Senior Planning Form - Meeting #2 will be automatically saved every five minutes.
              <br />
              <br />
            </section>
            <Grid container align="left" className={formClasses.unprintedContent}>
              <Grid item xs={12} align="center" className={formClasses.textRowLabelSpacer} style={{ minHeight: 50 }}>
                <aside style={{ fontWeight: 600, fontSize: '1.25rem' }}>Current Status: {displayStatus(form.status)}</aside>
              </Grid>
            </Grid>
            <div className={formClasses.printedContent}>
              <aside style={{ fontWeight: 600, fontSize: '1.50rem', paddingBottom: 10 }} align="center">
                Current Status: {displayStatus(form.status)}
              </aside>
            </div>
            {(form.status === 'approved' || form.status === 'incomplete') && (
              <aside className={formClasses.totalScore} align="center">
                Planning Form Score:{' '}
                {(form.gradingSheet2Completed || 0) +
                  (form.gradingSheet2Submitted || 0) +
                  (form.gradingSheet2Clarity || 0) +
                  (form.module4Submitted || 0) +
                  (form.module4Completed || 0) +
                  (form.module5Submitted || 0) +
                  (form.module5Completed || 0) +
                  (form.module6Submitted || 0) +
                  (form.module6Completed || 0) >
                0
                  ? (form.gradingSheet2Completed > -1 ? form.gradingSheet2Completed : 0) +
                    (form.gradingSheet2Submitted > -1 ? form.gradingSheet2Submitted : 0) +
                    (form.gradingSheet2Clarity > -1 ? form.gradingSheet2Clarity : 0) +
                    (form.module4Submitted > -1 ? form.module4Submitted : 0) +
                    (form.module4Completed > -1 ? form.module4Completed : 0) +
                    (form.module5Submitted > -1 ? form.module5Submitted : 0) +
                    (form.module5Completed > -1 ? form.module5Completed : 0) +
                    (form.module6Submitted > -1 ? form.module6Submitted : 0) +
                    (form.module6Completed > -1 ? form.module6Completed : 0)
                  : 0}
              </aside>
            )}
          </summary>
        )}

        {shouldDisplayStatusNotes() && (
          <Grid item xs={12} align="left" className={formClasses.dispositionNote + ' ' + formClasses.space10After}>
            {form.dispositionReason && form.dispositionReason.length > 0 && (
              <React.Fragment>
                <summary style={{ fontWeight: 600 }}>Notes Regarding Status</summary>
                <summary>{form.dispositionReason}</summary>
              </React.Fragment>
            )}
          </Grid>
        )}

        {store.senior && (
          <Grid container key={store.senior.userId} align="left" style={{ marginBottom: '75px' }}>
            <Grid container direction="row">
              <Grid item xs={12} align="left" className={formClasses.firstSectionSpacer}>
                <section align="left" className={formClasses.firstSectionTitle}>
                  General Information
                </section>
              </Grid>
            </Grid>
            {store.senior && <GeneralInformation senior={store.senior} />}
            <Grid container direction="row" className={formClasses.sectionSpacer}>
              <Grid item xs={12} align="left">
                <section align="left" className={formClasses.sectionTitle}>
                  Spiritual/Religious Information
                </section>
              </Grid>
            </Grid>
            <SpiritualInformation className={formClasses.topMargin10} form={form} setForm={setForm} disabled={isEditDisabled()} />
            <Grid container direction="row" className={formClasses.sectionSpacer}>
              <Grid item xs={12} align="left" className={formClasses.hideForMd}>
                <section align="left" className={formClasses.sectionTitle}>
                  Health Insurance Plan
                </section>
              </Grid>
              <Grid item xs={12} align="left" className={formClasses.hideForXs}>
                <section align="left" className={formClasses.sectionTitle}>
                  Post-Graduation Health Insurance Plan
                </section>
              </Grid>
            </Grid>
            <HealthInsurancePlan form={form} setForm={setForm} disabled={isEditDisabled()} saveHealthInsuranceTask={saveHealthInsuranceTask} />
            <Grid container direction="row" className={formClasses.sectionSpacer}>
              <Grid item xs={12} align="left">
                <section align="left" className={formClasses.sectionTitle}>
                  Support and Activities for Post-Graduation
                </section>
              </Grid>
            </Grid>
            <Box sx={{ width: '90%' }}>
              <Grid container direction="row">
                <Grid item xs={12} md={5} lg={5} className={formClasses.unprintedContent}>
                  <section align="left" className={formClasses.bigLabel}>
                    These are people I can contact post-graduation for guidance and support.{' '}
                  </section>
                  <ExpandingList
                    className={formClasses.unprintedContent}
                    form={form}
                    setForm={setForm}
                    listname="support"
                    field1="name"
                    field1Cols={11}
                    field1MaxLength={50}
                    rowsRequired={1}
                    disabled={isEditDisabled()}
                  />
                </Grid>
                <Grid item xs={false} md={1} lg={1}></Grid>
                <Grid item xs={12} md={6} lg={6} className={formClasses.unprintedContent}>
                  <section align="left" className={formClasses.bigLabel}>
                    Current positive activities I participate in and will continue to participate in after graduation.{' '}
                  </section>
                  <ExpandingList
                    className={formClasses.unprintedContent}
                    form={form}
                    setForm={setForm}
                    listname="activities"
                    field1="name"
                    field1Cols={11}
                    field1MaxLength={50}
                    rowsRequired={1}
                    disabled={isEditDisabled()}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" className={formClasses.printedContent}>
                <Grid item xs={12}>
                  <label>These are people I can contact post-graduation for guidance and support.</label>
                </Grid>
                <Grid item xs={12}>
                  <summary className={formClasses.alignPrintedContent}>{createPrintedList(form.support)}</summary>
                </Grid>
              </Grid>
              <Grid container direction="row" className={formClasses.printedContent}>
                <Grid item xs={12}>
                  <label>Current positive activities I participate in and will continue to participate in after graduation.</label>
                </Grid>
                <Grid item xs={12}>
                  <summary className={formClasses.alignPrintedContent}>{createPrintedList(form.activities)}</summary>
                </Grid>
              </Grid>
            </Box>

            <Grid container direction="row" className={formClasses.sectionSpacer}>
              <Grid item xs={12} align="left">
                <section align="left" className={formClasses.sectionTitle}>
                  Transportation Plan
                </section>
              </Grid>
            </Grid>
            <TransportationPlan
              form={form}
              setForm={setForm}
              disabled={isEditDisabled()}
              saveDriversLicenseTask={saveDriversLicenseTask}
              saveDriversEdTask={saveDriversEdTask}
              saveLearnersPermitTask={saveLearnersPermitTask}
              saveAutoInsuranceTask={saveAutoInsuranceTask}
              handleHaveVehicle={handleHaveVehicle}
            />
            <Grid container direction="row" className={formClasses.sectionSpacer}>
              <Grid item xs={12} align="left">
                <section align="left" className={formClasses.sectionTitle}>
                  Summer Plans
                </section>
              </Grid>
            </Grid>
            <Grid container direction="row" className={formClasses.sectionSpacer}>
              <Grid item xs={12} align="left">
                <section align="left" className={formClasses.sectionSubTitle}>
                  Summer Housing Plan
                </section>
              </Grid>
            </Grid>
            <SummerHousingPlan form={form} setForm={setForm} disabled={isEditDisabled()} />
            <Grid container direction="row" className={formClasses.sectionSpacer}>
              <Grid item xs={12} align="left">
                <section align="left" className={formClasses.sectionSubTitle}>
                  Summer Employment Plan
                </section>
              </Grid>
            </Grid>
            <SummerEmploymentPlan form={form} setForm={setForm} disabled={isEditDisabled()} saveTalkedToManagerTask={saveTalkedToManagerTask} />
            <Grid container direction="row" className={formClasses.sectionSpacer}>
              <Grid item xs={12} align="left">
                <section align="left" className={formClasses.sectionTitle}>
                  Fall Plans
                </section>
              </Grid>
            </Grid>
            <FallPlan
              form={form}
              setForm={setForm}
              act={store.senior && store.senior.act}
              disabled={isEditDisabled()}
              saveJobCorpsStartDateTask={saveJobCorpsStartDateTask}
              saveMilitaryMEPSDateTask={saveMilitaryMEPSDateTask}
              saveMilitaryBasicTrainingDateTask={saveMilitaryBasicTrainingDateTask}
              saveFallPlanAndTasks={saveFallPlanAndTasks}
            />
            <Grid container direction="row" className={formClasses.sectionSpacer}>
              <Grid item xs={12} align="left">
                <section align="left" className={formClasses.sectionTitle}>
                  Back-up Plan
                </section>
              </Grid>
            </Grid>
            <BackupPlan form={form} setForm={setForm} disabled={isEditDisabled()} />

            <Grid container direction="row" className={formClasses.unprintedContent}>
              <Grid item xs={12} className={formClasses.bigLabel} style={{ marginTop: '20px' }}>
                <section className={formClasses.tasksHeader}>Tasks I need to complete to move toward my plan. </section>
              </Grid>

              {form.automaticTasks && form.automaticTasks.length > 0 && (
                <Grid item xs={12} className={formClasses.bigLabel + ' ' + formClasses.automaticTaskHeading}>
                  <label>Based on your responses, consider the following items when coming up with tasks:</label>
                </Grid>
              )}
            </Grid>

            <Grid container direction="row" className={formClasses.unprintedContent}>
              <Grid item xs={12} className={formClasses.tasks}>
                <ul>
                  {form.automaticTasks &&
                    form.automaticTasks.length > 0 &&
                    form.automaticTasks.map((t, idx) => {
                      return (
                        <li key={'autotasks' + idx} className={formClasses.automaticTasksList}>
                          {t}
                        </li>
                      );
                    })}
                </ul>
              </Grid>
            </Grid>

            <Grid container direction="row" style={{ height: '185px', overflow: 'auto' }} className={formClasses.unprintedContent}>
              <Grid item xs={12} className={formClasses.textRowSpacer}>
                <ExpandingList
                  form={form}
                  setForm={setForm}
                  listname="tasks"
                  field1="name"
                  field1Cols={11}
                  field1MaxLength={500}
                  rowsRequired={5}
                  disabled={isEditDisabled()}
                />
              </Grid>
            </Grid>
            <div className={formClasses.printedContent}>
              <div>
                <label>Tasks I need to complete to move toward my plan.</label>
              </div>
              <div>
                <ul>
                  {form.tasks &&
                    form.tasks.length > 0 &&
                    form.tasks.map((t, idx) => {
                      return (
                        <li key={'printedtasks_' + idx} className={formClasses.printedTasksList}>
                          {t.name}
                        </li>
                      );
                    })}
                </ul>
              </div>
              <br />
            </div>

            <Grid container direction="row" className={formClasses.sectionSpacer + ' ' + formClasses.unprintedContent}>
              <Grid item xs={12} align="left">
                <section align="left" className={formClasses.sectionSubTitle + ' ' + formClasses.reminder}>
                  PLEASE READ THE FOLLOWING STATEMENTS AND INITIAL IN THE SPACE PROVIDED:
                </section>
              </Grid>
            </Grid>
            <Grid container direction="row" className={formClasses.unprintedContent}>
              <Grid item xs={12} sm={12} md={9} lg={8} className={formClasses.textRowLabelSpacer}>
                <section>I understand I can access the Boys Town scholarship application online and am aware of the due date.</section>
              </Grid>
              <Grid item xs={3} sm={3} md={1} lg={1} className={formClasses.textRowSpacer}>
                <FormControl fullWidth>
                  <TextField
                    id="scholarship-received-initial"
                    size="small"
                    hiddenLabel
                    placeholder="Initials"
                    variant="outlined"
                    value={form.scholarshipReceivedInitial}
                    onChange={(event) => setForm({ ...form, scholarshipReceivedInitial: event.target.value })}
                    InputProps={{ maxLength: 5, classes: { input: formClasses.textField } }}
                    disabled={isEditDisabled()}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container direction="row" className={formClasses.printedContent}>
              <Grid item xs={8}>
                <label className={formClasses.alignPrintedContent}>
                  I understand I can access the Boys Town scholarship application online and am aware of the due date.
                </label>
              </Grid>
              <Grid item xs={1}>
                <summary className={formClasses.alignPrintedContent}>{form.scholarshipReceivedInitial || null}</summary>
              </Grid>
            </Grid>

            <Grid container direction="row" className={formClasses.unprintedContent}>
              <Grid item xs={12} sm={12} md={9} lg={8} className={formClasses.textRowLabelSpacer}>
                <section>I understand what I need to do for Boys Town to be able to support my plan.</section>
              </Grid>
              <Grid item xs={3} sm={3} md={1} lg={1} className={formClasses.textRowSpacer}>
                <FormControl fullWidth>
                  <TextField
                    id="understand-what-i-need-to-do-initial"
                    size="small"
                    hiddenLabel
                    variant="outlined"
                    placeholder="Initials"
                    value={form.understandWhatINeedToDoInitial}
                    onChange={(event) => setForm({ ...form, understandWhatINeedToDoInitial: event.target.value })}
                    InputProps={{ maxLength: 5, classes: { input: formClasses.textField } }}
                    disabled={isEditDisabled()}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container direction="row" className={formClasses.printedContent}>
              <Grid item xs={8}>
                <label className={formClasses.alignPrintedContent}>I understand what I need to do for Boys Town to be able to support my plan.</label>
              </Grid>
              <Grid item xs={1}>
                <summary className={formClasses.alignPrintedContent}>{form.understandWhatINeedToDoInitial || null}</summary>
              </Grid>
            </Grid>

            {/* Action Buttons */}
            <Grid container direction="row" className={formClasses.unprintedContent}>
              <Grid item xs={12} sm={12} md={3} align="right" className={formClasses.buttonSpacer + ' ' + formClasses.unprintedContent}>
                <div align="left">
                  {(!form.status || form.status === 'created' || form.status === 'reopened') &&
                    (auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')) && (
                      <Tooltip title="Click to submit your planning form 2 for review." placement="top">
                        <Button
                          className={formClasses.floatingSubmitButton}
                          color="secondary"
                          variant="contained"
                          onClick={(event) => setShowConfirmSubmitDialog(true)}
                          disabled={!auth.roles.includes('STUDENT') && !auth.roles.includes('ALUMNI')}
                          style={{ margin: '0 15px 15px 0', width: '110px' }}
                        >
                          Submit
                        </Button>
                      </Tooltip>
                    )}
                </div>
                <div align="right">
                  {showSaveButton() ? (
                    <>
                      <Button
                        className={formClasses.floatingSaveButton}
                        variant="contained"
                        onClick={(event) => handleSaveReturn()}
                        disabled={!store.senior}
                        style={{ margin: '0 140px 15px 0', width: '110px' }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className={formClasses.floatingSaveButton}
                        color="primary"
                        variant="contained"
                        onClick={(event) => handleSavePlanningForm2(true)}
                        disabled={!store.senior}
                        style={{ margin: '0 15px 15px 0', width: '110px' }}
                      >
                        Save
                      </Button>
                    </>
                  ) : (
                    <Button
                      className={formClasses.floatingSaveButton}
                      variant="contained"
                      onClick={(event) => handleSaveReturn()}
                      disabled={!store.senior}
                      style={{ margin: '0 15px 15px 0', width: '110px' }}
                    >
                      Cancel
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>

            {/* Meeting Notes */}
            {!auth.roles.includes('STUDENT') && !auth.roles.includes('ALUMNI') && (
              <React.Fragment>
                <Grid container direction="row" className={formClasses.sectionSpacer}>
                  <Grid item xs={12} sm={12} md={6} align="left">
                    <section align="left" className={formClasses.sectionTitle}>
                      Meeting Notes
                    </section>
                  </Grid>
                </Grid>
                <Grid container direction="row" className={formClasses.unprintedContent}>
                  <Grid item xs={12} sm={8} md={5} lg={7} className={formClasses.textArea5RowSpacer}>
                    <FormControl fullWidth>
                      <TextField
                        id="meetingComment"
                        ref={meetingCommentRef}
                        size="small"
                        hiddenLabel
                        variant="outlined"
                        value={form.meetingNote}
                        onChange={(event) => setAddMeetingNote(event.target.value)}
                        disabled={auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')}
                        InputProps={{
                          classes: { input: formClasses.textField },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} lg={2} className={formClasses.textArea5RowSpacer}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={(event) => handleAddMeetingComment()}
                      disabled={!addMeetingNote}
                      style={{ margin: '0 0 0 15px' }}
                    >
                      Add Note
                    </Button>
                  </Grid>
                </Grid>
                <Grid container direction="row" className={formClasses.unprintedContent}>
                  <Grid item xs={12} className={formClasses.textRowSpacer}>
                    <aside className={formClasses.meetingSaveNote}>NOTE: Meeting notes are not visible to students.</aside>
                  </Grid>
                </Grid>
                <summary
                  className={formClasses.unprintedContent}
                  style={{ height: '300px', width: '90%', border: '1px solid #808080', overflowY: 'scroll' }}
                >
                  <Grid container direction="row" className={formClasses.sectionSpacer}>
                    <Grid item xs={12} sm={12} md={9}>
                      {form.meetingComments &&
                        form.meetingComments
                          .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                          .map((comment, idx) => {
                            return (
                              <table style={{ width: '100%', marginBottom: '20px', fontSize: '0.90rem' }} key={idx}>
                                <tr style={{ margin: '5px 0' }}>
                                  <td style={{ width: '100%', verticalAlign: 'top' }}>
                                    <aside>
                                      <label>Logged at</label> {comment.timestamp}
                                    </aside>
                                    <aside>
                                      <label>By</label> {comment.user}
                                    </aside>
                                    <summary>{comment.text}</summary>
                                  </td>
                                </tr>
                              </table>
                            );
                          })}
                    </Grid>
                  </Grid>
                </summary>
                <summary className={formClasses.printedContent} style={{ width: '90%' }}>
                  {form.meetingComments &&
                    form.meetingComments
                      .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                      .map((comment, idx) => {
                        return (
                          <table style={{ width: '100%', marginBottom: '20px', fontSize: '0.90rem' }} key={idx}>
                            <tr style={{ margin: '5px 0' }}>
                              <td style={{ width: '100%', verticalAlign: 'top' }}>
                                <aside>
                                  <label>Logged at</label> {comment.timestamp}
                                </aside>
                                <aside>
                                  <label>By</label> {comment.user}
                                </aside>
                                <summary>{comment.text}</summary>
                              </td>
                            </tr>
                          </table>
                        );
                      })}
                </summary>
              </React.Fragment>
            )}

            {/* Comments */}
            <Grid container direction="row" className={formClasses.sectionSpacer} style={{ marginTop: '30px' }}>
              <Grid item xs={12} sm={12} md={6} align="left">
                {auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI') ? (
                  <section align="left" className={formClasses.disabledSectionTitle}>
                    Comments
                  </section>
                ) : (
                  <section align="left" className={formClasses.sectionTitle}>
                    Comments
                  </section>
                )}
              </Grid>
            </Grid>
            {!auth.roles.includes('STUDENT') && !auth.roles.includes('ALUMNI') && (
              <React.Fragment>
                <Grid container direction="row" className={formClasses.unprintedContent}>
                  <Grid item xs={12} sm={8} md={5} lg={7} className={formClasses.textArea5RowSpacer}>
                    <FormControl fullWidth>
                      <TextField
                        id="comment"
                        ref={commentRef}
                        size="small"
                        hiddenLabel
                        variant="outlined"
                        value={form.note}
                        onChange={(event) => setAddNote(event.target.value)}
                        disabled={auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')}
                        InputProps={{
                          classes: { input: formClasses.textField },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} lg={2} className={formClasses.textArea5RowSpacer}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={(event) => handleAddComment()}
                      disabled={!addNote}
                      style={{ margin: '0 0 0 15px' }}
                    >
                      Add Comment
                    </Button>
                  </Grid>
                </Grid>
                <Grid container direction="row" className={formClasses.unprintedContent}>
                  <Grid item xs={12} className={formClasses.textRowSpacer}>
                    <aside className={formClasses.commentSaveNote}>
                      NOTE: Comments should be used to advise students. They will be seen by the seniors. After adding a comment you MUST click "SAVE"
                      to save the Planning Form.
                    </aside>
                  </Grid>
                </Grid>
              </React.Fragment>
            )}

            <summary
              className={formClasses.unprintedContent}
              style={{ height: '300px', width: '90%', border: '1px solid #808080', overflowY: 'scroll', marginBottom: 35 }}
            >
              <Grid container direction="row" className={formClasses.sectionSpacer}>
                <Grid item xs={12} sm={12} md={9}>
                  {form.comments &&
                    form.comments
                      .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                      .map((comment, idx) => {
                        return (
                          <table style={{ width: '100%', marginBottom: '20px', fontSize: '0.90rem' }} key={idx}>
                            <tr style={{ margin: '5px 0' }}>
                              <td style={{ width: '100%', verticalAlign: 'top' }}>
                                <aside>
                                  <label>Logged at</label> {comment.timestamp}
                                </aside>
                                <aside>
                                  <label>By</label> {comment.user}
                                </aside>
                                <summary>{comment.text}</summary>
                              </td>
                            </tr>
                          </table>
                        );
                      })}
                </Grid>
              </Grid>
            </summary>
            <summary className={formClasses.printedContent} style={{ width: '90%', marginBottom: 35 }}>
              {form.comments &&
                form.comments
                  .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                  .map((comment, idx) => {
                    return (
                      <table style={{ width: '100%', marginBottom: '20px', fontSize: '0.90rem' }} key={idx}>
                        <tr style={{ margin: '5px 0' }}>
                          <td style={{ width: '100%', verticalAlign: 'top' }}>
                            <aside>
                              <label>Logged at</label> {comment.timestamp}
                            </aside>
                            <aside>
                              <label>By</label> {comment.user}
                            </aside>
                            <summary>{comment.text}</summary>
                          </td>
                        </tr>
                      </table>
                    );
                  })}
            </summary>
          </Grid>
        )}

        {!auth.roles.includes('STUDENT') && !auth.roles.includes('ALUMNI') && (
          <Grid container direction="row" className={formClasses.unprintedContent}>
            <Grid item xs={12} align="left">
              <FormControlLabel
                style={{ marginLeft: '10px' }}
                control={
                  <Tooltip
                    title="Student has not submitted the planning form, but we need to score the other pieces required for incentive payments."
                    placement="top"
                  >
                    <Checkbox
                      color="default"
                      disabled={auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')}
                      checked={showIncomplete}
                      onChange={(ev) => {
                        markFormIncomplete();
                      }}
                    />
                  </Tooltip>
                }
                label="Show Admin Form for Incomplete Grading"
              />
            </Grid>
          </Grid>
        )}

        {/* Administrative Disposition Panel */}
        {showDisposition() && (
          <Grid
            container
            direction="row"
            className={formClasses.unprintedContent}
            style={{ marginTop: 20, padding: 20, border: '1px solid darkgreen', background: '#dcf5dc' }}
          >
            <Grid item xs={12} align="center">
              <label style={{ fontWeight: 600 }}>Successful Futures Administration Only</label>
            </Grid>
            <Grid item xs={12} sm={5} md={3} lg={2} className={formClasses.textRowLabelSpacer} align="left">
              <label style={{ fontWeight: 600, color: '#000000' }}>Planning Form 2 Status</label>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={6} className={formClasses.textArea5RowSpacer}>
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-label="approve planning form 2"
                  name="approve-planning-form-2"
                  horizontal="true"
                  value={form.status}
                  onChange={(event) => setForm({ ...form, status: event.target.value })}
                  disabled={auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')}
                >
                  {!showIncomplete && (
                    <FormControlLabel
                      value="reopened"
                      className={formClasses.marginRight40}
                      control={<Radio color="default" disabled={auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')} />}
                      label={
                        <span className={formClasses.radioLabel} style={{ fontWeight: 500, color: '#000000' }}>
                          Re-Opened
                        </span>
                      }
                    />
                  )}

                  {!showIncomplete && (
                    <FormControlLabel
                      value="approved"
                      className={formClasses.marginRight40}
                      control={<Radio color="default" disabled={auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')} />}
                      label={
                        <span className={formClasses.radioLabel} style={{ fontWeight: 500, color: '#000000' }}>
                          Approved
                        </span>
                      }
                    />
                  )}

                  <FormControlLabel
                    value="incomplete"
                    className={formClasses.marginRight40}
                    control={<Radio color="default" disabled={auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')} />}
                    label={
                      <span className={formClasses.radioLabel} style={{ fontWeight: 500, color: '#000000' }}>
                        Incomplete
                      </span>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} className={formClasses.textArea5RowSpacer} align="right">
              <FormControlLabel
                style={{ marginLeft: '10px' }}
                control={
                  <Tooltip title="Student needs an additional senior planning meeting to address shortcomings in their current plan." placement="top">
                    <Checkbox
                      color="default"
                      disabled={auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')}
                      checked={form.followup ? form.followup : false}
                      onChange={(ev) => {
                        if (ev.target.checked) {
                          setForm({ ...form, followup: true });
                        } else {
                          setForm({ ...form, followup: ev.target.checked });
                        }
                      }}
                    />
                  </Tooltip>
                }
                label="Needs Follow-up"
              />
            </Grid>

            {(form.status === 'reopened' || form.status === 'approved') && (
              <React.Fragment>
                <Grid item xs={12} className={formClasses.textRowSpacer}>
                  <FormControl fullWidth>
                    <TextField
                      id="disposition-reason"
                      size="small"
                      fullWidth
                      label={`Enter any notes for status '${titleize(form.status)}'`}
                      variant="outlined"
                      value={form.dispositionReason}
                      onChange={(event) => setForm({ ...form, dispositionReason: event.target.value })}
                      disabled={auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')}
                      InputProps={{ maxLength: 500, classes: { input: formClasses.textField } }}
                      className={formClasses.root}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} className={formClasses.textRowSpacer}>
                  <label className={formClasses.note}>
                    {form.status === 'reopened'
                      ? 'This reason will be displayed to the student when they view the reopened planning form.'
                      : 'This reason will be displayed to the student when they view the planning form.'}
                  </label>
                </Grid>
              </React.Fragment>
            )}

            <Scoring form={form} setForm={setForm} disabled={auth.roles.includes('STUDENT')} />
          </Grid>
        )}

        <RequiredInfoDialog
          title="Planning Form 2 Errors"
          introduction={requiredFieldsIntro}
          content={requiredFields}
          showDialog={showRequiredFieldsDialog}
          handleClose={handleRequiredInfoClose}
        />
        <ConfirmSubmitDialog
          setContinueSubmit={setContinueSubmit}
          setShowConfirmSubmitDialog={setShowConfirmSubmitDialog}
          showDialog={showConfirmSubmitDialog}
          handleClose={handleConfirmSubmitDialogClose}
        />
        <Snackbar
          ContentProps={{
            classes: {
              root: formClasses.warning,
            },
          }}
          open={showWarning}
          autoHideDuration={6000}
          onClose={handleClose}
          message={warning}
          action={action}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        ></Snackbar>
        <Snackbar
          ContentProps={{
            classes: {
              root: formClasses.errorAlert,
            },
          }}
          open={showError}
          autoHideDuration={10000}
          onClose={handleErrorClose}
          message={error}
          action={errorAction}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        ></Snackbar>
      </React.Fragment>
    );
  })
);

SeniorPlanning2.propTypes = {
  handleSaveReturn: PropTypes.func,
};

export default SeniorPlanning2;

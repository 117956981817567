import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  Grid,
  FormControl,
  FormControlLabel,
  TextField,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextareaAutosize,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { logException, appInsights } from '../../services/appInsights';
import CloseIcon from '@material-ui/icons/Close';
import { callApi } from '../../util/apiWrapper';
import { getCurrentDateTime } from '../../util/dateUtil';
import { btNavyBlueColor } from '../../assets/jss/nextjs-material-kit-pro';
import { dateTimeToS, dateToS } from '../../util/dateUtil';
import HelpRequestResolution from './HelpRequestResolution';
import HelpRequestContact from './HelpRequestContact';
import { logAudit } from '../../util/auditUtil';
import useAuthRedirect from '../../hooks/authRedirectHook';

const formStyles = makeStyles((theme) => ({
  title: {
    color: 'brown',
    margin: '10px 0 5px 0',
    fontSize: '1.45rem',
    fontWeight: 600,
  },
  subTitle: {
    margin: '15px 0',
    fontSize: '1.05rem',
    fontWeight: 500,
  },
  firstSectionSpacer: {
    [theme.breakpoints.down('md')]: {
      height: 60,
    },
    [theme.breakpoints.up('md')]: {
      height: 40,
    },
  },
  sectionSpacer: {
    height: 60,
    padding: '10px 0',
  },
  consentSectionSpacer: {
    minHeight: 160,
    padding: '10px 0',
  },
  rowSpacer: {
    height: 30,
  },
  textSpacer: {
    margin: '15px 0 5px 0',
  },
  smallRowSpacer: {
    margin: '3px 0 3px 0',
  },
  textRowSpacer: {
    [theme.breakpoints.up('sm')]: {
      height: 50,
    },
  },
  textArea5RowSpacer: {
    [theme.breakpoints.down('sm')]: {
      height: 85,
    },
    [theme.breakpoints.up('sm')]: {
      height: 75,
    },
  },
  textArea10RowSpacer: {
    height: 185,
  },
  labelRowSpacer: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 15,
    },
    [theme.breakpoints.up('xs')]: {
      marginTop: 5,
    },
  },
  textRowLabelSpacer: {
    [theme.breakpoints.down('md')]: {
      height: 30,
      paddingTop: 5,
      overflow: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      height: 50,
      paddingTop: 8,
      overflow: 'auto',
    },
  },
  valignSelect: {
    marginTop: 5,
  },
  caseNumber: {
    fontWeight: 600,
    fontSize: '1.10rem',
    padding: '5px 0 15px 0',
    minHeight: 45,
  },
  statusHeader: {
    fontWeight: 500,
    fontSize: '1.15rem',
    padding: 5,
    minHeight: 35,
  },
  messageHeader: {
    [theme.breakpoints.down('md')]: {
      minHeight: 225,
      paddingTop: 5,
      overflow: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 175,
      paddingTop: 5,
      overflow: 'auto',
    },
  },
  textRowLargeLabelSpacer: {
    [theme.breakpoints.down('sm')]: {
      height: 60,
      paddingTop: 5,
    },
    [theme.breakpoints.up('sm')]: {
      height: 50,
      paddingTop: 8,
    },
  },
  buttonWidth: {
    width: 110,
  },
  buttonSpacer: {
    height: 50,
    marginTop: 20,
  },
  firstSectionTitle: {
    [theme.breakpoints.down('sm')]: {
      minHeight: 80,
      fontSize: '1.00rem',
      fontWeight: 600,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.10rem',
      fontWeight: 600,
    },
  },
  sectionTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.00rem',
      fontWeight: 600,
      margin: '8px 0',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.10rem',
      fontWeight: 600,
      margin: '15px 0',
    },
  },
  sectionSubTitle: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.80rem',
      fontWeight: 600,
      margin: '2px 0',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.90rem',
      fontWeight: 600,
      margin: '5px 0',
    },
  },
  hideForXs: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  hideForMd: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  radioLabel: {
    fontSize: '0.85rem',
    fontWeight: 500,
    '& svg': {
      width: '1.25rem',
      height: '1.25rem',
    },
  },
  radioRow: {
    width: '100%',
    justifyContent: 'space-between',
  },
  reminder: {
    color: 'blue',
    fontSize: '1.05rem',
    margin: '10px 0',
  },
  [theme.breakpoints.down('xs')]: {
    textField: {
      fontSize: '0.75rem',
    },
    root: {
      '& .MuiFormLabel-root': {
        fontSize: '0.75rem',
      },
    },
  },
  bigLabel: {
    fontSize: '0.85rem',
  },
  addButton: {
    padding: 0,
    color: btNavyBlueColor,
  },
  topMargin10: {
    marginTop: 10,
  },
  printedContent: {
    '@media print': {
      marginBottom: 10,
    },
    '@media screen': {
      display: 'none',
    },
  },
  unprintedContent: {
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  alignPrintedContent: {
    lineHeight: '25px',
    verticalAlign: 'top',
  },
  commentSaveNote: {
    fontSize: '0.90rem',
    fontWeight: 600,
    color: 'blue',
  },
  error: {
    fontSize: '1.05rem',
    color: '#FA0000',
    fontWeight: 600,
  },
  spacer2520: {
    margin: '25px 0 20px 0',
  },
  dispositionNote: {
    fontSize: '1.05rem',
    padding: '15px 15px',
    margin: '20px 0',
    '@media print': {},
    '@media screen': {
      border: '1px solid blue',
      backgroundColor: '#FFFAF0',
    },
  },
  warning: {
    backgroundColor: '#fdb515',
    color: 'black',
  },
  note: {
    color: 'blue',
  },
  helpRequestNote: {
    color: 'blue',
    fontSize: '1.05rem',
    padding: 5,
  },
  submittedInfo: {
    color: btNavyBlueColor,
    fontStyle: 'italic',
    fontSize: '0.95rem',
    fontWeight: 600,
  },
  meetingSaveNote: {
    fontSize: '0.90rem',
    fontWeight: 600,
    color: '#808080',
  },
  floatingSaveButton: {
    width: 210,
    position: 'absolute',
    right: 15,
    bottom: 15,
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  floatingSubmitButton: {
    width: 210,
    position: 'absolute',
    left: 15,
    bottom: 15,
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  space10After: {
    marginBottom: 10,
  },
  totalScore: {
    color: btNavyBlueColor,
    fontSize: '1.00rem',
    fontWeight: 600,
    paddingBottom: 25,
  },
  checkboxSpacer: {
    minHeight: 230,
  },
  textAreaFont: {
    fontSize: '0.938rem',
    fontFamily: 'Source Sans Pro, Helvetica, Roboto, Arial, sans-serif',
    padding: 10,
  },
  textAreaSpacer: {
    height: 110,
    marginBottom: 20,
  },
  consentCheckbox: {
    fontSize: '0.85rem',
    fontWeight: 500,
    lineHeight: '1.10rem',
  },
  resolution: {
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
      minHeight: 95,
      paddingTop: 5,
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 50,
      paddingTop: 8,
    },
  },
}));

const HelpApplication = inject(
  'SeniorStore',
  'AuthStore'
)(
  observer((props) => {
    const store = props.SeniorStore;
    const auth = props.AuthStore;

    //TLR
    const { handleSaveReturn } = props;

    const classes = formStyles();

    const [planningForm1, setPlanningForm1] = useState(null);
    const [planningForm2, setPlanningForm2] = useState(null);
    const [checkList, setCheckList] = useState(null);

    const [status, setStatus] = useState('created');
    const [originalStatus, setOriginalStatus] = useState('');

    const [assignedTo, setAssignedTo] = useState('');
    const [sendAssignedToEmail, setSendAssignedToEmail] = useState(false);
    const [assignedDate, setAssignedDate] = useState(null);
    const [resolutionComment, setResolutionComment] = useState('');

    const [errorMsg, setErrorMsg] = React.useState('');
    const [warning, setWarning] = useState('');
    const [showWarning, setShowWarning] = useState(false);

    const [seniorName, setSeniorName] = useState('');
    const [seniorEmail, setSeniorEmail] = useState('');
    const [seniorPhone, setSeniorPhone] = useState('');

    const [requestWasClosed, setRequestWasClosed] = useState(false);

    const [originalContactInfo, setOriginalContactInfo] = useState({
      alumniPhone1: '',
      alumniPhone1Type: '',
      alumniPhone2: '',
      alumniPhone2Type: '',
      alumniPhone3: '',
      alumniPhone3Type: '',
      alumniAddress: '',
      alumniAddress2: '',
      alumniCity: '',
      alumniState: '',
      alumniZip: '',
      alumniCountry: '',
      alumniUpdatedFirstName: '',
      alumniUpdatedLastName: '',
    });

    const [contactInfo, setContactInfo] = useState({
      alumniPhone1: '',
      alumniPhone1Type: '',
      alumniPhone2: '',
      alumniPhone2Type: '',
      alumniPhone3: '',
      alumniPhone3Type: '',
      alumniAddress: '',
      alumniAddress2: '',
      alumniCity: '',
      alumniState: '',
      alumniZip: '',
      alumniCountry: '',
      alumniUpdatedFirstName: '',
      alumniUpdatedLastName: '',
    });

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setShowWarning(false);
    };

    const action = (
      <React.Fragment>
        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    );

    // prettier-ignore
    const [form, setForm] = useState({
      coaching: false,
      dailyLiving: false,
      education: false,
      employment: false,
      finances: false,
      food: false,
      health: false,
      housing: false,
      legalDocs: false,
      lifeSkills: false,
      mentalHealth: false,
      scholarships: false,
      transportation: false,
      utilities: false,
      other: false,
      additionalInfo: '',
      maritalStatus: '',
      haveChildren: '',
      consent: false,
    });

    // prettier-ignore
    const [formResolution, setFormResolution] = useState({
      closeRequest: false,
      coaching: '',
      dailyLiving: '',
      education: '',
      employment: '',
      finances: '',
      food: '',
      health: '',
      housing: '',
      legalDocs: '',
      lifeSkills: '',
      mentalHealth: '',
      scholarships: '',
      transportation: '',
      utilities: '',
      other: '',
    });

    const [phone1IsValid, setPhone1IsValid] = useState(true);
    const [phone2IsValid, setPhone2IsValid] = useState(true);
    const [phone3IsValid, setPhone3IsValid] = useState(true);
    const [phoneTypes, setPhoneTypes] = React.useState([]);

    // Page Load useEffect
    useEffect(() => {
      setPhoneTypes(['Mobile', 'Home', 'Work']);

      if (store.helpApp && store.helpApp.application) {
        const tempForm = JSON.parse(store.helpApp.application || null);
        setForm((f) => (f = tempForm));
        setOriginalStatus(store.helpApp.status);
        setStatus(store.helpApp.status);
        setAssignedTo(store.helpApp.assigned_to);
        setAssignedDate(store.helpApp.assigned_date);
        const res = JSON.parse(store.helpApp.resolution || null);
        setFormResolution((r) => (r = res));
        setResolutionComment(store.helpApp.resolution_comment);
      }

      setOriginalContactInfo({
        alumniPhone1: store.senior.alumni_phone_1,
        alumniPhone1Type: store.senior.alumni_phone_1_type,
        alumniPhone2: store.senior.alumni_phone_2,
        alumniPhone2Type: store.senior.alumni_phone_2_type,
        alumniPhone3: store.senior.alumni_phone_3,
        alumniPhone3Type: store.senior.alumni_phone_3_type,
        alumniAddress: store.senior.alumni_address,
        alumniAddress2: store.senior.alumni_address2,
        alumniCity: store.senior.alumni_city,
        alumniState: store.senior.alumni_state,
        alumniZip: store.senior.alumni_zip,
        alumniCountry: store.senior.alumni_country,
        alumniUpdatedFirstName: store.senior.alumni_updated_first_name,
        alumniUpdatedLastName: store.senior.alumni_updated_last_name,
        first_name: store.senior.first_name,
        last_name: store.senior.last_name,
      });

      setContactInfo({
        alumniPhone1: store.senior.alumni_phone_1,
        alumniPhone1Type: store.senior.alumni_phone_1_type,
        alumniPhone2: store.senior.alumni_phone_2,
        alumniPhone2Type: store.senior.alumni_phone_2_type,
        alumniPhone3: store.senior.alumni_phone_3,
        alumniPhone3Type: store.senior.alumni_phone_3_type,
        alumniAddress: store.senior.alumni_address,
        alumniAddress2: store.senior.alumni_address2,
        alumniCity: store.senior.alumni_city,
        alumniState: store.senior.alumni_state,
        alumniZip: store.senior.alumni_zip,
        alumniCountry: store.senior.alumni_country,
        alumniUpdatedFirstName: store.senior.alumni_updated_first_name,
        alumniUpdatedLastName: store.senior.alumni_updated_last_name,
        first_name: store.senior.first_name,
        last_name: store.senior.last_name,
      });
      // store.senior.planning_form_1 && store.senior.planning_form_1.length > 0 && setPlanningForm1(JSON.parse(store.senior.planning_form_1));
      // store.senior.planning_form_2 && store.senior.planning_form_2.length > 0 && setPlanningForm2(JSON.parse(store.senior.planning_form_2));
      // store.senior.checklist && store.senior.checklist.length > 0 && setCheckList(JSON.parse(store.senior.checklist));
    }, [store]);

    // Redirect user to login page if token is now longer available.
    useAuthRedirect(auth);

    useEffect(() => {
      const fetchAftercareSpecialistList = async () => {
        try {
          if (store.aftercareSpecialistList && store.aftercareSpecialistList.length < 1) {
            store.setLoading(true);
            store.setSearching(true);
            const specialists = await callApi(auth, 'staff', 'GET');
            store.setAftercareSpecialistList(
              specialists.result.filter(
                (s) =>
                  s.is_active &&
                  (s.position_class === '1347' || s.position_class === '2001' || s.position_class === '2148' || s.position_class === '1534')
              )
            );
            store.setSearching(false);
            store.setLoading(false);
          }
        } catch (error) {
          store.setSearching(false);
          store.setLoading(false);
          console.log('Error fetching aftercare specialist list:', error);
          logException(error);
        }
      };
      fetchAftercareSpecialistList();
      store.helpApp && fetchUserInformation(store.helpApp.userId);
    }, [store.helpApp]);

    const fetchUserInformation = async (userId) => {
      if (userId === 0) return;
      try {
        let alum = await callApi(auth, 'studentplan/' + userId, 'GET');
        setSeniorName(
          (alum.result.alumni_updated_first_name && alum.result.alumni_updated_first_name.length > 0
            ? alum.result.alumni_updated_first_name
            : alum.result.first_name + ' ' + alum.result.last_name) +
            ' ' +
            (alum && alum.result && alum.result.alumni_updated_last_name && alum.result.alumni_updated_last_name.length > 0
              ? alum.result.alumni_updated_last_name
              : alum.result.last_name)
        );
        // setSeniorName({alum && alum.result && alum.result.alumni_updated_first_name && alum.result.alumni_updated_first_name.length > 0
        //   ? alum.result.alumni_updated_first_name
        //   : alum.result.first_name}{' '}
        // {alum && alum.result && alum.result.alumni_updated_last_name && alum.result.alumni_updated_last_name.length > 0
        //   ? alum.result.alumni_updated_last_name
        //   : alum.result.last_name}
        // )
        setSeniorEmail(alum.result.user.email);
        setSeniorPhone(alum.result.alumni_phone_1 ? alum.result.alumni_phone_1 : alum.result.home_phone);
      } catch (error) {
        console.log('Error fetching updatedBy name information:', error);
        logException(error);
      }
    };

    const validatePhoneNumber = (phone) => {
      const phoneRegex = /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g;
      const phoneValidated = phone.match(phoneRegex);
      if (phoneValidated && phoneValidated.length > 0) return true;
      return false;
    };

    const formatPhoneNumber = (str) => {
      //Filter only numbers from the input
      let cleaned = ('' + str).replace(/\D/g, '');
      //Check if the input is of correct length
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
      return null;
    };

    const handleSaveHelpRequest = (updatedSenior) => {
      // Requirements if Dispositioning by Admin/Specialist - FUTURE STORY
      if (auth.roles.includes('ADMIN') || auth.roles.includes('AFTERCARE_SPECIALIST')) {
        writeAdminHelpRequest();

        console.log('...........in handleSaveHelpRequest: ', requestWasClosed ? 'true' : 'false');
        if (requestWasClosed) {
          sendHelpRequestClosedEmail(new Date().getYear() + store.helpAppId.toString().padStart(6, 0), form);
        }

        return;
      }

      const wasContactInfoUpdated = (original, updated) => {
        if (
          original.alumniUpdatedFirstName !== updated.alumni_updated_first_name ||
          original.alumniUpdatedLastName !== updated.alumni_updated_last_name ||
          original.alumniPhone1 !== updated.alumni_phone_1 ||
          original.alumniPhone1Type !== updated.alumni_phone_1_type ||
          original.alumniPhone2 !== updated.alumni_phone_2 ||
          original.alumniPhone2Type !== updated.alumni_phone_2_type ||
          original.alumniPhone3 !== updated.alumni_phone_3 ||
          original.alumniPhone3Type !== updated.alumni_phone_3_type ||
          original.alumniAddress !== updated.alumni_address ||
          original.alumniAddress2 !== updated.alumni_address2 ||
          original.alumniCity !== updated.alumni_city ||
          original.alumniState !== updated.alumni_state ||
          original.alumniZip !== updated.alumni_zip ||
          original.alumniCountry !== updated.alumni_country
        ) {
          return true;
        } else if (updated.alumni_updated_first_name && updated.alumni_updated_first_name.length > 0) {
          // Checking if first name was modified from either alumni name or first name
          if (updated.alumni_updated_first_name !== original.alumni_updated_first_name && updated.alumni_updated_first_name !== original.first_name) {
            return true;
          }
        } else if (original.alumni_updated_last_name && original.alumni_updated_last_name.length > 0) {
          // Checking if second name was modified from either alumni name or first name
          if (updated.alumni_updated_last_name !== original.alumni_updated_last_name && updated.alumni_updated_last_name !== original.last_name) {
            return true;
          }
        } else {
          return false;
        }
      };

      async function writeHelpRequest() {
        if (canSubmit()) {
          try {
            store.setLoading(false);
            store.setSearching(true);

            const item = {
              id: store.helpAppId,
              created_at: store.helpApp.created_at || new Date(),
              updated_at: new Date(),
              userId: store.senior.userId,
              application: form,
              submitted_date: new Date(),
              status: 'new',
              status_date: new Date(),
              status_by: auth.user.result.first_name + ' ' + auth.user.result.last_name,
              resolution: formResolution,
              resolution_comment: resolutionComment,
            };

            await callApi(auth, 'helprequest', 'PUT', { item });

            await sendReceivedHelpRequestEmail(store.senior.id);
            await sendHelpRequestSubmittedEmail(store.senior.id, new Date().getYear() + store.helpAppId.toString().padStart(6, 0), form);

            appInsights.trackTrace({ message: `Help Request submitted by ${auth.user.result.email}` });
            const auditObj = {
              audit_ts: new Date(),
              user_id: auth.user.result.id,
              username: auth.user.result.first_name + ' ' + auth.user.result.last_name,
              audit_type: 'help request',
              action: 'submitted',
              value: JSON.stringify(form).substring(0, 999),
            };
            logAudit(auth, auditObj);

            store.setSearching(false);
            store.setLoading(false);
            handleSaveReturn();
          } catch (error) {
            logException(error);
            store.setSearching(false);
            store.setLoading(false);
            console.log('Error writing updated help request:', error);
          }
        }
      }

      async function writeAdminHelpRequest() {
        try {
          store.setLoading(false);
          store.setSearching(true);

          const item = {
            id: store.helpAppId,
            created_at: store.helpApp.created_at,
            updated_at: new Date(),
            userId: store.helpApp.userId,
            application: form,
            submitted_date: store.helpApp.submitted_date,
            status: formResolution.closeRequest ? 'closed' : assignedTo > 0 ? 'inprogress' : originalStatus,
            status_date: new Date(),
            status_by: store.helpApp.status_by, // if admin update, do NOT update the status_by.
            assigned_to: assignedTo,
            assigned_date: assignedDate,
            resolution: formResolution,
            resolution_comment: resolutionComment,
          };

          await callApi(auth, 'helprequest', 'PUT', { item });

          // Send Assigned Email to Specialist //
          if (auth.roles.includes('ADMIN') || auth.roles.includes('AFTERCARE_SPECIALIST')) {
            if (sendAssignedToEmail && assignedTo && assignedTo > 0) {
              const specialistEmail = store.aftercareSpecialistList.find((s) => s.id === assignedTo).user.email;
              sendHelpRequestAssignedEmail(specialistEmail, new Date().getYear() + store.helpAppId.toString().padStart(6, 0), form);
              setSendAssignedToEmail(false);
            }
          }
          //

          appInsights.trackTrace({ message: `Help Request updated by admin ${auth.user.result.email}` });
          const auditObj = {
            audit_ts: new Date(),
            user_id: auth.user.result.id,
            username: auth.user.result.first_name + ' ' + auth.user.result.last_name,
            audit_type: 'help request',
            action: 'disposition',
            value: JSON.stringify(formResolution).substring(0, 999),
          };
          logAudit(auth, auditObj);

          store.setSearching(false);
          store.setLoading(false);
          handleSaveReturn();
        } catch (error) {
          logException(error);
          store.setSearching(false);
          store.setLoading(false);
          console.log('Error writing admin updated help request:', error);
        }
      }

      function validateContactInformation() {
        let rc = true;
        let requiredFields = [];

        if (contactInfo.alumniPhone1.length > 0 && contactInfo.alumniPhone1Type.length === 0) {
          requiredFields = [
            ...requiredFields,
            {
              requirement: 'Please select a phone type for your phone number 1.',
            },
          ];
          rc = false;
        }
        if (contactInfo.alumniPhone2.length > 0 && contactInfo.alumniPhone2Type.length === 0) {
          requiredFields = [
            ...requiredFields,
            {
              requirement: 'Please select a phone type for your phone number 2.',
            },
          ];
          rc = false;
        }
        if (contactInfo.alumniPhone3.length > 0 && contactInfo.alumniPhone3Type.length === 0) {
          requiredFields = [
            ...requiredFields,
            {
              requirement: 'Please select a phone type for your phone number 3.',
            },
          ];
          rc = false;
        }

        if (contactInfo.alumniPhone1.length === 0 && contactInfo.alumniPhone1Type.length > 0) {
          requiredFields = [
            ...requiredFields,
            {
              requirement: 'Please enter a phone number for phone type ' + contactInfo.alumniPhone1Type + '.',
            },
          ];
          rc = false;
        }
        if (contactInfo.alumniPhone2.length === 0 && contactInfo.alumniPhone2Type.length > 0) {
          requiredFields = [
            ...requiredFields,
            {
              requirement: 'Please enter a phone number for phone type ' + contactInfo.alumniPhone2Type + '.',
            },
          ];
          rc = false;
        }
        if (contactInfo.alumniPhone3.length === 0 && contactInfo.alumniPhone3Type.length > 0) {
          requiredFields = [
            ...requiredFields,
            {
              requirement: 'Please enter a phone number for phone type ' + contactInfo.alumniPhone3Type + '.',
            },
          ];
          rc = false;
        }

        if (contactInfo.alumniPhone1.length > 0 && validatePhoneNumber(contactInfo.alumniPhone1) === false) {
          requiredFields = [
            ...requiredFields,
            {
              requirement: 'Please enter a valid phone number for phone type ' + contactInfo.alumniPhone1Type + '.',
            },
          ];
          rc = false;
        }
        if (contactInfo.alumniPhone2.length > 0 && validatePhoneNumber(contactInfo.alumniPhone2) === false) {
          requiredFields = [
            ...requiredFields,
            {
              requirement: 'Please select a valid phone number for phone type ' + contactInfo.alumniPhone2Type + '.',
            },
          ];
          rc = false;
        }
        if (contactInfo.alumniPhone3.length > 0 && validatePhoneNumber(contactInfo.alumniPhone3) === false) {
          requiredFields = [
            ...requiredFields,
            {
              requirement: 'Please select a valid phone number for phone type ' + contactInfo.alumniPhone3Type + '.',
            },
          ];
          rc = false;
        }

        if (
          contactInfo.alumniCountry.length > 0 &&
          contactInfo.alumniCountry !== 'USA' &&
          contactInfo.alumniCountry !== 'United States' &&
          contactInfo.alumniCountry !== 'United States of America'
        ) {
          // If country other than USA and either address, city, zip completed, require all.
          if (contactInfo.alumniAddress.length > 0 || contactInfo.alumniCity.length > 0 || contactInfo.alumniZip.length > 0) {
            if (contactInfo.alumniAddress.length === 0 || contactInfo.alumniCity.length === 0 || contactInfo.alumniZip.length === 0) {
              requiredFields = [
                ...requiredFields,
                {
                  requirement: 'Please enter a complete address, including city, and zip/postal code.',
                },
              ];
              rc = false;
            }
          }
        } else {
          // If either address, city, state zip completed, require all.
          if (
            contactInfo.alumniAddress.length > 0 ||
            contactInfo.alumniCity.length > 0 ||
            contactInfo.alumniState.trim().length > 0 ||
            contactInfo.alumniZip.length > 0
          ) {
            if (
              contactInfo.alumniAddress.length === 0 ||
              contactInfo.alumniCity.length === 0 ||
              contactInfo.alumniState.trim().length === 0 ||
              contactInfo.alumniZip.length === 0
            ) {
              requiredFields = [
                ...requiredFields,
                {
                  requirement: 'Please enter a complete address, including city, state, and zip code.',
                },
              ];
              rc = false;
            }
          }
        }

        let errors = [];
        requiredFields.forEach((req, idx) => {
          errors.push(req.requirement);
        });
        setErrorMsg(errors);
        if (errors.length > 0) {
          setWarning('The help request could not be submitted. See the errors at the bottom of the form.');
          setShowWarning(true);
        }
        return rc;
      }

      async function updateContactInformation() {
        if (validateContactInformation()) {
          try {
            store.setLoading(false);
            store.setSearching(true);

            let updatedSenior = store.senior;

            updatedSenior.alumni_phone_1 = contactInfo.alumniPhone1;
            updatedSenior.alumni_phone_1_type = contactInfo.alumniPhone1Type;
            updatedSenior.alumni_phone_2 = contactInfo.alumniPhone2;
            updatedSenior.alumni_phone_2_type = contactInfo.alumniPhone2Type;
            updatedSenior.alumni_phone_3 = contactInfo.alumniPhone3;
            updatedSenior.alumni_phone_3_type = contactInfo.alumniPhone3Type;
            updatedSenior.alumni_address = contactInfo.alumniAddress;
            updatedSenior.alumni_address2 = contactInfo.alumniAddress2;
            updatedSenior.alumni_city = contactInfo.alumniCity;
            updatedSenior.alumni_state = contactInfo.alumniState;
            updatedSenior.alumni_zip = contactInfo.alumniZip;
            updatedSenior.alumni_country = contactInfo.alumniCountry;
            updatedSenior.alumni_updated_first_name = contactInfo.alumniUpdatedFirstName;
            updatedSenior.alumni_updated_last_name = contactInfo.alumniUpdatedLastName;
            updatedSenior.updated_by = auth.user.result.id;
            updatedSenior.updated_at = getCurrentDateTime();

            // Contact Update logging //
            if (wasContactInfoUpdated(originalContactInfo, updatedSenior)) {
              updatedSenior.contact_updated_at = new Date();
              updatedSenior.contact_updated_by = auth.user.result.id;

              // This MUST be here so the JSON is correctly formatted when written back to the db.
              updatedSenior.planning_form_1 = planningForm1;
              updatedSenior.planning_form_2 = planningForm2;
              updatedSenior.checklist = checkList;

              const student = await callApi(auth, 'student', 'PUT', {
                item: updatedSenior,
              });

              appInsights.trackTrace({ message: `Contact Information updated by ${auth.user.result.email}` });
              const auditObj = {
                audit_ts: new Date(),
                user_id: auth.user.result.id,
                username: auth.user.result.first_name + ' ' + auth.user.result.last_name,
                audit_type: 'contact info',
                action: 'updated',
                value: JSON.stringify(updatedSenior).substr(0, 999),
              };
              logAudit(auth, auditObj);

              if (student.result) {
                store.setSenior(student.result);
              }
              store.setSenior(student.result);
              let allseniors = store.allseniors;
              var index = allseniors.indexOf(student.result);
              allseniors[index] = student.result;
              store.setSeniorData(allseniors);
              store.setAllSeniors(allseniors);
            }
          } catch (error) {
            logException(error);
            store.setSearching(false);
            store.setLoading(false);
            console.log('Error writing updated help request:', error);
          }
        }
      }

      if (canSubmit()) {
        if (validateContactInformation()) {
          writeHelpRequest();
          updateContactInformation();
          store.setSearching(false);
          store.setLoading(false);
          handleSaveReturn();
        }
      }
    };

    const canSubmit = () => {
      let rc = true;
      let requiredFields = [];

      let totalOptionsSelected = 0;
      if (form.coaching) totalOptionsSelected += 1;
      if (form.dailyLiving) totalOptionsSelected += 1;
      if (form.education) totalOptionsSelected += 1;
      if (form.employment) totalOptionsSelected += 1;
      if (form.finances) totalOptionsSelected += 1;
      if (form.food) totalOptionsSelected += 1;
      if (form.health) totalOptionsSelected += 1;
      if (form.housing) totalOptionsSelected += 1;
      if (form.legalDocs) totalOptionsSelected += 1;
      if (form.lifeSkills) totalOptionsSelected += 1;
      if (form.mentalHealth) totalOptionsSelected += 1;
      if (form.scholarships) totalOptionsSelected += 1;
      if (form.transportation) totalOptionsSelected += 1;
      if (form.utilities) totalOptionsSelected += 1;
      if (form.other) totalOptionsSelected += 1;

      if (totalOptionsSelected < 1) {
        requiredFields = [...requiredFields, { requirement: 'Please indicate why you are contacting us today.' }];
        rc = false;
      }

      if (!form.additionalInfo || form.additionalInfo.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please enter additional information about this request or ask a question.' }];
        rc = false;
      }

      if (!contactInfo.alumniUpdatedFirstName || contactInfo.alumniUpdatedFirstName.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please enter your legal first name.' }];
        rc = false;
      }

      if (!contactInfo.alumniUpdatedLastName || contactInfo.alumniUpdatedLastName.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please enter your legal last name.' }];
        rc = false;
      }

      if (form.housing) {
        if (!form.maritalStatus || form.maritalStatus.length === 0) {
          requiredFields = [
            ...requiredFields,
            { requirement: 'Your marital status is required when requesting help with housing. Please indicate your marital status.' },
          ];
          rc = false;
        }
        if (!form.haveChildren) {
          requiredFields = [
            ...requiredFields,
            {
              requirement:
                'You must indicate whether you have children when requesting help with housing. Please indicate whether you have children.',
            },
          ];
          rc = false;
        }
      }

      if (form.consent === false) {
        requiredFields = [...requiredFields, { requirement: 'You must consent in order to submit your request. Please check the consent box.' }];
        rc = false;
      }

      let errors = [];
      requiredFields.forEach((req, idx) => {
        errors.push(req.requirement);
      });
      setErrorMsg(errors);
      if (errors.length > 0) {
        setWarning('The help request could not be submitted. See the errors at the bottom of the form.');
        setShowWarning(true);
      }
      return rc;
    };

    const isCloseRequestDisabled = () => {
      if (status === 'closed') return true;
      if (form) {
        if (formResolution) {
          if (form && form.coaching && formResolution.coaching.length === 0) return true;
          if (form.dailyLiving && formResolution.dailyLiving.length === 0) return true;
          if (form.education && formResolution.education.length === 0) return true;
          if (form.employment && formResolution.employment.length === 0) return true;
          if (form.finances && formResolution.finances.length === 0) return true;
          if (form.food && formResolution.food.length === 0) return true;
          if (form.health && formResolution.health.length === 0) return true;
          if (form.housing && formResolution.housing.length === 0) return true;
          if (form.legalDocs && formResolution.legalDocs.length === 0) return true;
          if (form.lifeSkills && formResolution.lifeSkills.length === 0) return true;
          if (form.mentalHealth && formResolution.mentalHealth.length === 0) return true;
          if (form.scholarships && formResolution.scholarships.length === 0) return true;
          if (form.transportation && formResolution.transportation.length === 0) return true;
          if (form.utilities && formResolution.utilities.length === 0) return true;
          if (form.other && formResolution.coaching.other === 0) return true;
        }
      }
      return false;
    };

    const showSaveButton = () => {
      // Status Values: created, new, inprogress, closed
      if (auth.roles.includes('ADMIN') || auth.roles.includes('AFTERCARE_SPECIALIST')) {
        if (status === 'new' || status === 'inprogress') {
          return true;
        }
      }
      return false;
    };

    const isFormDisabled = () => {
      if (status === 'new' || status === 'inprogress' || status === 'closed') {
        return true;
      }
      return false;
    };

    const showAdminPanel = () => {
      let rc = false;
      if (auth.roles.includes('ADMIN') || auth.roles.includes('AFTERCARE_SPECIALIST')) {
        rc = true;
      }
      return rc;
    };

    const showDisposition = () => {
      let rc = false;
      if (auth.roles.includes('ADMIN') || auth.roles.includes('AFTERCARE_SPECIALIST')) {
        // Status Values: created, new, inprogress, closed
        if (originalStatus === 'inprogress') {
          rc = true;
        }
      }
      return rc;
    };

    const assignApp = (val) => {
      setSendAssignedToEmail(true);
      setAssignedTo(val);
      setAssignedDate(new Date());
    };

    const showAdminResolution = () => {
      let rc = false;
      if (auth.roles.includes('ADMIN') || auth.roles.includes('AFTERCARE_SPECIALIST')) {
        // Status Values: created, new, inprogress, closed
        if (originalStatus === 'closed') {
          rc = true;
        }
      }
      return rc;
    };

    const displayStatus = (status) => {
      if (status === 'created') {
        return 'Started';
      }
      if (status === 'new') {
        return 'New';
      }
      if (status === 'inprogress') {
        return 'In Progress';
      }
      if (status === 'closed') {
        return 'Closed';
      }
      return status;
    };

    const displayDisposition = (val) => {
      if (val === 'notreached') {
        return 'Not Reached';
      }
      if (val === 'fulfilled') {
        return 'Fulfilled';
      }
      if (val === 'unabletohelp') {
        return 'Unable to Help';
      }
      if (val === 'notqualified') {
        return 'Not Qualified';
      }
      return val;
    };

    const summarizeHelpOptions = (appValues) => {
      if (!appValues || (appValues && appValues.length === 0)) return [];
      let list = [];
      if (appValues.coaching) {
        list.push('Coaching');
      }
      if (appValues.dailyLiving) {
        list.push('Daily Living');
      }
      if (appValues.education) {
        list.push('Education');
      }
      if (appValues.employment) {
        list.push('Employment');
      }
      if (appValues.finances) {
        list.push('Finances');
      }
      if (appValues.food) {
        list.push('Food');
      }
      if (appValues.health) {
        list.push('Health');
      }
      if (appValues.housing) {
        list.push('Housing');
      }
      if (appValues.legalDocs) {
        list.push('Legal Documents');
      }
      if (appValues.lifeSkills) {
        list.push('Life Skills');
      }
      if (appValues.mentalHealth) {
        list.push('Mental Health');
      }
      if (appValues.scholarships) {
        list.push('Scholarships');
      }
      if (appValues.transportation) {
        list.push('Transportation');
      }
      if (appValues.utilities) {
        list.push('Utilities');
      }
      if (appValues.other) {
        list.push('Other');
      }
      return list;
    };

    const sendReceivedHelpRequestEmail = async (id) => {
      try {
        store.setLoading(true);
        store.setSearching(true);
        await callApi(auth, 'receivedhelprequestemail', 'POST', { student_id: id, first_name: auth.user.result.first_name });
        store.setSearching(false);
        store.setLoading(false);
      } catch (error) {
        logException(error);
        store.setSearching(false);
        store.setLoading(false);
        console.log('Error sending received help request email:', error);
      }
    };

    const sendHelpRequestSubmittedEmail = async (id, request_id, request_reasons) => {
      try {
        store.setLoading(true);
        store.setSearching(true);
        await callApi(auth, 'helprequestsubmittedemail', 'POST', {
          student_id: id,
          request_id,
          request_reasons: summarizeHelpOptions(request_reasons).join(', ') || '',
        });
        store.setSearching(false);
        store.setLoading(false);
      } catch (error) {
        logException(error);
        store.setSearching(false);
        store.setLoading(false);
        console.log('Error sending received help request email:', error);
      }
    };

    const sendHelpRequestAssignedEmail = async (email, request_id, request_reasons) => {
      try {
        store.setLoading(true);
        store.setSearching(true);
        await callApi(auth, 'helprequestassignedemail', 'POST', {
          request_id,
          request_reasons: summarizeHelpOptions(request_reasons).join(', ') || '',
          email,
        });
        store.setSearching(false);
        store.setLoading(false);
      } catch (error) {
        logException(error);
        store.setSearching(false);
        store.setLoading(false);
        console.log('Error sending help request assigned email:', error);
      }
    };

    const sendHelpRequestClosedEmail = async (request_id, request_reasons) => {
      try {
        store.setLoading(true);
        store.setSearching(true);
        await callApi(auth, 'helprequestclosedemail', 'POST', {
          request_id,
          request_reasons: summarizeHelpOptions(request_reasons).join(', ') || '',
        });
        store.setSearching(false);
        store.setLoading(false);
      } catch (error) {
        logException(error);
        store.setSearching(false);
        store.setLoading(false);
        console.log('Error sending closed help request email:', error);
      }
    };

    const closeHelpRequest = () => {
      setFormResolution({ ...formResolution, closeRequest: !formResolution.closeRequest });
      setRequestWasClosed(!formResolution.closeRequest);
    };

    return (
      <React.Fragment>
        {store.helpApp && (
          <summary>
            {auth.roles.includes('ALUMNI') && <aside className={classes.title}>How can we help?</aside>}
            <Grid container align="left" className={classes.unprintedContent}>
              <Grid item xs={12} align="center" className={classes.messageHeader}>
                <aside className={classes.statusHeader}>Status: {displayStatus(status)}</aside>
                <aside className={classes.caseNumber}>
                  {/* Formatting record.id as a case number - use created year! */}
                  Case #{new Date(dateTimeToS(store.helpApp.created_at)).getYear() + store.helpApp.id.toString().padStart(6, 0)}
                </aside>
                {!auth.roles.includes('ALUMNI') && (
                  <summary style={{ textAlign: 'left', margin: '0 0 50px 0' }}>
                    <div>
                      <label>Submitted By: </label>
                      <strong>{seniorName}</strong>
                    </div>
                    <div>
                      <label>Email: </label>
                      <strong>{seniorEmail}</strong>
                    </div>
                    <div>
                      <label>Phone: </label>
                      <strong>{formatPhoneNumber(seniorPhone)}</strong>
                    </div>
                    <div>
                      <label>Date Received: </label>
                      <strong>{dateToS(store.helpApp.submitted_date)}</strong>
                    </div>
                  </summary>
                )}
                {auth.roles.includes('ALUMNI') && (
                  <aside className={classes.error} style={{ fontSize: '1.25rem', padding: 5 }}>
                    If you are in a crisis situation and need immediate assistance, call the Boys Town National Hotline at 800-448-3000.
                  </aside>
                )}
                {auth.roles.includes('ALUMNI') && (
                  <summary className={classes.helpRequestNote}>
                    <strong>Please note that it will take approximately two business days for us to respond to help requests.</strong>
                  </summary>
                )}
              </Grid>
            </Grid>
            <Grid container direction="row" className={classes.printedContent}>
              <Grid item xs={12}>
                <aside style={{ textAlign: 'center' }}>Status: {displayStatus(status)}</aside>
                <aside style={{ textAlign: 'center', fontWeight: 700 }}>
                  {/* Formatting record.id as a case number - use created year! */}
                  Case #{new Date(dateTimeToS(store.helpApp.created_at)).getYear() + store.helpApp.id.toString().padStart(6, 0)}
                </aside>
                {auth.roles.includes('ADMIN') ||
                  (auth.roles.includes('AFTERCARE_SPECIALIST') && (
                    <summary style={{ textAlign: 'left' }}>
                      <div>
                        <label>Submitted By: </label>
                        <strong>{seniorName}</strong>
                      </div>
                      <div>
                        <label>Email: </label>
                        <strong>{seniorEmail}</strong>
                      </div>
                      <div>
                        <label>Phone: </label>
                        <strong>{formatPhoneNumber(seniorPhone)}</strong>
                      </div>
                      <div>
                        <label>Date Received: </label>
                        <strong>{dateToS(store.helpApp.submitted_date)}</strong>
                      </div>
                    </summary>
                  ))}
                {auth.roles.includes('ALUMNI') && (
                  <aside className={classes.error} style={{ fontSize: '1.25rem', padding: 5 }}>
                    If you are in a crisis situation and need immediate assistance, call the Boys Town National Hotline at 800-448-3000.
                  </aside>
                )}
                {auth.roles.includes('ALUMNI') && (
                  <summary className={classes.helpRequestNote}>
                    <strong>Please note that it will take approximately two business days for us to respond to help requests.</strong>
                  </summary>
                )}
              </Grid>
            </Grid>
          </summary>
        )}
        {store.helpApp && (
          <Grid container key={store.senior.userId} align="left">
            <Grid container direction="row">
              <Grid item xs={12} align="left" className={classes.firstSectionSpacer}>
                <section align="left" className={classes.firstSectionTitle}>
                  Check the reason you are contacting us today. (Please check all that apply.) <span style={{ color: 'red' }}>*</span>
                </section>
              </Grid>
            </Grid>
            <Grid container direction="row" className={classes.checkboxSpacer}>
              <Grid item xs={12} md={6} align="left">
                <FormControl fullWidth>
                  <FormControlLabel
                    control={<Checkbox color="default" />}
                    label="Coaching / Mentoring"
                    checked={form.coaching}
                    onChange={(event) => setForm({ ...form, coaching: !form.coaching })}
                    disabled={isFormDisabled()}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={<Checkbox color="default" />}
                    label="Daily Living (cell, clothes, hygiene)"
                    checked={form.dailyLiving}
                    onChange={(event) => setForm({ ...form, dailyLiving: !form.dailyLiving })}
                    disabled={isFormDisabled()}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={<Checkbox color="default" />}
                    label="Education Support"
                    checked={form.education}
                    onChange={(event) => setForm({ ...form, education: !form.education })}
                    disabled={isFormDisabled()}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={<Checkbox color="default" />}
                    label="Employment"
                    checked={form.employment}
                    onChange={(event) => setForm({ ...form, employment: !form.employment })}
                    disabled={isFormDisabled()}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={<Checkbox color="default" />}
                    label="Finances"
                    checked={form.finances}
                    onChange={(event) => setForm({ ...form, finances: !form.finances })}
                    disabled={isFormDisabled()}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={<Checkbox color="default" />}
                    label="Food"
                    checked={form.food}
                    onChange={(event) => setForm({ ...form, food: !form.food })}
                    disabled={isFormDisabled()}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={<Checkbox color="default" />}
                    label="Health Services"
                    checked={form.health}
                    onChange={(event) => setForm({ ...form, health: !form.health })}
                    disabled={isFormDisabled()}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={<Checkbox color="default" />}
                    label="Housing"
                    checked={form.housing}
                    onChange={(event) => setForm({ ...form, housing: !form.housing })}
                    disabled={isFormDisabled()}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} align="left">
                <FormControl fullWidth>
                  <FormControlLabel
                    control={<Checkbox color="default" />}
                    label="Legal Documents"
                    checked={form.legalDocs}
                    onChange={(event) => setForm({ ...form, legalDocs: !form.legalDocs })}
                    disabled={isFormDisabled()}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={<Checkbox color="default" />}
                    label="Life Skills Training"
                    checked={form.lifeSkills}
                    onChange={(event) => setForm({ ...form, lifeSkills: !form.lifeSkills })}
                    disabled={isFormDisabled()}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={<Checkbox color="default" />}
                    label="Mental Health Services"
                    checked={form.mentalHealth}
                    onChange={(event) => setForm({ ...form, mentalHealth: !form.mentalHealth })}
                    disabled={isFormDisabled()}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={<Checkbox color="default" />}
                    label="Scholarships and Awards"
                    checked={form.scholarships}
                    onChange={(event) => setForm({ ...form, scholarships: !form.scholarships })}
                    disabled={isFormDisabled()}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={<Checkbox color="default" />}
                    label="Transportation"
                    checked={form.transportation}
                    onChange={(event) => setForm({ ...form, transportation: !form.transportation })}
                    disabled={isFormDisabled()}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={<Checkbox color="default" />}
                    label="Utilities"
                    checked={form.utilities}
                    onChange={(event) => setForm({ ...form, utilities: !form.utilities })}
                    disabled={isFormDisabled()}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={<Checkbox color="default" />}
                    label="Other"
                    checked={form.other}
                    onChange={(event) => setForm({ ...form, other: !form.other })}
                    disabled={isFormDisabled()}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container direction="row" className={classes.rowSpacer} />
            {/* Additional Information/Question */}
            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} md={5} lg={3} className={classes.rowSpacer}>
                <label className={classes.firstSectionTitle}>Additional Information / Questions</label>{' '}
                <span style={{ color: 'red', fontWeight: 700 }}>*</span>
              </Grid>
              <Grid item xs={12} className={classes.textAreaSpacer}>
                <FormControl fullWidth>
                  <TextareaAutosize
                    aria-label="additional-information"
                    minRows={5}
                    maxRows={5}
                    maxLength={1000}
                    value={form.additionalInfo}
                    onChange={(event) => setForm({ ...form, additionalInfo: event.target.value })}
                    className={classes.textAreaFont}
                    disabled={isFormDisabled()}
                    style={{ color: isFormDisabled() && 'rgba(0, 0, 0, 0.3)' }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.printedContent}>
              <div>
                <label>Additional Information / Questions</label>
                <div style={{ marginTop: 10, whiteSpace: 'pre-wrap' }}>{form.additionalInfo || null}</div>
                <br />
              </div>
            </div>

            {!auth.roles.includes('ADMIN') && !auth.roles.includes('AFTERCARE_SPECIALIST') && (
              <HelpRequestContact
                phoneTypes={phoneTypes}
                isFormDisabled={isFormDisabled}
                contactInfo={contactInfo}
                setContactInfo={setContactInfo}
                phone1IsValid={phone1IsValid}
                setPhone1IsValid={setPhone1IsValid}
                phone2IsValid={phone2IsValid}
                setPhone2IsValid={setPhone2IsValid}
                phone3IsValid={phone3IsValid}
                setPhone3IsValid={setPhone3IsValid}
                formatPhoneNumber={formatPhoneNumber}
                email={auth.user.result.email}
                classes={classes}
              />
            )}

            <Grid container direction="row" className={classes.unprintedContent}>
              <Grid item xs={12} sm={7} md={3} lg={3} className={classes.textRowLabelSpacer}>
                <label id="marital-status-label">Marital Status {form.housing && <span style={{ color: 'red', fontWeight: 700 }}>*</span>}</label>
              </Grid>
              <Grid item xs={12} sm={5} md={3} lg={3} className={classes.textEditSpacer}>
                <FormControl fullWidth>
                  <Select
                    id="marital-status"
                    value={form.maritalStatus}
                    className={classes.valignSelect}
                    onChange={(event) => setForm({ ...form, maritalStatus: event.target.value })}
                    disabled={isFormDisabled()}
                  >
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="Single">Single</MenuItem>
                    <MenuItem value="Married">Married</MenuItem>
                    <MenuItem value="Separated">Separated</MenuItem>
                    <MenuItem value="Divorced">Divorced</MenuItem>
                    <MenuItem value="Widowed">Widowed</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={false} sm={false} md={6} lg={6} className={classes.textRowSpacer}></Grid>
              <Grid item xs={12} className={classes.textEditSpacer}></Grid>
              <Grid item xs={12} sm={7} md={3} lg={3} className={classes.textRowLabelSpacer}>
                <label>Do you have, or are you expecting, children? {form.housing && <span style={{ color: 'red', fontWeight: 700 }}>*</span>}</label>
              </Grid>
              <Grid item xs={12} sm={5} md={3} lg={2} className={classes.textRowSpacer}>
                <FormControl fullWidth>
                  <RadioGroup
                    row
                    aria-label="Do you have, or are you expecting, children?"
                    name="children"
                    value={form.haveChildren}
                    onChange={(event) => setForm({ ...form, haveChildren: event.target.value })}
                    disabled={isFormDisabled()}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio color="default" disabled={isFormDisabled()} />}
                      label={<span className={classes.radioLabel}>Yes</span>}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio color="default" disabled={isFormDisabled()} />}
                      label={<span className={classes.radioLabel}>No</span>}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={false} sm={false} md={6} lg={7} className={classes.textRowSpacer}></Grid>
            </Grid>
            <Grid container direction="row" className={classes.printedContent}>
              <Grid item xs={12}>
                <label>Marital Status</label>
                <br />
                {form.maritalStatus || 'N/A'}
                <br />
                <br />
                <label>Do you have, or are you expecting, children?</label>
                <br />
                {form.haveChildren || 'N/A'}
                <br />
              </Grid>
            </Grid>

            <Grid container direction="row" style={{ margin: '30px 0 20px 0' }}>
              <Grid item xs={12} className={classes.consentSectionSpacer}>
                <FormControl fullWidth>
                  <FormControlLabel
                    style={{ display: 'table' }}
                    control={
                      <div style={{ display: 'table-cell' }}>
                        <Checkbox
                          color="default"
                          checked={form.consent}
                          onChange={(event) => setForm({ ...form, consent: !form.consent })}
                          disabled={isFormDisabled()}
                        />
                      </div>
                    }
                    label={
                      <Typography className={classes.consentCheckbox} style={{ verticalAlign: 'top' }}>
                        I voluntarily give consent to Boys Town Successful Futures Department to contact me for the purpose of service provision. I
                        acknowledge that no guarantees have been made to me as to the availability and results of services. I am aware that this
                        consent can be revoked in writing at any time. I understand that Boys Town will protect my privacy and rights and will not use
                        my information for any other purpose. I understand that Boys Town follows a policy of non-discrimination on the basis of race,
                        color, national origin, gender, sexual orientation, age, or disability. I understand that my signature indicates that all the
                        above information is true to the best of my knowledge.
                      </Typography>
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container direction="row">
              <Grid item xs={false} sm={false} md={2} lg={3} className={classes.textRowLabelSpacer} />
              <Grid item xs={12} sm={12} md={10} lg={9} className={classes.textRowSpacer}>
                <summary className={classes.error}>
                  {errorMsg &&
                    errorMsg.length > 0 &&
                    errorMsg.map((err, idx) => {
                      return <li>{err}</li>;
                    })}
                </summary>
              </Grid>
            </Grid>
            {showDisposition() && status === 'new' && store.helpApp.submitted_date && (
              <Grid container direction="row">
                <Grid item xs={12} align="left" className={classes.firstSectionSpacer}>
                  <section align="left" className={classes.submittedInfo}>
                    <label>Help Request Submitted:</label> {dateTimeToS(store.helpApp.submitted_date)}
                  </section>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
        {showAdminPanel() && (
          <Grid
            container
            direction="row"
            className={classes.unprintedContent}
            style={{ marginTop: 20, padding: 20, border: '1px solid darkgreen', background: '#dcf5dc' }}
          >
            <Grid item xs={12} align="center" className={classes.textArea5RowSpacer}>
              <label style={{ fontWeight: 600 }}>Successful Futures Administration Only</label>
            </Grid>
            <React.Fragment>
              <Grid item xs={12} sm={4} md={2} lg={2} className={classes.labelInlineEditSpacer} align="left">
                <label style={{ fontWeight: 600, color: '#000000' }}>{status === 'closed' ? 'Assigned To' : 'Assign Help Request To'}</label>
              </Grid>
              <Grid item xs={11} sm={4} md={3} lg={3} className={classes.textEditSpacer} align="left">
                <FormControl fullWidth>
                  <Select
                    labelId="aftercare-specialist-label"
                    id="aftercare-specialist"
                    value={assignedTo}
                    onChange={(event) => assignApp(event.target.value)}
                    disabled={auth.roles.includes('ALUMNI') || status === 'closed'}
                  >
                    <MenuItem value="0"></MenuItem>
                    {store.aftercareSpecialistList
                      .slice()
                      .sort((a, b) => {
                        return a.user.first_name > b.user.first_name ? 1 : -1;
                      })
                      .sort((a, b) => {
                        return a.user.last_name > b.user.last_name ? 1 : -1;
                      })
                      .map((specialist) => {
                        return (
                          <MenuItem value={specialist.id}>
                            {specialist.user.first_name} {specialist.user.last_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={11} sm={4} md={6} lg={6} />
              <Grid item xs={11} sm={4} md={2} lg={2} />
              <Grid item xs={11} sm={7} md={9} lg={9} className={classes.textEditSpacer} align="left">
                <div style={{ fontSize: '.9rem', fontWeight: 600, color: '#0000FF', marginTop: 5 }}>
                  NOTE: Staff will receive an email notification that they have been assigned to this request.
                </div>
              </Grid>
            </React.Fragment>
            {showAdminResolution() && (
              <Grid container direction="row" align="left" style={{ columns: 3 }}>
                <Grid item xs={12}>
                  <summary className={classes.subTitle}>Resolutions</summary>
                </Grid>
                {form.coaching && (
                  <Grid item xs={12} sm={7} md={3} lg={3}>
                    <label>Coaching:</label> {displayDisposition(formResolution.coaching)}
                  </Grid>
                )}
                {form.dailyLiving && (
                  <Grid item xs={12} sm={7} md={3} lg={3}>
                    <label>Daily Living (cell, clothes, hygiene):</label> {displayDisposition(formResolution.dailyLiving)}
                  </Grid>
                )}
                {form.education && (
                  <Grid item xs={12} sm={7} md={3} lg={3}>
                    <label>Education Support:</label> {displayDisposition(formResolution.education)}
                  </Grid>
                )}
                {form.employment && (
                  <Grid item xs={12} sm={7} md={3} lg={3}>
                    <label>Employment:</label> {displayDisposition(formResolution.employment)}
                  </Grid>
                )}
                {form.finances && (
                  <Grid item xs={12} sm={7} md={3} lg={3}>
                    <label>Finances:</label> {displayDisposition(formResolution.finances)}
                  </Grid>
                )}
                {form.food && (
                  <Grid item xs={12} sm={7} md={3} lg={3}>
                    <label>Food:</label> {displayDisposition(formResolution.food)}
                  </Grid>
                )}
                {form.health && (
                  <Grid item xs={12} sm={7} md={3} lg={3}>
                    <label>Health Services:</label> {displayDisposition(formResolution.health)}
                  </Grid>
                )}
                {form.housing && (
                  <Grid item xs={12} sm={7} md={3} lg={3}>
                    <label>Housing:</label> {displayDisposition(formResolution.housing)}
                  </Grid>
                )}
                {form.legalDocs && (
                  <Grid item xs={12} sm={7} md={3} lg={3}>
                    <label>Legal Documents:</label> {displayDisposition(formResolution.legalDocs)}
                  </Grid>
                )}
                {form.lifeSkills && (
                  <Grid item xs={12} sm={7} md={3} lg={3}>
                    <label>Life Skills Training:</label> {displayDisposition(formResolution.lifeSkills)}
                  </Grid>
                )}
                {form.mentalHealth && (
                  <Grid item xs={12} sm={7} md={3} lg={3}>
                    <label>Mental Health Services:</label> {displayDisposition(formResolution.mentalHealth)}
                  </Grid>
                )}
                {form.scholarships && (
                  <Grid item xs={12} sm={7} md={3} lg={3}>
                    <label>Scholarships and Awards:</label> {displayDisposition(formResolution.scholarships)}
                  </Grid>
                )}
                {form.transportation && (
                  <Grid item xs={12} sm={7} md={3} lg={3}>
                    <label>Transportation:</label> {displayDisposition(formResolution.transportation)}
                  </Grid>
                )}

                {form.utilities && (
                  <Grid item xs={12} sm={7} md={3} lg={3}>
                    <label>Utilities:</label> {displayDisposition(formResolution.utilities)}
                  </Grid>
                )}
                {form.other && (
                  <Grid item xs={12} sm={7} md={3} lg={3}>
                    <label>Other:</label> {displayDisposition(formResolution.other)}
                  </Grid>
                )}
                <Grid item xs={12} style={{ paddingTop: 25 }}>
                  <label>Comments:</label> {resolutionComment}
                </Grid>
              </Grid>
            )}
            {showDisposition() && (
              <>
                <Grid container direction="row" className={classes.unprintedContent} style={{ width: '90%', margin: '10px 0 0 35px' }}>
                  {form.coaching && (
                    <HelpRequestResolution
                      reason="coaching"
                      name="Coaching / Mentoring"
                      formResolution={formResolution}
                      setFormResolution={setFormResolution}
                      classes={classes}
                    />
                  )}
                  {form.dailyLiving && (
                    <HelpRequestResolution
                      reason="dailyLiving"
                      name="Daily Living (cell, clothes, hygiene)"
                      formResolution={formResolution}
                      setFormResolution={setFormResolution}
                      classes={classes}
                    />
                  )}
                  {form.education && (
                    <HelpRequestResolution
                      reason="education"
                      name="Education Support"
                      formResolution={formResolution}
                      setFormResolution={setFormResolution}
                      classes={classes}
                    />
                  )}
                  {form.employment && (
                    <HelpRequestResolution
                      reason="employment"
                      name="Employment"
                      formResolution={formResolution}
                      setFormResolution={setFormResolution}
                      classes={classes}
                    />
                  )}
                  {form.finances && (
                    <HelpRequestResolution
                      reason="finances"
                      name="Finances"
                      formResolution={formResolution}
                      setFormResolution={setFormResolution}
                      classes={classes}
                    />
                  )}
                  {form.food && (
                    <HelpRequestResolution
                      reason="food"
                      name="Food"
                      formResolution={formResolution}
                      setFormResolution={setFormResolution}
                      classes={classes}
                    />
                  )}
                  {form.health && (
                    <HelpRequestResolution
                      reason="health"
                      name="Health Services"
                      formResolution={formResolution}
                      setFormResolution={setFormResolution}
                      classes={classes}
                    />
                  )}
                  {form.housing && (
                    <HelpRequestResolution
                      reason="housing"
                      name="Housing"
                      formResolution={formResolution}
                      setFormResolution={setFormResolution}
                      classes={classes}
                    />
                  )}
                  {form.legalDocs && (
                    <HelpRequestResolution
                      reason="legalDocs"
                      name="Legal Documents"
                      formResolution={formResolution}
                      setFormResolution={setFormResolution}
                      classes={classes}
                    />
                  )}
                  {form.lifeSkills && (
                    <HelpRequestResolution
                      reason="lifeSkills"
                      name="Life Skills Training"
                      formResolution={formResolution}
                      setFormResolution={setFormResolution}
                      classes={classes}
                    />
                  )}
                  {form.mentalHealth && (
                    <HelpRequestResolution
                      reason="mentalHealth"
                      name="Mental Health Services"
                      formResolution={formResolution}
                      setFormResolution={setFormResolution}
                      classes={classes}
                    />
                  )}
                  {form.scholarships && (
                    <HelpRequestResolution
                      reason="scholarships"
                      name="Scholarships and Awards"
                      formResolution={formResolution}
                      setFormResolution={setFormResolution}
                      classes={classes}
                    />
                  )}
                  {form.transportation && (
                    <HelpRequestResolution
                      reason="transportation"
                      name="Transportation"
                      formResolution={formResolution}
                      setFormResolution={setFormResolution}
                      classes={classes}
                    />
                  )}
                  {form.utilities && (
                    <HelpRequestResolution
                      reason="utilities"
                      name="Utilities"
                      formResolution={formResolution}
                      setFormResolution={setFormResolution}
                      classes={classes}
                    />
                  )}
                  {form.other && (
                    <HelpRequestResolution
                      reason="other"
                      name="Other"
                      formResolution={formResolution}
                      setFormResolution={setFormResolution}
                      classes={classes}
                    />
                  )}
                </Grid>
                <Grid item xs={12} className={classes.textRowSpacer} style={{ marginTop: 30 }}>
                  <FormControl fullWidth>
                    <TextField
                      id="disposition-reason"
                      size="small"
                      fullWidth
                      label="Comments"
                      variant="outlined"
                      value={resolutionComment}
                      onChange={(event) => setResolutionComment(event.target.value)}
                      disabled={auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')}
                      InputProps={{ maxLength: 1000, classes: { input: classes.textField } }}
                      className={classes.root}
                    />
                  </FormControl>
                </Grid>
              </>
            )}
            {!isCloseRequestDisabled() && (
              <>
                <Grid item xs={8} sm={6} md={3} lg={2} className={classes.textRowSpacer}>
                  <Tooltip title="All request reasons have been resolved. Click to close this help request" placement="top">
                    <FormControl fullWidth>
                      <FormControlLabel
                        style={{ display: 'table' }}
                        control={
                          <div style={{ display: 'table-cell' }}>
                            <Checkbox
                              color="default"
                              disabled={isCloseRequestDisabled()}
                              checked={formResolution.closeRequest}
                              onChange={(event) => closeHelpRequest()}
                            />
                          </div>
                        }
                        label="Close Help Request"
                      />
                    </FormControl>
                  </Tooltip>
                </Grid>
                <Grid item xs={4} sm={6} md={9} lg={10} />
              </>
            )}
          </Grid>
        )}
        <Grid container direction="row" className={classes.unprintedContent}>
          <Grid item xs={12} sm={8} md={5} lg={7} className={classes.buttonSpacer}>
            <div align="left">
              {status && status === 'created' && (
                <Tooltip title="Click to submit your help request." placement="top">
                  <Button
                    className={classes.floatingSubmitButton}
                    color="secondary"
                    variant="contained"
                    onClick={(event) => handleSaveHelpRequest()}
                    disabled={!auth.roles.includes('ALUMNI')}
                    style={{ margin: '0 15px 15px 0', width: '110px' }}
                  >
                    Submit
                  </Button>
                </Tooltip>
              )}
            </div>
            <div align="right">
              {showSaveButton() ? (
                <>
                  <Button
                    className={classes.floatingSaveButton}
                    variant="contained"
                    onClick={(event) => handleSaveReturn()}
                    disabled={!store.senior}
                    style={{ margin: '0 140px 15px 0', width: '110px' }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={classes.floatingSaveButton}
                    color="primary"
                    variant="contained"
                    onClick={(event) => handleSaveHelpRequest()}
                    disabled={!store.senior}
                    style={{ margin: '0 15px 15px 0', width: '110px' }}
                  >
                    Save
                  </Button>
                </>
              ) : (
                <Button
                  className={classes.floatingSaveButton}
                  variant="contained"
                  onClick={(event) => handleSaveReturn()}
                  disabled={!store.senior}
                  style={{ margin: '0 15px 15px 0', width: '110px' }}
                >
                  Cancel
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
        <Snackbar
          ContentProps={{
            classes: {
              root: classes.warning,
            },
          }}
          open={showWarning}
          autoHideDuration={6000}
          onClose={handleClose}
          message={warning}
          action={action}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        ></Snackbar>
      </React.Fragment>
    );
  })
);

HelpApplication.propTypes = {
  handleSaveReturn: PropTypes.func,
};

export default HelpApplication;

import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, MenuItem, Select } from '@material-ui/core';

const renderMenuItems = (items) => {
  return items.map((item, index) => (
    <MenuItem
      key={`${index}-${item.value}-${item.text.toString().replace(/\s+/g, '-')}`}
      value={item.value}
      disabled={item.disabled}
    >
      {item.text}
    </MenuItem>
  ));
};

const SelectScore = ({ labelId, id, value, onChange, disabled, items, displayEmpty, className, variant }) => {
  return (
    <FormControl fullWidth>
      <Select
        labelId={labelId}
        id={id}
        value={value}
        onChange={onChange}
        disabled={disabled}
        displayEmpty={displayEmpty}
        className={className}
        variant={variant}
        data-testid={id}
      >
        {renderMenuItems(items)}
      </Select>
    </FormControl>
  );
};

SelectScore.propTypes = {
  labelId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  displayEmpty: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.string,
};

export default SelectScore;
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, FormControl, Grid, Slider } from '@material-ui/core';
import SelectScore from './SelectScore';
import { SENIOR_PLANNING_2 } from '../../../constants/general';

const styles = makeStyles((theme) => ({
  sectionSpacer: {
    height: 50,
    padding: '10px 0',
  },
  rowSpacer: {
    height: 30,
  },
  textRowSpacer: {
    [theme.breakpoints.up('sm')]: {
      height: 50,
    },
  },
  [theme.breakpoints.down('xs')]: {
    textField: {
      fontSize: '0.75rem',
    },
    root: {
      '& .MuiFormLabel-root': {
        fontSize: '0.75rem',
      },
    },
  },
  printedContent: {
    '@media print': {
      marginBottom: 10,
    },
    '@media screen': {
      display: 'none',
    },
  },
  unprintedContent: {
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  alignPrintedContent: {
    lineHeight: '25px',
    verticalAlign: 'top',
  },
  rubricTitle: {
    fontSize: '1.05rem',
    fontWeight: 700,
  },
  rubricQuestion: {
    [theme.breakpoints.up('sm')]: {
      height: 50,
    },
    fontSize: '0.90rem',
    fontWeight: 600,
  },
  rubricExplanation: {
    fontSize: '0.90rem',
    fontStyle: 'italic',
    height: 80,
  },
  rubricScore: {
    [theme.breakpoints.up('sm')]: {
      height: 30,
    },
    fontSize: '1.05rem',
    fontWeight: 400,
  },
}));

export default function Scoring(props) {
  const { form, setForm, disabled } = props;

  const classes = styles();

  const housingRubricMarks = [
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
  ];

  const lookupMotivationValue = (value) => {
    switch (value) {
      case 1:
        return 'Student demonstrates faint interest in working on their post-graduation plan, moving through the motions and completing most basic tasks and expectations with lots of prompts, motivators, and consequences, and showing no initiative or enthusiasm.';
      case 2:
        return 'Student demonstrates some interest in working on the post-graduation plan, completes most basic tasks and expectations with a few prompts, motivators, and consequences, and shows very little initiative or enthusiasm.';
      case 3:
        return 'Student demonstrates interest in working on the post-graduation plan, completes most basic tasks and expectations without prompts, motivators, consequences, has a positive attitude, and shows some initiative and enthusiasm.';
      case 4:
        return 'Student works on their plan with persistence, a positive attitude, self-discipline, and lots of initiative and enthusiasm asking for help and keeping track of all requirements independently.';
      default:
        return '';
    }
  };

  const lookupFutureValue = (value) => {
    switch (value) {
      case 1:
        return 'Student refuses to set a realistic post-graduation plan.';
      case 2:
        return 'Student has an unrealistic view of their abilities and post-graduation plans as evidenced by contradictory MAP, ACT, and ASVAB scores, the team\'s overall unfavorable support, and student is reluctant to feedback received during the Sr. Planning sessions.';
      case 3:
        return 'Student is somewhat realistic about their abilities and post-graduation plans as evidenced by supportive MAP, ACT, and ASVAB scores and team-neutral support, student is open to feedback received during the Sr. Planning sessions.';
      case 4:
        return 'Student is realistic about their abilities and plans for their future as evidenced by supportive MAP, ACT, and ASVAB scores and team overall favorable support.';
      default:
        return '';
    }
  };

  const lookupSFTeamValue = (value) => {
    switch (value) {
      case 1:
        return 'Never: Student does not reach out via email, call, or attend afterschool hours.';
      case 2:
        return 'Sometimes: Student reaches out to SFS only when facing consequences or past due on their requirements.';
      case 3:
        return 'Often: Student reaches out to SFS with proactive questions about their senior planning binder completion and some about their post-graduation plan.';
      case 4:
        return 'Very Often: Student reaches out to SFS with proactive questions about their post-graduation plan and senior planning binder completion. Communication is fluid and allows for relationship building.';
      default:
        return '';
    }
  };

  const handleRubricMotivationSlider = (ev, value) => {
    console.log('................in handleRubricMotivationSlider: ', value, form.rubricFuture, form.rubricSFTeam);
    const explain = lookupMotivationValue(value);
    const avg = (value + form.rubricFuture + form.rubricSFTeam) / 3;
    setForm({ ...form, rubricMotivation: value, rubricMotivationExplain: explain, rubricMotivationScore: avg });
  };

  const handleRubricFutureSlider = (ev, value) => {
    console.log('................in handleRubricFutureSlider: ', value, form.rubricMotivation, form.rubricSFTeam);
    const explain = lookupFutureValue(value);
    const avg = (form.rubricMotivation + value + form.rubricSFTeam) / 3;
    setForm({ ...form, rubricFuture: value, rubricFutureExplain: explain, rubricMotivationScore: avg });
  };

  const handleRubricSFTeamSlider = (ev, value) => {
    console.log('................in handleRubricSFTeamSlider: ', value, form.rubricMotivation, form.rubricFuture);
    const explain = lookupSFTeamValue(value);
    const avg = (form.rubricMotivation + form.rubricFuture + value) / 3;
    setForm({ ...form, rubricSFTeam: value, rubricSFTeamExplain: explain, rubricMotivationScore: avg });
  };

  return (
    <>
      <Grid item xs={12} align="center" style={{ margin: '15px 0' }}>
        <label style={{ fontWeight: 600 }}>Grading the Planning Meeting Form 2</label>
      </Grid>
      <Grid item xs={12} align="left">
        <label style={{ fontWeight: 600 }}>Preparation Sheet #2</label>
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <label htmlFor="sheet-2-completed">Sheet is fully completed by student.</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <SelectScore
          labelId="sheet-2-fully-completed"
          id="sheet-2-completed"
          value={form.gradingSheet2Completed}
          onChange={(event) => setForm({ ...form, gradingSheet2Completed: event.target.value })}
          disabled={disabled}
          items={SENIOR_PLANNING_2.SHEET_2.COMPLETED.OPTIONS}
        />
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <label htmlFor="sheet-2-submitted" id="sheet-2-submitted-on-time-label">Sheet is submitted on
          time.</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <SelectScore
          labelId="sheet-2-submitted-label"
          id="sheet-2-submitted"
          value={form.gradingSheet2Submitted}
          onChange={(event) => setForm({ ...form, gradingSheet2Submitted: event.target.value })}
          disabled={disabled}
          items={SENIOR_PLANNING_2.SHEET_2.SUBMITTED.OPTIONS}
        />
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left"
            style={{ marginBottom: 10 }}>
        <label>Student is able to clearly speak about her/his plan options and rationales.</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left"
            style={{ marginBottom: 10 }}>
        <SelectScore
          labelId="sheet-2-clarity-label"
          id="sheet-2-clarity"
          value={form.gradingSheet2Clarity}
          onChange={(event) => setForm({ ...form, gradingSheet2Clarity: event.target.value })}
          disabled={disabled}
          items={SENIOR_PLANNING_2.SHEET_2.SPEAK.OPTIONS}
        />
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left"
            style={{ marginBottom: 20 }}>
        <label style={{ fontWeight: 600 }}>Preparation Sheet #2 Score</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left"
            style={{ marginBottom: 20 }}>
        {(form.gradingSheet2Completed && form.gradingSheet2Completed >= 0 ? form.gradingSheet2Completed : 0) +
          (form.gradingSheet2Submitted && form.gradingSheet2Submitted >= 0 ? form.gradingSheet2Submitted : 0) +
          (form.gradingSheet2Clarity && form.gradingSheet2Clarity >= 0 ? form.gradingSheet2Clarity : 0)}
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} align="left">
        <label style={{ fontWeight: 600 }}>Module #4</label>
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <label>Module was submitted on time.</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <SelectScore
          labelId="module-4-submitted-label"
          id="module-4-submitted"
          value={form.module4Submitted}
          onChange={(event) => setForm({ ...form, module4Submitted: event.target.value })}
          disabled={disabled}
          items={SENIOR_PLANNING_2.MODULE_4.SUBMITTED.OPTIONS}
        />
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} align="left"></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left"
            style={{ marginBottom: 35 }}>
        <label>
          Worksheets and activities (housing questions, family contract, if applicable, transportation options,
          affording a car, etc.) thoughtfully
          completed with realistic exploration of options and clear notes and follow-up descriptions.
        </label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left"
            style={{ marginBottom: 35 }}>
        <SelectScore
          labelId="module-4-completed-label"
          id="module-4-completed"
          value={form.module4Completed}
          onChange={(event) => setForm({ ...form, module4Completed: event.target.value })}
          disabled={disabled}
          items={SENIOR_PLANNING_2.MODULE_4.COMPLETED.OPTIONS}
        />
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left"
            style={{ marginBottom: 20 }}>
        <label style={{ fontWeight: 600 }}>Module #4 Score</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left"
            style={{ marginBottom: 20 }}>
        {(form.module4Submitted && form.module4Submitted >= 0 ? form.module4Submitted : 0) +
          (form.module4Completed && form.module4Completed >= 0 ? form.module4Completed : 0)}
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} align="left">
        <label style={{ fontWeight: 600 }}>Module #5</label>
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <label>Module was submitted on time.</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <SelectScore
          labelId="module-5-submitted-label"
          id="module-5-submitted"
          value={form.module5Submitted}
          onChange={(event) => setForm({ ...form, module5Submitted: event.target.value })}
          disabled={disabled}
          items={SENIOR_PLANNING_2.MODULE_5.SUBMITTED.OPTIONS}
        />
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} align="left"></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left"
            style={{ marginBottom: 20 }}>
        <label>
          Health Worksheet and activities (insurance, healthy lifestyle questions, SODAs etc.) thoughtfully completed
          with realistic exploration of
          options and clear notes and follow-up descriptions.
        </label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left"
            style={{ marginBottom: 20 }}>
        <SelectScore
          labelId="module-5-completed-label"
          id="module-5-completed"
          value={form.module5Completed}
          onChange={(event) => setForm({ ...form, module5Completed: event.target.value })}
          disabled={disabled}
          items={SENIOR_PLANNING_2.MODULE_5.COMPLETED.OPTIONS}
        />
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left"
            style={{ marginBottom: 20 }}>
        <label style={{ fontWeight: 600 }}>Module #5 Score</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left"
            style={{ marginBottom: 20 }}>
        {(form.module5Submitted && form.module5Submitted >= 0 ? form.module5Submitted : 0) +
          (form.module5Completed && form.module5Completed >= 0 ? form.module5Completed : 0)}
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} align="left">
        <label style={{ fontWeight: 600 }}>Module #6</label>
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <label>Module was submitted on time.</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <SelectScore
          labelId="module-6-submitted-label"
          id="module-6-submitted"
          value={form.module6Submitted}
          onChange={(event) => setForm({ ...form, module6Submitted: event.target.value })}
          disabled={disabled}
          items={SENIOR_PLANNING_2.MODULE_6.SUBMITTED.OPTIONS}
        />
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} align="left"></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left"
            style={{ marginBottom: 40 }}>
        <label>
          Worksheets (spirituality/faith, personal boundaries, community resources, etc.) and activities (friends,
          relationships with adults, support
          systems, time management, etc.) thoughtfully completed with realistic exploration of options and clear notes
          and follow-up descriptions.
        </label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left"
            style={{ marginBottom: 20 }}>
        <SelectScore
          labelId="module-6-completed-label"
          id="module-6-completed"
          value={form.module6Completed}
          onChange={(event) => setForm({ ...form, module6Completed: event.target.value })}
          disabled={disabled}
          items={SENIOR_PLANNING_2.MODULE_6.COMPLETED.OPTIONS}
        />
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left"
            style={{ marginBottom: 20 }}>
        <label style={{ fontWeight: 600 }}>Module #6 Score</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left"
            style={{ marginBottom: 20 }}>
        {(form.module6Submitted && form.module6Submitted >= 0 ? form.module6Submitted : 0) +
          (form.module6Completed && form.module6Completed >= 0 ? form.module6Completed : 0)}
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        <label style={{ fontWeight: 600 }}>Overall Total Score</label>
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className={classes.textRowSpacer} align="left">
        {(form.gradingSheet2Completed && form.gradingSheet2Completed >= 0 ? form.gradingSheet2Completed : 0) +
          (form.gradingSheet2Submitted && form.gradingSheet2Submitted >= 0 ? form.gradingSheet2Submitted : 0) +
          (form.gradingSheet2Clarity && form.gradingSheet2Clarity >= 0 ? form.gradingSheet2Clarity : 0) +
          (form.module4Submitted && form.module4Submitted >= 0 ? form.module4Submitted : 0) +
          (form.module4Completed && form.module4Completed >= 0 ? form.module4Completed : 0) +
          (form.module5Submitted && form.module5Submitted >= 0 ? form.module5Submitted : 0) +
          (form.module5Completed && form.module5Completed >= 0 ? form.module5Completed : 0) +
          (form.module6Submitted && form.module6Submitted >= 0 ? form.module6Submitted : 0) +
          (form.module6Completed && form.module6Completed >= 0 ? form.module6Completed : 0)}
      </Grid>
      <Grid item xs={false} sm={false} md={1} lg={1}></Grid>

      <br />
      {/* -------------------------------------------------------------------------------------------------------------------- */}
      <Grid container direction="row">
        <Grid item xs={12} align="center" style={{ margin: '25px 0 15px 0' }}>
          <div className={classes.rubricTitle}>Housing Rubric Enhancement</div>
        </Grid>
      </Grid>

      <Grid container direction="row">
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.rubricQuestion} align="left">
          1. Displays self-motivation while working on their post-graduation plan (beyond the actual completion of the
          senior planner modules).
        </Grid>
        <Grid item xs={8} sm={6} md={3} lg={4} className={classes.textRowSpacer} align="left">
          <FormControl fullWidth>
            <Box sx={{ width: 300 }}>
              <Slider
                aria-label="motivation-rubric"
                value={form.rubricMotivation}
                onChange={handleRubricMotivationSlider}
                marks={housingRubricMarks}
                step={1}
                min={0}
                max={4}
              />
            </Box>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.rubricExplanation} align="left">
          {form.rubricMotivationExplain && form.rubricMotivationExplain.length > 0 && form.rubricMotivationExplain}
        </Grid>
      </Grid>

      <Grid container direction="row">
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.rubricQuestion} align="left">
          2. Thinking about their future (SFS)
        </Grid>
        <Grid item xs={8} sm={6} md={3} lg={4} className={classes.textRowSpacer} align="left">
          <FormControl fullWidth>
            <Box sx={{ width: 300 }}>
              <Slider
                aria-label="future-rubric"
                value={form.rubricFuture}
                onChange={handleRubricFutureSlider}
                marks={housingRubricMarks}
                step={1}
                min={0}
                max={4}
              />
            </Box>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.rubricExplanation} align="left">
          {form.rubricFutureExplain && form.rubricFutureExplain.length > 0 && form.rubricFutureExplain}
        </Grid>
      </Grid>

      <Grid container direction="row">
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.rubricQuestion} align="left">
          3. Student communication with SF team: Student is appropriately communicating (email, call, afterschool hours)
          with SF
        </Grid>
        <Grid item xs={8} sm={6} md={3} lg={4} className={classes.textRowSpacer} align="left">
          <FormControl fullWidth>
            <Box sx={{ width: 300 }}>
              <Slider
                aria-label="sfteam-rubric"
                value={form.rubricSFTeam}
                onChange={handleRubricSFTeamSlider}
                marks={housingRubricMarks}
                step={1}
                min={0}
                max={4}
              />
            </Box>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.rubricExplanation} align="left">
          {form.rubricSFTeamExplain && form.rubricSFTeamExplain.length > 0 && form.rubricSFTeamExplain}
        </Grid>
      </Grid>

      <Grid container direction="row">
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.rubricQuestion} align="left">
          4. Considering all available collateral information and professional judgment, as an SFS, how likely is this
          student to succeed with their
          current post-graduation plan?
        </Grid>
        <Grid item xs={6} sm={5} md={4} lg={3} className={classes.textRowSpacer} align="left">
          <SelectScore
            variant="outlined"
            labelId="likely-success-label"
            id="likely-success"
            value={form.rubricSuccess}
            onChange={(event) => setForm({ ...form, rubricSuccess: event.target.value })}
            disabled={disabled}
            items={SENIOR_PLANNING_2.PROFESSIONAL_JUDGMENT.OPTIONS}
          />
        </Grid>
      </Grid>

      <div style={{ padding: '75px 0 10px 0', width: '100%' }}>
        <Grid container direction="row">
          <Grid item xs={9} sm={8} md={5} lg={3} className={classes.rubricTitle} align="left">
            Motivation Score
          </Grid>
          <Grid item xs={6} sm={5} md={4} lg={3} className={classes.rubricScore} align="left">
            {form.rubricMotivationScore > 0 ? form.rubricMotivationScore.toFixed(2) : 0}
          </Grid>
          <Grid item xs={false} sm={false} md={5} lg={7}></Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={9} sm={8} md={5} lg={3} className={classes.rubricTitle} align="left">
            Likely to Succeed Score
          </Grid>
          <Grid item xs={3} sm={4} md={2} lg={2} className={classes.rubricScore} align="left">
            {form.rubricSuccess}
          </Grid>
          <Grid item xs={false} sm={false} md={5} lg={7}></Grid>
        </Grid>
      </div>
    </>
  );
}

Scoring.propTypes = {
  form: PropTypes.object,
  setForm: PropTypes.func,
  disabled: PropTypes.bool,
};

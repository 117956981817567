import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { callApi } from '../../util/apiWrapper';
import GeneralItems from './ChecklistComponents/GeneralItems';
import PostSecondaryEducationItems from './ChecklistComponents/PostSecondaryEducationItems';
import PostSecondaryCareerItems from './ChecklistComponents/PostSecondaryCareerItems';
import MilitaryItems from './ChecklistComponents/MilitaryItems';
import JobCorpsItems from './ChecklistComponents/JobCorpsItems';
import WorkforceItems from './ChecklistComponents/WorkforceItems';
import { btNavyBlueColor } from '../../assets/jss/nextjs-material-kit-pro';
import { logException, appInsights } from '../../services/appInsights';
import RequiredInfoDialog from '../Shared/RequiredInfoDialog';
import { titleize } from '../../util/stringUtil';
import CloseIcon from '@material-ui/icons/Close';
import ConfirmSubmitDialog from './ChecklistDialogs/ConfirmSubmitDialog';
import { webPrimaryColor, whiteColor, secondaryColor } from '../../assets/jss/nextjs-material-kit-pro.js';
import PleaseWait from '../Shared/PleaseWait';
import useAuthRedirect from '../../hooks/authRedirectHook';

const formStyles = makeStyles((theme) => ({
  columnHeader: {
    height: 40,
    fontSize: '0.90rem',
    fontWeight: 'bold',
  },
  firstSectionSpacer: {
    height: 40,
    fontSize: '0.90rem',
    fontWeight: 'bold',
  },
  columnHeaders: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  sectionTitle: {
    backgroundColor: btNavyBlueColor,
    color: whiteColor,
    textTransform: 'uppercase',
    padding: 8,
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.80rem',
      fontWeight: 600,
      margin: '10px 0',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.90rem',
      fontWeight: 600,
      margin: '20px 0',
    },
  },
  textRowLabelSpacer: {
    [theme.breakpoints.down('md')]: {
      height: 30,
      paddingTop: 5,
      overflow: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      height: 50,
      paddingTop: 8,
      overflow: 'auto',
    },
  },
  buttonWidth: {
    width: 110,
  },
  buttonSpacer: {
    height: 50,
    marginTop: 20,
  },
  printedContent: {
    '@media print': {
      marginBottom: 10,
    },
    '@media screen': {
      display: 'none',
    },
  },
  unprintedContent: {
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  floatingSaveButton: {
    position: 'absolute',
    right: 15,
    bottom: 15,
    margin: '0 15px 15px 0',
    width: '110px',
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  error: {
    fontSize: '1.05rem',
    color: '#FA0000',
    fontWeight: 600,
  },
  floatingScore: {
    position: 'absolute',
    height: '110px',
    width: '110px',
    borderRadius: '50%',
    backgroundColor: secondaryColor,
    fontWeight: 600,
    fontSize: '1.25rem',
    color: whiteColor,
    '@media print': {
      display: 'none',
    },
    '@media screen': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      top: '8.4%',
      left: '80%',
    },
    [theme.breakpoints.down('md')]: {
      top: '6%',
      left: '81%',
    },
    [theme.breakpoints.down('sm')]: {
      top: '4.5%',
      left: '78%',
    },
    [theme.breakpoints.down('xs')]: {
      top: '5.2%',
      left: '62%',
    },
  },
  printedScore: {
    width: '100%',
    fontWeight: 600,
    fontSize: '1.50rem',
    padding: '10px 0 10px 0',
    color: webPrimaryColor,
    '@media screen': {
      display: 'none',
    },
  },
  floatingScoreNumber: {
    padding: '30px 4px 5px 4px',
    width: '100%',
  },
  floatingScoreLabel: {
    padding: '0 4px 22px 4px',
    width: '100%',
  },
  footnote: {
    fontSize: '0.90rem',
    fontWeight: 700,
  },
  labelRowSpacer: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 15,
    },
    [theme.breakpoints.up('xs')]: {
      marginTop: 5,
    },
  },
  textRowSpacer: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 20,
    },
    [theme.breakpoints.up('xs')]: {
      height: 50,
    },
  },
  spacer2520: {
    margin: '25px 0 20px 0',
  },
  dispositionNote: {
    fontSize: '1.05rem',
    padding: '25px 15px',
    marginBottom: 30,
    '@media print': {},
    '@media screen': {
      border: '1px solid blue',
      backgroundColor: '#FFFAF0',
    },
  },
  warning: {
    backgroundColor: '#fdb515',
  },
  note: {
    color: 'blue',
  },
}));

const SeniorChecklist = observer((props) => {
  const store = props.SeniorStore;
  const auth = props.AuthStore;

  const { handleSaveReturn } = props;

  const formClasses = formStyles();

  const [requiredFields, setRequiredFields] = useState(null);
  const [requiredFieldsIntro, setRequiredFieldsIntro] = useState('');
  const [showRequiredFieldsDialog, setShowRequiredFieldsDialog] = useState(false);
  const [originalStatus, setOriginalStatus] = useState('');
  const [warning, setWarning] = useState('');
  const [showWarning, setShowWarning] = useState(false);
  const [continueSubmit, setContinueSubmit] = useState(false);

  const [totalPoints, setTotalPoints] = useState(0);
  const [generalPoints, setGeneralPoints] = useState(0);
  const [secedPoints, setSecedPoints] = useState(0);
  const [secCareerPoints, setSecCareerPoints] = useState(0);
  const [militaryPoints, setMilitaryPoints] = useState(0);
  const [jobcorpsPoints, setJobcorpsPoints] = useState(0);
  const [workforcePoints, setWorkforcePoints] = useState(0);

  const [showConfirmSubmitDialog, setShowConfirmSubmitDialog] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowWarning(false);
  };

  const handleConfirmSubmitDialogClose = () => {
    setShowConfirmSubmitDialog(false);
  };

  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  // prettier-ignore
  const [form, setForm] = useState({
    postGraduationPlan: 'Undecided',
    createSystem: false,
    confirmCreateSystem: false,
    loginToWeb: false,
    confirmLoginToWeb: false,
    obtainCopies: false,
    confirmObtainCopies: false,
    obtainPermit: false,
    confirmObtainPermit: false,
    registerDriversEd: false,
    confirmRegisterDriversEd: false,
    createEmail: false,
    confirmCreateEmail: false,
    volunteerOpportunities: false,
    confirmVolunteerOpportunities: false,
    cleanUpSocialMedia: false,
    confirmCleanUpSocialMedia: false,
    obtainDriversLicense: false,
    confirmObtainDriversLicense: false,
    boysTownScholarship: false,
    confirmBoysTownScholarship: false,
    secedTakeACTOrAccuplacer: false,
    confirmSecedTakeACTOrAccuplacer: false,
    secedVisitSchools: false,
    confirmSecedVisitSchools: false,
    secedFASID: false,
    confirmSecedFASID: false,
    secedCollegeApplications: false,
    confirmSecedCollegeApplications: false,
    secedACTForwarded: false,
    confirmSecedACTForwarded: false,
    secedFAFSASent: false,
    confirmSecedFAFSASent: false,
    secedStudentAidRpt: false,
    confirmSecedStudentAidRpt: false,
    seced2ScholarshipApps: false,
    confirmSeced2ScholarshipApps: false,
    secedExternalScholarships: false,
    confirmSecedExternalScholarships: false,
    secedAcceptanceLetters: false,
    confirmSecedAcceptanceLetters: false,
    secedStudentId: false,
    confirmSecedStudentId: false,
    secedLogInFinancialAid: false,
    confirmSecedLogInFinancialAid: false,
    secedLogInOtherDocs: false,
    confirmSecedLogInOtherDocs: false,
    secedCampusHousing: false,
    confirmSecedCampusHousing: false,
    secedTranscripts: false,
    confirmSecedTranscripts: false,
    secedNoteDates: false,
    confirmSecedNoteDates: false,
    militaryRecruiter: false,
    confirmMilitaryRecruiter: false,
    militaryASVAB: false,
    confirmMilitaryASVAB: false,
    militaryMEPS: false,
    confirmMilitaryMEPS: false,
    militaryContract: false,
    confirmMilitaryContract: false,
    militaryOathCeremony: false,
    confirmMilitaryOathCeremony: false,
    jobcorpsMeeting: false,
    confirmJobcorpsMeeting: false,
    jobcorpsCareerPath: false,
    confirmJobcorpsCareerPath: false,
    jobcorpsVisitSite: false,
    confirmJobcorpsVisitSite: false,
    jobcorpsRecruiter: false,
    confirmJobcorpsRecruiter: false,
    jobcorpsCompleteApplication: false,
    confirmJobcorpsCompleteApplication: false,
    jobcorpsAdmissionDate: false,
    confirmJobcorpsAdmissionDate: false,
    workforceOccupation: false,
    confirmWorkforceOccupation: false,
    workforceHousing: false,
    confirmWorkforceHousing: false,
    workforceResume: false,
    confirmWorkforceResume: false,
    workforceEmployers: false,
    confirmWorkforceEmployers: false,
    workforceReferences: false,
    confirmWorkforceReferences: false,
    workforceJobApplications: false,
    confirmWorkforceJobApplications: false,
    workforceSecureHousing: false,
    confirmWorkforceSecureHousing: false,
    workforceTransportation: false,
    confirmWorkforceTransportation: false,
    workforceFollowupOnApps: false,
    confirmWorkforceFollowupOnApps: false,
    workforceInterviews: false,
    confirmWorkforceInterviews: false,
    workforceFirstDayOfWork: false,
    confirmWorkforceFirstDateOfWork: false,
    status: 'created',
    dispositionReason: '',
    discussTransportationNeeds: false,
    confirmDiscussTransportationNeeds: false,
    completeDriversEd: false,
    confirmCompleteDriversEd: false,
    liveAfterGraduation: false,
    confirmLiveAfterGraduation: false,
    liveAfterGraduationResponse: '',
    requestLettersofRecommendation: false,
    confirmRequestLettersofRecommendation: false,
    secedCompleteFAFSA: false,
    confirmSecedCompleteFAFSA: false,
    secedLocateStudentAidRpt: false,
    confirmSecedLocateStudentAidRpt: false,
    secCareerStartDates: false,
    confirmSecCareerStartDates: false,
    secCareerSubmitApps: false,
    confirmSecCareerSubmitApps: false,
    secCareerSendDeposits: false,
    confirmSecCareerSendDeposits: false,
    secCareerFinancial: false,
    confirmSecCareerFinancial: false,
    secCareerAcceptFAFSASupport: false,
    confirmSecCareerAcceptFAFSASupport: false,
    secCareerFASID: false,
    confirmSecCareerFASID: false,
    secCareerCompleteFAFSA: false,
    confirmSecCareerCompleteFAFSA: false,
    secCareerLocateStudentAidRpt: false,
    confirmSecCareerLocateStudentAidRpt: false,
    secCareerNecessaryInfo: false,
    confirmSecCareerNecessaryInfo: false,
    secCareerExploreHousing: false,
    confirmSecCareerExploreHousing: false,
    secCareerHousingApps: false,
    confirmSecCareerHousingApps: false,
    secCareerIdentifyTransportation: false,
    confirmSecCareerIdentifyTransportation: false,
    secCareerPartTimeJobs: false,
    confirmSecCareerPartTimeJobs: false,
    secCareerTools: false,
    confirmSecCareerTools: false,
    secCareerBudget: false,
    confirmSecCareerBudget: false,
    secCareerPlanDates: false,
    confirmSecCareerPlanDates: false,

  });

  // Redirect user to login page if token is now longer available.
  useAuthRedirect(auth);

  // Load checklist if one exists
  useEffect(() => {
    const fetchChecklist = async (studentId) => {
      try {
        store.setLoading(true);
        store.setSearching(true);

        const checklist = await callApi(auth, 'checklist/' + studentId, 'GET');

        // If checklist fetched, set it to form.
        if (checklist.result) {
          store.setChecklistObj(checklist.result);
          setForm(JSON.parse(checklist.result.checklist_data));
        }
        store.setSearching(false);
        store.setLoading(false);
      } catch (error) {
        store.setSearching(false);
        store.setLoading(false);
        console.warn('Error fetching checklist:', error);
        logException(error);
      }
    };

    fetchChecklist(store.senior.id);
  }, [auth, store]);

  useEffect(() => {
    computeTotalPoints();

    const canSubmit = () => {
      let rc = true;
      let requiredFields = [];

      if (form.postGraduationPlan.length === 0 || form.postGraduationPlan.toLowerCase() === 'undecided') {
        requiredFields = [...requiredFields, { requirement: 'Indicate your post-graduation plans before submitting your checklist.' }];
        rc = false;
      }

      setRequiredFieldsIntro(
        'You have not completed all of the required information on your Checklist. Please make these adjustments before submitting.'
      );
      setRequiredFields(requiredFields);
      return rc;
    };

    if (continueSubmit) {
      if (canSubmit()) {
        async function submitChecklist() {
          try {
            store.setLoading(false);

            let updatedChecklist = null;

            form.status = 'submitted';
            form.dispositionReason = '';

            if (store.checklistObj && store.checklistObj.id && store.checklistObj.id > 0) {
              updatedChecklist = store.checklistObj;
              updatedChecklist.checklist_data = form;
              updatedChecklist.updated_at = new Date();
              updatedChecklist.updated_by = auth && auth.user && auth.user.result.id;
            }

            if (updatedChecklist) {
              updatedChecklist = await callApi(auth, 'checklist', 'PUT', {
                item: updatedChecklist,
              });
            } else {
              const res = await callApi(auth, 'checklist', 'POST', {
                item: {
                  student_id: store.senior.id,
                  created_at: new Date(),
                  created_by: store.senior.userId,
                  updated_at: new Date(),
                  updated_by: store.senior.userId,
                  checklist_data: null,
                },
              });

              if (res.result) updatedChecklist = res.result;

              // PUT to update checklist_data in correct format
              updatedChecklist.checklist_data = form;
              const c = await callApi(auth, 'checklist', 'PUT', {
                item: updatedChecklist,
              });

              if (c.result) {
                updatedChecklist = res.result;
                store.setChecklistObj(updatedChecklist);
              }
            }

            appInsights.trackTrace({ message: `Checklist submitted by ${auth && auth.user && auth.user.result.email}` });

            store.setLoading(false);
            handleSaveReturn();
          } catch (error) {
            logException(error);
            store.setLoading(false);
            console.log('Error submitting updated checklist:', error);
          }
        }
        submitChecklist();
      } else {
        setShowRequiredFieldsDialog(true);
      }
    }
  }, [continueSubmit, auth, store, form, handleSaveReturn]);

  const sendChecklistReturnedEmail = async (id) => {
    try {
      store.setLoading(true);
      await callApi(auth, 'checklistreturnemail', 'POST', { student_id: id });
      appInsights.trackTrace({ message: `Checklist returned email sent to ${auth && auth.user && auth.user.result.email}` });
      store.setLoading(false);
    } catch (error) {
      logException(error);
      store.setLoading(false);
      console.log('Error sending Checklist returned email:', error);
    }
  };

  const handleSaveChecklist = () => {
    // if admin is returning the checklist, require a reason.
    if (auth.roles.includes('ADMIN') || auth.roles.includes('AFTERCARE_SPECIALIST')) {
      if (form.status === 'returned') {
        if (!form.dispositionReason || form.dispositionReason.length === 0) {
          setWarning('You must provide a reason for returning the checklist.');
          setShowWarning(true);
          return;
        }
      }
    }

    async function writeChecklist() {
      try {
        store.setLoading(false);

        // Send email
        if (originalStatus !== 'returned' && form.status === 'returned') {
          sendChecklistReturnedEmail(store.senior.id);
        }

        let updatedChecklist = null;

        if (store.checklistObj && store.checklistObj.id && store.checklistObj.id > 0) {
          updatedChecklist = store.checklistObj;
          updatedChecklist.checklist_data = form;
          updatedChecklist.updated_at = new Date();
          updatedChecklist.updated_by = auth && auth.user && auth.user.result.id;
        }

        if (updatedChecklist) {
          updatedChecklist = await callApi(auth, 'checklist', 'PUT', {
            item: updatedChecklist,
          });
        } else {
          const res = await callApi(auth, 'checklist', 'POST', {
            item: {
              student_id: store.senior.id,
              created_at: new Date(),
              created_by: store.senior.userId,
              updated_at: new Date(),
              updated_by: store.senior.userId,
              checklist_data: null,
            },
          });

          if (res.error) {
            if (res.result) updatedChecklist = res.result;
          }

          // PUT to update checklist_data in correct format
          updatedChecklist.checklist_data = form;
          const c = await callApi(auth, 'checklist', 'PUT', {
            item: updatedChecklist,
          });

          if (c.result) {
            updatedChecklist = res.result;
            store.setChecklistObj(updatedChecklist);
          }
        }

        appInsights.trackTrace({ message: `Checklist saved by ${auth && auth.user && auth.user.result.email}` });

        store.setLoading(false);
        handleSaveReturn();
      } catch (error) {
        logException(error);
        store.setLoading(false);
        console.log('Error writing updated checklist:', error);
      }
    }
    writeChecklist();
  };

  const displayStatus = (status) => {
    if (status === 'qualified' || status === 'qualifiedinitial' || status === 'qualifiedprogram') {
      return 'Under Review';
    }
    return status;
  };

  const handleRequiredInfoClose = () => {
    setShowRequiredFieldsDialog(false);
  };

  const showSaveButton = () => {
    // Status Values: created, submitted, approved, returned
    if (auth.roles.includes('ADMIN') || auth.roles.includes('AFTERCARE_SPECIALIST')) {
      return true;
    }
    if (auth.roles.includes('STUDENT')) {
      if (form.status === 'created' || form.status === 'returned') {
        return true;
      }
    }
    return false;
  };

  const showDisposition = () => {
    let rc = false;
    if (auth.roles.includes('ADMIN') || auth.roles.includes('AFTERCARE_SPECIALIST')) {
      // Status Values: created, submitted, approved, returned
      // PS-355 if (originalStatus === 'submitted') {
      rc = true;
      // PS-355 }
    }
    return rc;
  };

  const isPostGraduationPlanDisabled = () => {
    if (auth.roles.includes('STUDENT')) {
      if (form.status === 'created' || form.status === 'returned') {
        return false;
      }
    }
    return true;
  };

  const shouldDisplayStatusNotes = () => {
    if (form.dispositionReason && form.dispositionReason.length > 0) return true;
    return false;
  };

  const computeConfirmedPointsEarned = () => {
    // if any point totals are changed, be sure to change the section Headers values.
    let rc = 0;
    if (form.confirmCreateSystem) rc += 4;
    if (form.confirmCreateEmail) rc += 4;
    if (form.confirmLoginToWeb) rc += 4;
    if (form.confirmObtainCopies) rc += 5;
    if (form.confirmObtainPermit) rc += 4;
    if (form.confirmRegisterDriversEd) rc += 4;
    if (form.confirmVolunteerOpportunities) rc += 5;
    if (form.confirmCleanUpSocialMedia) rc += 4;
    if (form.confirmCompleteDriversEd) rc += 5;
    if (form.confirmObtainDriversLicense) rc += 5;
    if (form.confirmLiveAfterGraduation) rc += 4;
    if (form.confirmDiscussTransportationNeeds) rc += 4;
    if (form.confirmBoysTownScholarship) rc += 4;
    if (form.confirmRequestLettersofRecommendation) rc += 4;
    if (form.postGraduationPlan && form.postGraduationPlan.length > 0) {
      if (form.postGraduationPlan && form.postGraduationPlan === 'post-secondary education') {
        if (form.confirmSecedTakeACTOrAccuplacer) rc += 3;
        if (form.confirmSecedVisitSchools) rc += 3;
        if (form.confirmSecedFASID) rc += 3;
        if (form.confirmSecedCompleteFAFSA) rc += 2;
        if (form.confirmSecedCollegeApplications) rc += 2;
        if (form.confirmSecedACTForwarded) rc += 2.5;
        if (form.confirmSecedFAFSASent) rc += 2.5;
        if (form.confirmSecedLocateStudentAidRpt) rc += 2;
        if (form.confirmSecedStudentAidRpt) rc += 2;
        if (form.confirmSeced2ScholarshipApps) rc += 2;
        if (form.confirmSecedExternalScholarships) rc += 2;
        if (form.confirmSecedAcceptanceLetters) rc += 2;
        if (form.confirmSecedStudentId) rc += 2;
        if (form.confirmSecedLogInFinancialAid) rc += 2;
        if (form.confirmSecedLogInOtherDocs) rc += 2;
        if (form.confirmSecedCampusHousing) rc += 2;
        if (form.confirmSecedTranscripts) rc += 2;
        if (form.confirmSecedNoteDates) rc += 2;
      }

      if (form.postGraduationPlan && form.postGraduationPlan === 'post-secondary career training') {
        if (form.confirmSecCareerStartDates) rc += 3;
        if (form.confirmSecCareerSubmitApps) rc += 3;
        if (form.confirmSecCareerSendDeposits) rc += 2;
        if (form.confirmSecCareerFinancial) rc += 3;
        if (form.confirmSecCareerAcceptFAFSASupport) rc += 3;
        if (form.confirmSecCareerFASID) rc += 1;
        if (form.confirmSecCareerCompleteFAFSA) rc += 1;
        if (form.confirmSecCareerLocateStudentAidRpt) rc += 1;
        if (form.confirmSecCareerNecessaryInfo) rc += 3;
        if (form.confirmSecCareerExploreHousing) rc += 3;
        if (form.confirmSecCareerHousingApps) rc += 3;
        if (form.confirmSecCareerIdentifyTransportation) rc += 3;
        if (form.confirmSecCareerPartTimeJobs) rc += 2;
        if (form.confirmSecCareerTools) rc += 3;
        if (form.confirmSecCareerBudget) rc += 3;
        if (form.confirmSecCareerPlanDates) rc += 3;
      }

      if (form.postGraduationPlan && form.postGraduationPlan === 'military') {
        if (form.confirmMilitaryRecruiter) rc += 8;
        if (form.confirmMilitaryASVAB) rc += 8;
        if (form.confirmMilitaryMEPS) rc += 8;
        if (form.confirmMilitaryContract) rc += 8;
        if (form.confirmMilitaryOathCeremony) rc += 8;
      }

      if (form.postGraduationPlan && form.postGraduationPlan === 'Job Corps/AmeriCorps') {
        if (form.confirmJobcorpsMeeting) rc += 6;
        if (form.confirmJobcorpsCareerPath) rc += 7;
        if (form.confirmJobcorpsVisitSite) rc += 6;
        if (form.confirmJobcorpsRecruiter) rc += 7;
        if (form.confirmJobcorpsCompleteApplication) rc += 8;
        if (form.confirmJobcorpsAdmissionDate) rc += 6;
      }

      if (form.postGraduationPlan && form.postGraduationPlan === 'workforce') {
        if (form.confirmWorkforceOccupation) rc += 3;
        if (form.confirmWorkforceHousing) rc += 3;
        if (form.confirmWorkforceResume) rc += 3;
        if (form.confirmWorkforceEmployers) rc += 3;
        if (form.confirmWorkforceReferences) rc += 4;
        if (form.confirmWorkforceJobApplications) rc += 4;
        if (form.confirmWorkforceSecureHousing) rc += 4;
        if (form.confirmWorkforceTransportation) rc += 4;
        if (form.confirmWorkforceFollowupOnApps) rc += 4;
        if (form.confirmWorkforceInterviews) rc += 4;
        if (form.confirmWorkforceFirstDateOfWork) rc += 4;
      }
    }
    return rc;
  };

  const computeTotalPoints = () => {
    // if any point totals are updated, be sure to update the section Headers.
    let general = 0;
    let seced = 0;
    let secCareer = 0;
    let military = 0;
    let jobcorps = 0;
    let workforce = 0;
    let grandTotal = 0;

    // General Total
    general += 4;
    general += 4;
    general += 4;
    general += 5;
    general += 4;
    general += 4;
    general += 5;
    general += 4;
    general += 5;
    general += 5;
    general += 4;
    general += 4;
    general += 4;
    general += 4;

    // Sec Ed Total
    seced += 3;
    seced += 3;
    seced += 3;
    seced += 2;
    seced += 2;
    seced += 2.5;
    seced += 2.5;
    seced += 2;
    seced += 2;
    seced += 2;
    seced += 2;
    seced += 2;
    seced += 2;
    seced += 2;
    seced += 2;
    seced += 2;
    seced += 2;
    seced += 2;

    // Sec Career Total
    secCareer += 3;
    secCareer += 3;
    secCareer += 2;
    secCareer += 3;
    secCareer += 3;
    secCareer += 1;
    secCareer += 1;
    secCareer += 1;
    secCareer += 3;
    secCareer += 3;
    secCareer += 3;
    secCareer += 3;
    secCareer += 2;
    secCareer += 3;
    secCareer += 3;
    secCareer += 3;

    // Military Total
    military += 8;
    military += 8;
    military += 8;
    military += 8;
    military += 8;

    // Jobcorps Total
    jobcorps += 6;
    jobcorps += 7;
    jobcorps += 6;
    jobcorps += 7;
    jobcorps += 8;
    jobcorps += 6;

    // Workforce Total
    workforce += 3;
    workforce += 3;
    workforce += 3;
    workforce += 3;
    workforce += 4;
    workforce += 4;
    workforce += 4;
    workforce += 4;
    workforce += 4;
    workforce += 4;
    workforce += 4;

    // Grand Total Points Available
    grandTotal = general;

    if (form.postGraduationPlan && form.postGraduationPlan.length > 0) {
      if (form.postGraduationPlan && form.postGraduationPlan === 'post-secondary education') {
        grandTotal += seced;
      }
      if (form.postGraduationPlan && form.postGraduationPlan === 'post-secondary career training') {
        grandTotal += secCareer;
      }
      if (form.postGraduationPlan && form.postGraduationPlan === 'military') {
        grandTotal += military;
      }
      if (form.postGraduationPlan && form.postGraduationPlan === 'Job Corps/AmeriCorps') {
        grandTotal += jobcorps;
      }

      if (form.postGraduationPlan && form.postGraduationPlan === 'workforce') {
        grandTotal += workforce;
      }
    }

    setGeneralPoints(general);
    setSecedPoints(seced);
    setSecCareerPoints(secCareer);
    setMilitaryPoints(military);
    setJobcorpsPoints(jobcorps);
    setWorkforcePoints(workforce);
    setTotalPoints(grandTotal);
  };

  const isAdminDisabled = (val) => {
    if (store.alumni || auth.roles.includes('STUDENT')) {
      return true;
    }
    return false;
  };

  return (
    <React.Fragment>
      <PleaseWait isLoading={store.loading} />
      <div className={formClasses.unprintedContent + ' ' + formClasses.floatingScore}>
        <div className={formClasses.floatingScoreNumber}>
          {computeConfirmedPointsEarned()}/{totalPoints}
        </div>
        <div className={formClasses.floatingScoreLabel}>points</div>
      </div>
      <div className={formClasses.printedContent + ' ' + formClasses.printedScore}>Score: {computeConfirmedPointsEarned()}/100 points</div>
      {store.senior && (
        <div>
          <Grid container align="left" className={formClasses.unprintedContent}>
            <Grid item xs={12} align="center" className={formClasses.textRowLabelSpacer} style={{ minHeight: 80 }}>
              <aside style={{ fontWeight: 600 }}>Current Status: {form.status && titleize(displayStatus(form.status))}</aside>
              {auth.roles.includes('STUDENT') && form.status === 'returned' && (
                <aside className={formClasses.error}>
                  NOTE: This Checklist was returned for additional information. Please make the necessary changes and re-submit.
                </aside>
              )}
            </Grid>
          </Grid>
          <div className={formClasses.printedContent}>
            <aside style={{ fontWeight: 600, fontSize: '1.50rem', paddingBottom: 10 }} align="center">
              Current Status: {form.status && titleize(displayStatus(form.status))}
            </aside>
            {auth.roles.includes('STUDENT') && form.status === 'returned' && (
              <aside className={formClasses.error}>
                NOTE: This Checklist was returned for additional information. Please make the necessary changes and re-submit.
              </aside>
            )}
          </div>
        </div>
      )}
      {shouldDisplayStatusNotes() && (
        <Grid item xs={12} align="left" className={formClasses.dispositionNote}>
          {form.dispositionReason && form.dispositionReason.length > 0 && (
            <React.Fragment>
              <summary style={{ fontWeight: 600 }}>Notes Regarding Status</summary>
              <summary>{form.dispositionReason}</summary>
            </React.Fragment>
          )}
        </Grid>
      )}
      <Grid container direction="row" align="left" className={formClasses.unprintedContent}>
        <Grid item xs={12} sm={4} md={3} lg={2} className={formClasses.labelRowSpacer}>
          <label>My post-graduation plan is</label>
        </Grid>
        <Grid item xs={11} sm={5} md={4} lg={3} className={formClasses.textRowSpacer}>
          <FormControl fullWidth>
            <Select
              labelId="post-graduation-plan"
              id="post-graduation"
              value={form.postGraduationPlan}
              onChange={(event) => setForm({ ...form, postGraduationPlan: event.target.value })}
              disabled={isPostGraduationPlanDisabled()}
            >
              <MenuItem value={'Job Corps/AmeriCorps'}>Job Corps/AmeriCorps</MenuItem>
              <MenuItem value={'military'}>Military</MenuItem>
              <MenuItem value={'post-secondary education'}>Post-Secondary Education</MenuItem>
              <MenuItem value={'post-secondary career training'}>Post-Secondary Career Training</MenuItem>
              <MenuItem value={'undecided'}>Undecided</MenuItem>
              <MenuItem value={'workforce'}>Workforce</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={false} sm={3} md={5} lg={7} className={formClasses.textRowSpacer}></Grid>
      </Grid>
      <summary className={formClasses.printedContent + ' ' + formClasses.spacer2520}>My post-graduation plan is {form.postGraduationPlan}.</summary>
      <Grid container key={store.senior.userId} align="left">
        <Grid container direction="row" className={formClasses.unprintedContent + ' ' + formClasses.columnHeaders}>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <section align="left" className={formClasses.columnHeader}>
              Activity
            </section>
          </Grid>
          <Grid item xs={3} sm={3} md={1} lg={1}>
            <section align="center" className={formClasses.columnHeader}>
              Points
            </section>
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <section align="left" className={formClasses.columnHeader}>
              Deadline
            </section>
          </Grid>
          <Grid item xs={3} sm={3} md={1} lg={1}>
            <section align="center" className={formClasses.columnHeader}>
              Confirmed
            </section>
          </Grid>
        </Grid>
        <Grid container direction="row" className={formClasses.sectionSpacer}>
          <Grid item xs={7} align="left">
            <section align="left" className={formClasses.sectionTitle}>
              General
            </section>
          </Grid>
          <Grid item xs={2}>
            <section align="left" className={formClasses.sectionTitle}>
              <div style={{ fontSize: '1.15rem', fontWeight: 600, paddingLeft: '15px' }}>{generalPoints}</div>
            </section>
          </Grid>
          <Grid item xs={3}>
            <section align="center" className={formClasses.sectionTitle}>
              <br />
            </section>
          </Grid>
        </Grid>
        {store.senior && store.senior.user && (
          <GeneralItems
            className={formClasses.topMargin10}
            form={form}
            setForm={setForm}
            auth={auth}
            emailAddress={store.senior.user.email}
            alumni={store.alumni}
          />
        )}
        <Grid container direction="row" className={formClasses.sectionSpacer}>
          <Grid item xs={7} align="left">
            <section align="left" className={formClasses.sectionTitle}>
              Post-Secondary Education Option
            </section>
          </Grid>
          <Grid item xs={2}>
            <section align="left" className={formClasses.sectionTitle}>
              <div style={{ fontSize: '1.15rem', fontWeight: 600, paddingLeft: '15px' }}>{secedPoints}</div>
            </section>
          </Grid>
          <Grid item xs={3}>
            <section align="center" className={formClasses.sectionTitle}>
              <br />
            </section>
          </Grid>
        </Grid>
        {store.senior && (
          <PostSecondaryEducationItems
            className={formClasses.topMargin10}
            form={form}
            setForm={setForm}
            auth={auth}
            act={store.senior.act}
            alumni={store.alumni}
          />
        )}
        <Grid container direction="row" className={formClasses.sectionSpacer}>
          <Grid item xs={7} align="left">
            <section align="left" className={formClasses.sectionTitle}>
              Post-Secondary Career Training Option
            </section>
          </Grid>
          <Grid item xs={2}>
            <section align="left" className={formClasses.sectionTitle}>
              <div style={{ fontSize: '1.15rem', fontWeight: 600, paddingLeft: '15px' }}>{secCareerPoints}</div>
            </section>
          </Grid>
          <Grid item xs={3}>
            <section align="center" className={formClasses.sectionTitle}>
              <br />
            </section>
          </Grid>
        </Grid>
        <PostSecondaryCareerItems
          className={formClasses.topMargin10}
          form={form}
          setForm={setForm}
          auth={auth}
          act={store.senior.act}
          alumni={store.alumni}
        />
        <Grid container direction="row" className={formClasses.sectionSpacer}>
          <Grid item xs={7} align="left">
            <section align="left" className={formClasses.sectionTitle}>
              Military Option
            </section>
          </Grid>
          <Grid item xs={2}>
            <section align="left" className={formClasses.sectionTitle}>
              <div style={{ fontSize: '1.15rem', fontWeight: 600, paddingLeft: '15px' }}>{militaryPoints}</div>
            </section>
          </Grid>
          <Grid item xs={3}>
            <section align="center" className={formClasses.sectionTitle}>
              <br />
            </section>
          </Grid>
        </Grid>
        <MilitaryItems
          className={formClasses.topMargin10}
          form={form}
          setForm={setForm}
          auth={auth}
          asvab={store.senior.asvab}
          alumni={store.alumni}
        />
        <Grid container direction="row" className={formClasses.sectionSpacer}>
          <Grid item xs={7} align="left">
            <section align="left" className={formClasses.sectionTitle}>
              Job Corps / AmeriCorps Option
            </section>
          </Grid>
          <Grid item xs={2}>
            <section align="left" className={formClasses.sectionTitle}>
              <div style={{ fontSize: '1.15rem', fontWeight: 600, paddingLeft: '15px' }}>{jobcorpsPoints}</div>
            </section>
          </Grid>
          <Grid item xs={3}>
            <section align="center" className={formClasses.sectionTitle}>
              <br />
            </section>
          </Grid>
        </Grid>
        <JobCorpsItems className={formClasses.topMargin10} form={form} setForm={setForm} auth={auth} alumni={store.alumni} />
        <Grid container direction="row" className={formClasses.sectionSpacer}>
          <Grid item xs={7} align="left">
            <section align="left" className={formClasses.sectionTitle}>
              Workforce Option
            </section>
          </Grid>
          <Grid item xs={2}>
            <section align="left" className={formClasses.sectionTitle}>
              <div style={{ fontSize: '1.15rem', fontWeight: 600, paddingLeft: '15px' }}>{workforcePoints}</div>
            </section>
          </Grid>
          <Grid item xs={3}>
            <section align="center" className={formClasses.sectionTitle}>
              <br />
            </section>
          </Grid>
        </Grid>
        <WorkforceItems className={formClasses.topMargin10} form={form} setForm={setForm} auth={auth} alumni={store.alumni} />
        {showDisposition() && (
          <React.Fragment>
            <Grid
              container
              direction="row"
              className={formClasses.unprintedContent}
              style={{ marginTop: 20, padding: 20, border: '1px solid darkgreen', background: '#dcf5dc' }}
            >
              <Grid item xs={12} align="center">
                <label style={{ fontWeight: 600 }}>Successful Futures Administration Only</label>
              </Grid>
              <Grid item xs={12} sm={5} md={3} lg={2} className={formClasses.textRowLabelSpacer}>
                <label style={{ fontWeight: 600, color: '#000000' }}>Application Status</label>
              </Grid>
              <Grid item xs={12} sm={12} md={9} lg={10} className={formClasses.textArea5RowSpacer}>
                <FormControl fullWidth>
                  <RadioGroup
                    row
                    aria-label="approve checklist"
                    name="approve-checklist"
                    horizontal="true"
                    value={form.status}
                    onChange={(event) => setForm({ ...form, status: event.target.value })}
                    disabled={isAdminDisabled('returned/approved radio group')}
                  >
                    <FormControlLabel
                      value="returned"
                      className={formClasses.marginRight40}
                      control={<Radio color="default" disabled={isAdminDisabled('returned')} />}
                      label={
                        <span className={formClasses.radioLabel} style={{ fontWeight: 500, color: webPrimaryColor }}>
                          Returned
                        </span>
                      }
                    />
                    <FormControlLabel
                      value="approved"
                      className={formClasses.marginRight40}
                      control={<Radio color="default" disabled={isAdminDisabled('approved')} />}
                      label={
                        <span className={formClasses.radioLabel} style={{ fontWeight: 500, color: '#000000' }}>
                          Approved
                        </span>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <React.Fragment>
                <Grid item xs={12} className={formClasses.textRowSpacer}>
                  <FormControl fullWidth>
                    <TextField
                      id="disposition-reason"
                      size="small"
                      fullWidth
                      label={`Enter any notes for status '${form.status && titleize(form.status)}'`}
                      variant="outlined"
                      value={form.dispositionReason}
                      onChange={(event) => setForm({ ...form, dispositionReason: event.target.value })}
                      disabled={isAdminDisabled('status notes')}
                      InputProps={{ maxLength: 500, classes: { input: formClasses.textField } }}
                      className={formClasses.root}
                    />
                  </FormControl>
                </Grid>
                {!store.alumni && (
                  <Grid item xs={12} className={formClasses.textRowSpacer}>
                    <label className={formClasses.note}>
                      {form.status === 'returned'
                        ? 'This reason will be displayed to the student when they view the returned checklist.'
                        : 'This reason will be displayed to the student when they view the checklist.'}
                    </label>
                  </Grid>
                )}
              </React.Fragment>
            </Grid>
          </React.Fragment>
        )}
        {!store.alumni && (
          <Grid container direction="row" className={formClasses.unprintedContent} style={{ width: '95vw' }}>
            <Grid item xs={12} sm={8} md={5} lg={7} className={formClasses.buttonSpacer}>
              {(!form.status || form.status === 'created' || form.status === 'returned') && (
                <Tooltip title="Click to submit your checklist for review." placement="top">
                  <Button
                    className={formClasses.buttonWidth}
                    color="secondary"
                    variant="contained"
                    onClick={(event) => setShowConfirmSubmitDialog(true)}
                    disabled={!auth.roles.includes('STUDENT')}
                    style={{ margin: '0 0 0 10px' }}
                  >
                    Submit
                  </Button>
                </Tooltip>
              )}
            </Grid>
            <Grid item xs={12} sm={4} md={2} lg={1}></Grid>
            <Grid item xs={12} sm={12} md={5} lg={4} align="right" className={formClasses.buttonSpacer + ' ' + formClasses.unprintedContent}>
              <Button
                className={formClasses.buttonWidth}
                variant="contained"
                onClick={(event) => handleSaveReturn()}
                style={{ margin: '0 20px 0 0' }}
              >
                Cancel
              </Button>
              <Button
                className={formClasses.buttonWidth}
                color="primary"
                variant="contained"
                onClick={(event) => handleSaveChecklist()}
                disabled={!store.senior}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
      {/* <div align="right">
        {showSaveButton() && (
          <Button
            className={formClasses.floatingSaveButton}
            color="primary"
            variant="contained"
            onClick={(event) => handleSaveChecklist()}
            disabled={!store.senior}
          >
            Save
          </Button>
        )}
      </div> */}
      <RequiredInfoDialog
        title="Checklist Errors"
        introduction={requiredFieldsIntro}
        content={requiredFields}
        showDialog={showRequiredFieldsDialog}
        handleClose={handleRequiredInfoClose}
      />
      <Snackbar
        ContentProps={{
          classes: {
            root: formClasses.warning,
          },
        }}
        open={showWarning}
        autoHideDuration={6000}
        onClose={handleClose}
        message={warning}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      ></Snackbar>
      <ConfirmSubmitDialog
        setContinueSubmit={setContinueSubmit}
        setShowConfirmSubmitDialog={setShowConfirmSubmitDialog}
        showDialog={showConfirmSubmitDialog}
        handleClose={handleConfirmSubmitDialogClose}
      />
    </React.Fragment>
  );
});

export default SeniorChecklist;

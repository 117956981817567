import React from 'react';
import InputField from '../../Shared/InputField';
import PropTypes from 'prop-types';
import { ABOUT_ME_FIELD_NAMES } from '../../../constants/general';

const AboutMeInputFields = ({ formData, handleChange, disabled = false }) => {
  return (
    <React.Fragment>
      <InputField
        label={ABOUT_ME_FIELD_NAMES.planText}
        name="post_graduation_plan"
        value={formData.post_graduation_plan}
        onChange={handleChange}
        multiple={true}
        maxLength={1000}
        isDisabled={disabled}
        isRequired={true}
      />
      <InputField
        label={ABOUT_ME_FIELD_NAMES.accomplishmentText}
        name="accomplishments"
        value={formData.accomplishments}
        onChange={handleChange}
        multiple={true}
        maxLength={1000}
        isDisabled={disabled}
        isRequired={true}
      />
      <InputField
        label={ABOUT_ME_FIELD_NAMES.activityText}
        name="extracurricular_activities"
        value={formData.extracurricular_activities}
        onChange={handleChange}
        multiple={true}
        maxLength={1000}
        isDisabled={disabled}
        isRequired={true}
      />
    </React.Fragment>
  );
};


AboutMeInputFields.propTypes = {
  formData: PropTypes.shape(
    {
      post_graduation_plan: PropTypes.string,
      accomplishments: PropTypes.string,
      extracurricular_activities: PropTypes.string,
    }).isRequired,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default AboutMeInputFields;
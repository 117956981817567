import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { logException } from '../../services/appInsights';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Checkbox,
  Grid,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  RadioGroup,
  Radio,
  Select,
  TextField,
  Tooltip,
} from '@material-ui/core';
import Button from '../CustomButtons/Button.js';
import { btNavyBlueColor, blackColor, whiteColor } from '../../assets/jss/nextjs-material-kit-pro';
import {
  dateToS,
  dateUtcToS,
  dateDiffYears,
  dateDiffMonths,
  dateToApi,
  dateOnlyFormat,
  getTodaysDate,
  getCurrentDateTime,
  getMonth,
  getYear,
  dateOnlyFormatJS,
  dateTimeToS,
  dateUtcToyyyyMMdd,
} from '../../util/dateUtil';
import PleaseWait from '../Shared/PleaseWait';
import { callApi } from '../../util/apiWrapper';
import { logAudit } from '../../util/auditUtil';
import { appInsights } from '../../services/appInsights';
import { stateLabelValues } from '../../util/stringUtil';
import PhoneInput from '../Shared/PhoneInput';
import useAuthRedirect from '../../hooks/authRedirectHook';

const formStyles = makeStyles((theme) => ({
  whiteBg: {
    background: '#FFF',
  },
  textRowLabelSpacer: {
    [theme.breakpoints.down('md')]: {
      height: 35,
      paddingTop: 5,
    },
    [theme.breakpoints.up('md')]: {
      height: 45,
      paddingTop: 8,
    },
  },
  labelInlineEditSpacer: {
    [theme.breakpoints.down('md')]: {
      height: 40,
      paddingTop: 3,
    },
    [theme.breakpoints.up('md')]: {
      height: 45,
      paddingTop: 5,
    },
  },
  textEditSpacer: {
    [theme.breakpoints.down('md')]: {
      height: 40,
    },
    [theme.breakpoints.up('md')]: {
      height: 45,
    },
  },
  formDates: {
    paddingLeft: -10,
    width: '100%',
  },
  form: {
    paddingLeft: 20,
    paddingTop: 20,
    width: '100%',
  },
  searchIconContainer: {
    position: 'absolute',
    display: 'inline',
  },
  searchIcon: {
    top: 10,
    right: 33,
  },
  dialog: {
    width: 500,
    minHeight: 150,
    backgroundColor: whiteColor,
    color: blackColor,
  },
  dialogHeaderFooter: {
    backgroundColor: whiteColor,
  },
  search: {
    paddingBottom: 10,
  },
  searchName: {
    marginTop: 0,
    marginBottom: 0,
    fontWeight: 'bold',
  },
  searchTitle: {
    marginTop: 0,
    marginBottom: 0,
  },
  photo: {
    '& img': {
      width: 60,
      height: 60,
      borderRadius: '50%',
      objectFit: 'cover',
    },
  },
  button: {
    marginLeft: 20,
    marginBottom: 20,
  },
  searchButton: {
    marginLeft: 20,
  },
  staff: {
    marginTop: 20,
    '& .MuiCheckbox-root': {
      paddingTop: 0,
    },
  },
  actions: {
    '& .MuiDialogActions-spacing': {
      justifyContent: 'space-around',
    },
  },
  rowSpacer: {
    height: 35,
  },
  formSpacer: {
    height: 35,
    marginTop: -10,
  },
  textRowSpacer: {
    height: 50,
  },
  buttonWidth: {
    width: 110,
  },
  buttonSpacer: {
    height: 50,
    marginTop: 20,
  },
  boxTopMargin20: {
    marginTop: 20,
  },
  printedContent: {
    '@media print': {
      marginBottom: 10,
    },
    '@media screen': {
      display: 'none',
    },
  },
  unprintedContent: {
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  alignPrintedContent: {
    lineHeight: '25px',
    verticalAlign: 'top',
  },
  error: {
    fontSize: '1.05rem',
    color: '#FA0000',
    fontWeight: 600,
  },
  valignSelect: {
    marginTop: 5,
  },
  updatedInfo: {
    fontSize: '0.80rem',
    fontWeight: 300,
    color: btNavyBlueColor,
    fontStyle: 'italic',
  },
  radioLabel: {
    color: '#000000',
    fontSize: '1.00rem',
    fontWeight: 300,
    '& svg': {
      width: '1.25rem',
      height: '1.25rem',
    },
  },
  radioRow: {
    width: '100%',
    justifyContent: 'space-between',
  },
  radioOption: {
    marginRight: 40,
  },
  radioLabelSpacing: {
    [theme.breakpoints.down('md')]: {
      height: 30,
      paddingTop: 5,
    },
    [theme.breakpoints.up('md')]: {
      height: 50,
      paddingTop: 8,
    },
  },
}));

const BasicInformation = observer((props) => {
  const store = props.SeniorStore;
  const auth = props.AuthStore;

  const { handleSaveReturn } = props;

  const formClasses = formStyles();

  const [senior, setSenior] = useState(null);
  const [gradDate, setGradDate] = React.useState(null);
  const [gradDateMonth, setGradDateMonth] = React.useState(null);
  const [gradDateYear, setGradDateYear] = React.useState(null);
  const [allowAlumniPlanning, setAllowAlumniPlanning] = React.useState(false);
  const [email, setEmail] = React.useState(null);
  const [active, setActive] = React.useState(0);
  const [gpa, setGpa] = React.useState(null);
  const [act, setAct] = React.useState(null);
  const [asvab, setAsvab] = React.useState(null);
  const [ageAtGraduation, setAgeAtGraduation] = React.useState(0);
  const [placementStatus, setPlacementStatus] = React.useState('');
  const [homeState, setHomeState] = React.useState('');
  const [errorMsg, setErrorMsg] = React.useState('');
  const [checkList, setCheckList] = React.useState(null);
  const [aftercareSpecialist, setAftercareSpecialist] = React.useState('');
  const [alumniPhone1, setAlumniPhone1] = React.useState('');
  const [alumniPhone1Type, setAlumniPhone1Type] = React.useState('');
  const [phone1IsValid, setPhone1IsValid] = useState(true);
  const [alumniPhone2, setAlumniPhone2] = React.useState('');
  const [alumniPhone2Type, setAlumniPhone2Type] = React.useState('');
  const [phone2IsValid, setPhone2IsValid] = useState(true);
  const [alumniPhone3, setAlumniPhone3] = React.useState('');
  const [alumniPhone3Type, setAlumniPhone3Type] = React.useState('');
  const [phone3IsValid, setPhone3IsValid] = useState(true);
  const [alumniAddress, setAlumniAddress] = React.useState('');
  const [alumniAddress2, setAlumniAddress2] = React.useState('');
  const [alumniCity, setAlumniCity] = React.useState('');
  const [alumniState, setAlumniState] = React.useState('');
  const [alumniZip, setAlumniZip] = React.useState('');
  const [alumniCountry, setAlumniCountry] = React.useState('');
  const [alumniUpdatedFirstName, setAlumniUpdatedFirstName] = React.useState('');
  const [alumniUpdatedLastName, setAlumniUpdatedLastName] = React.useState('');
  const [phoneTypes, setPhoneTypes] = React.useState([]);
  const [gradMonthList, setGradMonthList] = React.useState([]);
  const [gradYearList, setGradYearList] = React.useState([]);
  const [updatedByName, setUpdatedByName] = React.useState('');
  const [contactInfoUpdatedBy, setContactInfoUpdatedBy] = React.useState(null);
  const [contactInfoUpdatedTS, setContactInfoUpdatedTS] = React.useState(null);
  const [program, setProgram] = React.useState('');
  const [employer, setEmployer] = React.useState('');
  const [school, setSchool] = React.useState('');
  const [unemployed, setUnemployed] = React.useState('');

  const [contactInfo, setContactInfo] = useState({
    alumniPhone1: '',
    alumniPhone1Type: '',
    alumniPhone2: '',
    alumniPhone2Type: '',
    alumniPhone3: '',
    alumniPhone3Type: '',
    alumniAddress: '',
    alumniAddress2: '',
    alumniCity: '',
    alumniState: '',
    alumniZip: '',
    alumniCountry: '',
    alumniUpdatedFirstName: '',
    alumniUpdatedLastName: '',
    employer: '',
    school: '',
    unemployed: '',
  });

  // Redirect user to login page if token is now longer available.
  useAuthRedirect(auth);

  useEffect(() => {
    store.setLoading(true);
    setPhoneTypes(['Mobile', 'Home', 'Work']);
    if (store.alumni || auth.roles.includes('ALUMNI')) {
      const startYear = parseInt(getYear(getTodaysDate(), 10) - 10);
      const endYear = startYear + 13;
      let nextXyears = [];
      for (let step = startYear; step < endYear; step++) {
        nextXyears.push(step.toString());
      }
      setGradYearList(nextXyears);
    } else {
      const startYear = parseInt(getYear(getTodaysDate(), 10) - 0);
      const endYear = startYear + 4;
      let nextXyears = [];
      for (let step = startYear; step < endYear; step++) {
        nextXyears.push(step.toString());
      }
      setGradYearList(nextXyears);
    }

    const months = new Array(12).fill(0).map((_, i) => {
      return { value: (i + 1).toString(), label: new Date(`${i + 1}/1`).toLocaleDateString(undefined, { month: 'long' }).toString() };
    });
    setGradMonthList(months);
    store.setLoading(false);
  }, [store.alumni, auth.roles]);

  useEffect(() => {
    const fetchAftercareSpecialistList = async () => {
      try {
        if (store.aftercareSpecialistList && store.aftercareSpecialistList.length < 1) {
          store.setLoading(true);
          store.setSearching(true);
          const specialists = await callApi(auth, 'staff', 'GET');
          store.setAftercareSpecialistList(
            specialists.result.filter(
              (s) =>
                s.is_active &&
                (s.position_class === '1347' || s.position_class === '2001' || s.position_class === '2148' || s.position_class === '1534')
            )
          );
          store.setSearching(false);
          store.setLoading(false);
        }
      } catch (error) {
        store.setSearching(false);
        store.setLoading(false);
        console.log('Error fetching aftercare specialist list:', error);
        logException(error);
      }
    };

    const fetchUpdatedByUser = async (userId) => {
      if (userId === 0) return;
      try {
        store.setLoading(true);
        const user = await callApi(auth, 'user/' + userId, 'GET');
        setUpdatedByName(user.result.first_name + ' ' + user.result.last_name);
        store.setLoading(false);
      } catch (error) {
        console.log('Error fetching updatedBy name information:', error);
        logException(error);
        store.setLoading(false);
      }
    };

    fetchAftercareSpecialistList();

    if (store.senior) {
      store.senior.contact_updated_by && fetchUpdatedByUser(store.senior.contact_updated_by);
      setSenior(store.senior);
      setGradDateMonth(parseInt(getMonth(store.senior.graduation_date), 10));
      setGradDateYear(getYear(store.senior.graduation_date));
      //GradDate is set in the useEffect statement.
      setAgeAtGraduation(
        store.senior.graduation_date > /Date(0)/ && dateDiffYears(dateToApi(store.senior.dob), dateToApi(store.senior.graduation_date))
      );
      setAllowAlumniPlanning(store.senior.allow_alumni_planning);
      setGpa(store.senior.gpa);
      setAct(store.senior.act);
      setAsvab(store.senior.asvab);
      setHomeState(store.senior.home_state);
      setPlacementStatus(store.senior.placement_status || '');
      setAftercareSpecialist(store.senior.aftercare_specialist);
      setAlumniPhone1(store.senior.alumni_phone_1 || '');
      setAlumniPhone1Type(store.senior.alumni_phone_1_type || '');
      setAlumniPhone2(store.senior.alumni_phone_2 || '');
      setAlumniPhone2Type(store.senior.alumni_phone_2_type || '');
      setAlumniPhone3(store.senior.alumni_phone_3 || '');
      setAlumniPhone3Type(store.senior.alumni_phone_3_type || '');
      setAlumniAddress(store.senior.alumni_address || '');
      setAlumniAddress2(store.senior.alumni_address2 || '');
      setAlumniCity(store.senior.alumni_city || '');
      setAlumniState(store.senior.alumni_state || '');
      setAlumniZip(store.senior.alumni_zip || '');
      setAlumniCountry(store.senior.alumni_country ? store.senior.alumni_country : 'United States');
      setAlumniUpdatedFirstName(store.senior.alumni_updated_first_name ? store.senior.alumni_updated_first_name : store.senior.first_name);
      setAlumniUpdatedLastName(store.senior.alumni_updated_last_name ? store.senior.alumni_updated_last_name : store.senior.last_name);
      setContactInfoUpdatedTS(store.senior.contact_updated_at ? store.senior.contact_updated_at : null);
      setContactInfoUpdatedBy(store.senior.contact_updated_by ? store.senior.contact_updated_by : null);
      setProgram(store.senior.program);
      setEmployer(store.senior.employer);
      setSchool(store.senior.school);
      setUnemployed(store.senior.unemployed);

      setContactInfo({
        alumniPhone1: store.senior.alumni_phone_1,
        alumniPhone1Type: store.senior.alumni_phone_1_type,
        alumniPhone2: store.senior.alumni_phone_2,
        alumniPhone2Type: store.senior.alumni_phone_2_type,
        alumniPhone3: store.senior.alumni_phone_3,
        alumniPhone3Type: store.senior.alumni_phone_3_type,
        alumniAddress: store.senior.alumni_address,
        alumniAddress2: store.senior.alumni_address2,
        alumniCity: store.senior.alumni_city,
        alumniState: store.senior.alumni_state,
        alumniZip: store.senior.alumni_zip,
        alumniCountry: store.senior.alumni_country,
        alumniUpdatedFirstName: store.senior.alumni_updated_first_name,
        alumniUpdatedLastName: store.senior.alumni_updated_last_name,
        employer: store.senior.employer,
        school: store.senior.school,
        unemployed: store.senior.unemployed,
      });
      if (store.senior.user) {
        fetchChecklist(store.senior.id);
        setEmail(store.senior.user.email || '');
        setActive(store.senior.user.is_active);
      }
    }
  }, [store, auth, store.senior]);

  useEffect(() => {
    if (senior) {
      setAgeAtGraduation(gradDate > /Date(0)/ && dateDiffYears(dateToApi(senior.dob), dateToApi(gradDate)));
    }
  }, [senior, gradDate]);

  useEffect(() => {
    if (gradDateMonth > 0 && gradDateYear > 0) {
      const tempDate = dateOnlyFormatJS(new Date(gradDateYear + '-' + gradDateMonth + '-' + 15));
      setGradDate(tempDate);
    }
  }, [setGradDate, gradYearList, gradDateMonth, gradDateYear]);

  async function fetchChecklist(id) {
    try {
      const checklist = await callApi(auth, 'checklist/' + id, 'GET');
      if (checklist.result) {
        store.setChecklistObj(checklist.result);
        setCheckList(checklist.result);
      } else {
        store.setChecklistObj(null);
        setCheckList(null);
      }
    } catch (error) {
      store.setSearching(false);
      store.setLoading(false);
      console.error('Error fetching checklist:', error);
      logException(error);
    }
  }

  const validateGmail = (email) => {
    const gmailRegex = /([a-zA-Z0-9]+)([\.{1}])?([a-zA-Z0-9]+)\@(?:gmail|GMAIL|test)([\.])(?:com|COM)/i;
    return gmailRegex.test(email);
  };

  const handleUpdateBasicInfo = (updatedSenior) => {
    if (gpa > 5) {
      setErrorMsg('Please enter a valid GPA.');
      return;
    }
    if (act > 36) {
      setErrorMsg('An ACT score cannot exceed 36.');
      return;
    }
    if (asvab > 99) {
      setErrorMsg('An ASVAB score cannot exceed 99.');
      return;
    }
    if (updatedSenior.userId > 0 && validateGmail(email) === false) {
      setErrorMsg('You must enter a valid Gmail address.');
      return;
    }

    if (dateDiffMonths(dateToApi(getTodaysDate()), dateToApi(gradDate)) > 36) {
      setErrorMsg('A graduation date cannot be set more than 3 years in advance.');
      return;
    }

    if (alumniPhone1.length > 0 && alumniPhone1Type.length === 0) {
      auth.roles.includes('ALUMNI')
        ? setErrorMsg('Please select a phone type for your phone number 1.')
        : setErrorMsg('Please select a phone type for your post-graduation phone number 1.');
      return;
    }
    if (alumniPhone1.length > 0 && validatePhoneNumber(alumniPhone1) === false) {
      auth.roles.includes('ALUMNI')
        ? setErrorMsg('Please select a valid phone number for phone type ' + alumniPhone1Type + '.')
        : setErrorMsg('Please select enter a valid post-graduation phone number for phone type ' + alumniPhone1Type + '.');
      return;
    }
    if (alumniPhone1.length === 0 && alumniPhone1Type.length > 0) {
      auth.roles.includes('ALUMNI')
        ? setErrorMsg('Please select enter a phone number for phone type ' + alumniPhone1Type + '.')
        : setErrorMsg('Please select enter a post-graduation phone number for phone type ' + alumniPhone1Type + '.');
      return;
    }
    if (alumniPhone2.length > 0 && alumniPhone2Type.length === 0) {
      auth.roles.includes('ALUMNI')
        ? setErrorMsg('Please select a phone type for your phone number 2.')
        : setErrorMsg('Please select a phone type for your post-graduation phone number 2.');
      return;
    }
    if (alumniPhone2.length > 0 && validatePhoneNumber(alumniPhone2) === false) {
      auth.roles.includes('ALUMNI')
        ? setErrorMsg('Please select a valid phone number for phone type ' + alumniPhone2Type + '.')
        : setErrorMsg('Please select enter a valid post-graduation phone number for phone type ' + alumniPhone2Type + '.');
      return;
    }
    if (alumniPhone2.length === 0 && alumniPhone2Type.length > 0) {
      auth.roles.includes('ALUMNI')
        ? setErrorMsg('Please select enter a phone number for phone type ' + alumniPhone2Type + '.')
        : setErrorMsg('Please select enter a post-graduation phone number for phone type ' + alumniPhone2Type + '.');
      return;
    }
    if (alumniPhone3.length > 0 && alumniPhone3Type.length === 0) {
      auth.roles.includes('ALUMNI')
        ? setErrorMsg('Please select a phone type for your phone number 3.')
        : setErrorMsg('Please select a phone type for your post-graduation phone number 3.');
      return;
    }
    if (alumniPhone3.length > 0 && validatePhoneNumber(alumniPhone3) === false) {
      auth.roles.includes('ALUMNI')
        ? setErrorMsg('Please select a valid phone number for phone type ' + alumniPhone3Type + '.')
        : setErrorMsg('Please select enter a valid post-graduation phone number for phone type ' + alumniPhone3Type + '.');
      return;
    }
    if (alumniPhone3.length === 0 && alumniPhone3Type.length > 0) {
      auth.roles.includes('ALUMNI')
        ? setErrorMsg('Please select enter a phone number for phone type ' + alumniPhone3Type + '.')
        : setErrorMsg('Please select enter a post-graduation phone number for phone type ' + alumniPhone3Type + '.');
      return;
    }

    if (alumniCountry.length > 0 && alumniCountry !== 'USA' && alumniCountry !== 'United States' && alumniCountry !== 'United States of America') {
      // If country other than USA and either address, city, zip completed, require all.
      if (alumniAddress.length > 0 || alumniCity.length > 0 || alumniZip.length > 0) {
        if (alumniAddress.length === 0 || alumniCity.length === 0 || alumniZip.length === 0) {
          auth.roles.includes('ALUMNI')
            ? setErrorMsg('Please enter a complete address, including city, and zip/postal code.')
            : setErrorMsg('Please enter a complete post-graduation address, including city, and zip/postal code.');
          return;
        }
      }
    } else {
      // If either address, city, state zip completed, require all.
      if (alumniAddress.length > 0 || alumniCity.length > 0 || alumniState.trim().length > 0 || alumniZip.length > 0) {
        if (alumniAddress.length === 0 || alumniCity.length === 0 || alumniState.trim().length === 0 || alumniZip.length === 0) {
          auth.roles.includes('ALUMNI')
            ? setErrorMsg('Please enter a complete address, including city, state, and zip code.')
            : setErrorMsg('Please enter a complete post-graduation address, including city, state, and zip code.');
          return;
        }
      }
    }

    // if (employer && employer.length === 0 && unemployed.length === 0) {
    //   auth.roles.includes('ALUMNI') && setErrorMsg("Please enter your employer's name or indicate you are unemployed.");
    //   return;
    // }

    const wasContactInfoUpdated = (original, updated) => {
      if (
        original.alumniPhone1 !== updated.alumni_phone_1 ||
        original.alumniPhone1Type !== updated.alumni_phone_1_type ||
        original.alumniPhone2 !== updated.alumni_phone_2 ||
        original.alumniPhone2Type !== updated.alumni_phone_2_type ||
        original.alumniPhone3 !== updated.alumni_phone_3 ||
        original.alumniPhone3Type !== updated.alumni_phone_3_type ||
        original.alumniAddress !== updated.alumni_address ||
        original.alumniAddress2 !== updated.alumni_address2 ||
        original.alumniCity !== updated.alumni_city ||
        original.alumniState !== updated.alumni_state ||
        original.alumniZip !== updated.alumni_zip ||
        original.alumniCountry !== updated.alumni_country ||
        original.alumniUpdatedFirstName !== updated.alumni_updated_first_name ||
        original.alumniUpdatedLastName !== updated.alumni_updated_last_name ||
        original.employer !== updated.employer ||
        original.school !== updated.school ||
        original.unemployed !== updated.unemployed
      ) {
        return true;
      }
      return false;
    };

    async function writeBasicInformation() {
      let allowEmailChange = true;

      let userRecords = null;

      if (updatedSenior.userId > 0) {
        if (updatedSenior.user.email !== email) {
          store.setLoading(true);
          const fetchEmails = await callApi(auth, `useremail?email=${email}&is_active=1`, 'GET');
          if (fetchEmails.result) userRecords = fetchEmails.result;

          userRecords &&
            userRecords.length > 0 &&
            userRecords.forEach((u, index) => {
              if (u.id === senior.id) {
                allowEmailChange = true;
              } else {
                allowEmailChange = false;
                return;
              }
            });

          if (allowEmailChange) {
            const updatedUser = updatedSenior.user;
            updatedUser.email = email;
            updatedUser.is_active = active;
            await callApi(auth, 'user', 'PUT', {
              item: updatedUser,
            });
            store.setLoading(false);
          } else {
            setErrorMsg(`Another user is already using email address ${email}.`);
            store.setLoading(false);
            return;
          }
        }
      }

      // Update User record (except Email, which is handled above)
      const updatedUser = updatedSenior.user;
      if (updatedUser.is_active !== active) {
        store.setLoading(true);
        updatedUser.is_active = active;
        await callApi(auth, 'user', 'PUT', {
          item: updatedUser,
        });
        appInsights.trackTrace({ message: `User ${updatedUser.id} updated.` });

        const auditObj = {
          audit_ts: new Date(),
          user_id: auth.user.result.id,
          username: auth.user.result.first_name + ' ' + auth.user.result.last_name,
          audit_type: 'is_active',
          action: active,
          value: `${updatedUser.id} is_active set to ${active}`,
        };
        logAudit(auth, auditObj);
        appInsights.trackTrace({ message: `${updatedUser.id} is_active set to ${active}.` });
        store.setLoading(false);
      }

      updatedSenior.graduation_date = dateOnlyFormat(gradDate);
      updatedSenior.gpa = gpa;
      updatedSenior.act = act;
      updatedSenior.asvab = asvab;
      updatedSenior.home_state = homeState;
      updatedSenior.placement_status = placementStatus;
      updatedSenior.aftercare_specialist = aftercareSpecialist;
      updatedSenior.allow_alumni_planning = allowAlumniPlanning;
      updatedSenior.alumni_phone_1 = alumniPhone1;
      updatedSenior.alumni_phone_1_type = alumniPhone1Type;
      updatedSenior.alumni_phone_2 = alumniPhone2;
      updatedSenior.alumni_phone_2_type = alumniPhone2Type;
      updatedSenior.alumni_phone_3 = alumniPhone3;
      updatedSenior.alumni_phone_3_type = alumniPhone3Type;
      updatedSenior.alumni_address = alumniAddress;
      updatedSenior.alumni_address2 = alumniAddress2;
      updatedSenior.alumni_city = alumniCity;
      updatedSenior.alumni_state = alumniState;
      updatedSenior.alumni_zip = alumniZip;
      updatedSenior.alumni_country = alumniCountry;
      updatedSenior.alumni_updated_first_name = alumniUpdatedFirstName;
      updatedSenior.alumni_updated_last_name = alumniUpdatedLastName;
      updatedSenior.employer = employer;
      updatedSenior.school = school;
      updatedSenior.unemployed = unemployed;
      updatedSenior.updated_by = auth.user.result.id;
      updatedSenior.updated_at = getCurrentDateTime();
      updatedSenior.program = program;

      // Contact Update logging //
      let updateContactAtTo = null;
      let updateContactByTo = null;
      if (wasContactInfoUpdated(contactInfo, updatedSenior)) {
        updateContactAtTo = dateTimeToS(getCurrentDateTime());
      } else {
        if (contactInfoUpdatedTS) {
          updateContactAtTo = contactInfoUpdatedTS;
        }
      }
      if (wasContactInfoUpdated(contactInfo, updatedSenior)) {
        updateContactByTo = auth.user.result.id;
      } else {
        if (contactInfoUpdatedBy) {
          updateContactByTo = contactInfoUpdatedBy;
        }
      }
      updatedSenior.contact_updated_at = updateContactAtTo;
      updatedSenior.contact_updated_by = updateContactByTo;
      // Contact Update logging //

      // allows seniors who have not been onboarded to be updated.
      if (updatedSenior.userId === 0) {
        updatedSenior.userId = null;
        updatedSenior.user = null;
      }

      store.setLoading(true);
      const student = await callApi(auth, 'student', 'PUT', {
        item: updatedSenior,
      });

      if (student.result) {
        setSenior(student.result);
        store.setSenior(student.result);
        let allseniors = store.allseniors;
        var index = allseniors.indexOf(senior);
        allseniors[index] = student.result;
        store.setSeniorData(allseniors);
        store.setAllSeniors(allseniors);
      }
      handleSaveReturn();
      store.setShowBasicInfo(false);
      store.setLoading(false);
    }
    writeBasicInformation();
  };

  const isGPADisabled = () => {
    if (auth.roles.includes('STUDENT')) {
      return true;
    } else if (auth.roles.includes('ALUMNI')) {
      return true;
    } else if (senior.site === 'Home Campus') {
      return true;
    }
    return false;
  };

  const showBoysTownName = () => {
    if (store.alumni) {
      if (senior.first_name !== alumniUpdatedFirstName) {
        return true;
      }
      if (senior.last_name !== alumniUpdatedLastName) {
        return true;
      }
    }
    return false;
  };

  const handleEmployer = (val) => {
    setEmployer(val);
    if (val.length > 0) {
      setUnemployed('');
    }
  };

  const handleUnemployed = (val) => {
    setUnemployed(val);
    if (val.length > 0) {
      setEmployer('');
    }
  };

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g;
    const phoneValidated = phone.match(phoneRegex);
    if (phoneValidated && phoneValidated.length > 0) return true;
    return false;
  };

  return (
    <React.Fragment>
      <Grid container className={formClasses.whiteBg} style={{ width: '95vw' }}>
        <Grid item xs={12} align="left" className={formClasses.titlebar}>
          <PleaseWait isLoading={store.loading} />
          <div>
            {senior && (
              <Box sx={{ width: '90%' }} key={senior.id}>
                {!auth.roles.includes('STUDENT') && !auth.roles.includes('ALUMNI') && (
                  <Grid container direction="row" className={formClasses.unprintedContent}>
                    <Grid item xs={12} sm={4} md={3} lg={2} className={formClasses.rowSpacer}>
                      <label>NDB Id</label>
                    </Grid>
                    <Grid item xs={1} sm={false} />
                    <Grid item xs={11} sm={7} md={8} lg={8} className={formClasses.rowSpacer}>
                      {senior.int_id}
                    </Grid>
                  </Grid>
                )}
                <Grid container direction="row" className={formClasses.unprintedContent}>
                  <Grid item xs={12} sm={4} md={3} lg={2} className={showBoysTownName() ? formClasses.labelInlineEditSpacer : formClasses.rowSpacer}>
                    <label>{showBoysTownName() ? 'Current Name' : 'Name'}</label>
                  </Grid>
                  <Grid item xs={1} sm={false} />
                  {store.alumni || auth.roles.includes('ALUMNI') ? (
                    <React.Fragment>
                      <Grid item xs={5} sm={5} md={4} lg={3} className={formClasses.textRowSpacer}>
                        <FormControl fullWidth>
                          <TextField
                            id="updated-first-name"
                            size="small"
                            hiddenLabel
                            placeholder="Updated First Name"
                            variant="outlined"
                            value={alumniUpdatedFirstName ? alumniUpdatedFirstName : ''}
                            onChange={(event) => setAlumniUpdatedFirstName(event.target.value)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={1}></Grid>
                      <Grid item xs={5} sm={5} md={4} lg={3} className={formClasses.textRowSpacer}>
                        <FormControl fullWidth>
                          <TextField
                            id="updated-last-name"
                            size="small"
                            hiddenLabel
                            placeholder="Updated Last Name"
                            variant="outlined"
                            value={alumniUpdatedLastName ? alumniUpdatedLastName : ''}
                            onChange={(event) => setAlumniUpdatedLastName(event.target.value)}
                          />
                        </FormControl>
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <Grid item xs={11} sm={7} md={8} lg={8} className={formClasses.rowSpacer}>
                      {senior.first_name} {senior.last_name}
                    </Grid>
                  )}
                </Grid>
                {!auth.roles.includes('STUDENT') && !auth.roles.includes('ALUMNI') && (
                  <Grid container direction="row" className={formClasses.unprintedContent}>
                    <Grid item xs={12} sm={4} md={3} lg={2} className={formClasses.radioLabelSpacing}>
                      <label>Status</label>
                    </Grid>
                    <Grid item xs={1} sm={false} />
                    <Grid item xs={5} sm={false}>
                      <FormControl fullWidth>
                        <RadioGroup
                          row
                          aria-label="indicate status of user"
                          name="user-status"
                          horizontal="true"
                          value={active}
                          onChange={(event) => setActive(active === true ? false : true)}
                          disabled={senior.user.id === 0 || auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')}
                        >
                          <FormControlLabel
                            value={true}
                            className={formClasses.radioOption}
                            control={
                              <Radio
                                color="default"
                                disabled={senior.user.id === 0 || auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')}
                              />
                            }
                            label={<span className={formClasses.radioLabel}>Active</span>}
                          />
                          <FormControlLabel
                            value={false}
                            className={formClasses.radioOption}
                            control={
                              <Radio
                                color="default"
                                disabled={senior.user.id === 0 || auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')}
                              />
                            }
                            label={<span className={formClasses.radioLabel}>Inactive</span>}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                {showBoysTownName() && (
                  <Grid container direction="row" className={formClasses.unprintedContent}>
                    <Grid item xs={12} sm={4} md={3} lg={2} className={formClasses.rowSpacer}>
                      <label>Name while at Boys Town</label>
                    </Grid>
                    <Grid item xs={1} sm={false} />
                    <Grid item xs={11} sm={7} md={8} lg={8} className={formClasses.rowSpacer}>
                      {senior.first_name} {senior.last_name}
                    </Grid>
                  </Grid>
                )}
                {!auth.roles.includes('ALUMNI') && (
                  <Grid container direction="row" className={formClasses.unprintedContent}>
                    <Grid item xs={12} sm={4} md={3} lg={2} className={formClasses.rowSpacer}>
                      <label>DOB</label>
                    </Grid>
                    <Grid item xs={1} sm={false} />
                    <Grid item xs={11} sm={7} md={8} lg={8} className={formClasses.rowSpacer}>
                      {dateUtcToS(dateUtcToyyyyMMdd(senior.dob))}{' '}
                      {store.alumni &&
                        '(Age: ' + (senior.dob && dateDiffYears(dateToApi(dateUtcToyyyyMMdd(senior.dob)), dateToApi(getTodaysDate()))) + ')'}
                    </Grid>
                  </Grid>
                )}
                {!store.alumni && !auth.roles.includes('ALUMNI') && (
                  <Grid container direction="row" className={formClasses.unprintedContent}>
                    <Grid item xs={12} sm={4} md={3} lg={2} className={formClasses.textEditSpacer}>
                      <label>Program</label>
                    </Grid>
                    <Grid item xs={1} sm={false} />
                    <Grid item xs={7} sm={3} md={2} lg={2} className={formClasses.textEditSpacer}>
                      <FormControl fullWidth>
                        <Select
                          labelId="program-label"
                          id="program"
                          value={program}
                          placeholder="Month"
                          onChange={(event) => setProgram(event.target.value)}
                          disabled={
                            auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI') || senior.departure_date === null || senior.userId === 0
                          }
                        >
                          <MenuItem value="FFS">FFS</MenuItem>
                          <MenuItem value="FHP">FHP</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3} sm={4} md={6} lg={7}></Grid>
                  </Grid>
                )}

                {!store.alumni && !auth.roles.includes('ALUMNI') && (
                  <Grid container direction="row" className={formClasses.unprintedContent}>
                    <Grid item xs={12} sm={4} md={3} lg={2} className={formClasses.rowSpacer}>
                      <label>Date of Admission</label>
                    </Grid>
                    <Grid item xs={1} sm={false} />
                    <Grid item xs={11} sm={7} md={8} lg={8} className={formClasses.rowSpacer}>
                      {dateUtcToS(senior.admission_date)}
                    </Grid>
                  </Grid>
                )}
                {!auth.roles.includes('ALUMNI') && (
                  <Grid container direction="row" className={formClasses.unprintedContent}>
                    <Grid item xs={12} sm={4} md={3} lg={2} className={formClasses.rowSpacer}>
                      <label>Departure Date</label>
                    </Grid>
                    <Grid item xs={1} sm={false} />
                    <Grid item xs={11} sm={7} md={8} lg={5} className={formClasses.rowSpacer}>
                      {senior.departure_date > /Date(0)/ ? dateUtcToS(senior.departure_date) : 'N/A'}
                    </Grid>
                  </Grid>
                )}
                {store.alumni && (
                  <Grid container direction="row" className={formClasses.unprintedContent}>
                    <Grid item xs={12} sm={4} md={3} lg={2} className={formClasses.rowSpacer}>
                      <label>Program</label>
                    </Grid>
                    <Grid item xs={1} sm={false} />
                    <Grid item xs={11} sm={7} md={8} lg={5} className={formClasses.rowSpacer}>
                      Family Home - {senior.site}
                    </Grid>
                  </Grid>
                )}
                {store.alumni && senior.departure_date > /Date(0)/ && (
                  <Grid container direction="row" className={formClasses.unprintedContent}>
                    <Grid item xs={12} sm={4} md={3} lg={2} className={formClasses.rowSpacer}>
                      <label>Length of Stay</label>
                    </Grid>
                    <Grid item xs={1} sm={false} />
                    <Grid item xs={11} sm={7} md={8} lg={5} className={formClasses.rowSpacer}>
                      {dateDiffMonths(dateToApi(dateUtcToyyyyMMdd(senior.admission_date)), dateToApi(senior.departure_date))} months (
                      {dateUtcToS(dateUtcToyyyyMMdd(senior.admission_date))} -{' '}
                      {senior.departure_date > /Date(0)/ ? dateUtcToS(senior.departure_date) : 'N/A'})
                    </Grid>
                  </Grid>
                )}
                {!auth.roles.includes('ALUMNI') && senior.departure_date > /Date(0)/ && (
                  <Grid container direction="row" className={formClasses.unprintedContent}>
                    <Grid item xs={12} sm={4} md={3} lg={2} className={formClasses.rowSpacer}>
                      <label>Departure Reason</label>
                    </Grid>
                    <Grid item xs={1} sm={false} />
                    <Grid item xs={11} sm={7} md={3} lg={2} className={formClasses.rowSpacer}>
                      {senior.departure_reason}
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={3} className={formClasses.formSpacer}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="default"
                            disabled={
                              auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI') || senior.departure_date === null || senior.userId === 0
                            }
                            checked={allowAlumniPlanning ? allowAlumniPlanning : false}
                            onChange={(event) => setAllowAlumniPlanning(!allowAlumniPlanning)}
                          />
                        }
                        label="Allow access to Senior Planning Forms"
                      />
                    </Grid>
                  </Grid>
                )}
                {store.alumni && (
                  <Grid container direction="row" className={formClasses.unprintedContent}>
                    <Grid item xs={12} sm={4} md={3} lg={2} className={formClasses.rowSpacer}>
                      <label>Post-Graduation Plan</label>
                    </Grid>
                    <Grid item xs={1} sm={false} />
                    <Grid item xs={11} sm={7} md={8} lg={5} className={formClasses.rowSpacer}>
                      {store.checklistObj && store.checklistObj.checklist_data
                        ? JSON.parse(store.checklistObj.checklist_data).postGraduationPlan
                        : 'N/A'}
                    </Grid>
                  </Grid>
                )}
                {!auth.roles.includes('ALUMNI') && (
                  <Grid container direction="row" className={formClasses.unprintedContent}>
                    <Grid item xs={12} sm={4} md={3} lg={2} className={formClasses.textEditSpacer}>
                      <label>Graduation Date</label>
                    </Grid>
                    <Grid item xs={1} sm={false} />
                    <Grid item xs={7} sm={3} md={2} lg={2} className={formClasses.textEditSpacer}>
                      <FormControl fullWidth>
                        <Select
                          labelId="graduation-month"
                          id="graduation-month"
                          value={gradDateMonth}
                          placeholder="Month"
                          onChange={(event) => setGradDateMonth(event.target.value)}
                          disabled={
                            auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI') || senior.departure_date === null || senior.userId === 0
                          }
                        >
                          {gradMonthList.slice().map((mon) => {
                            return (
                              <MenuItem value={mon.value} key={mon.value}>
                                {mon.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={7} sm={3} md={2} lg={2} className={formClasses.textEditSpacer}>
                      <FormControl fullWidth>
                        <Select
                          labelId="graduation-year"
                          id="graduation-year"
                          value={gradDateYear}
                          placeholder="Year"
                          onChange={(event) => setGradDateYear(event.target.value)}
                          disabled={
                            auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI') || senior.departure_date === null || senior.userId === 0
                          }
                        >
                          {gradYearList.slice().map((year) => {
                            return (
                              <MenuItem value={year} key={year}>
                                {year}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                {!store.alumni && !auth.roles.includes('ALUMNI') && (
                  <Grid container direction="row" className={formClasses.unprintedContent}>
                    <Grid item xs={12} sm={4} md={3} lg={2} className={formClasses.rowSpacer}>
                      <label>Age at Graduation</label>
                    </Grid>
                    <Grid item xs={1} sm={false} />
                    <Grid item xs={11} sm={7} md={8} lg={8} className={formClasses.rowSpacer}>
                      {ageAtGraduation}
                    </Grid>
                  </Grid>
                )}
                {senior.userId && (
                  <Grid container direction="row" className={formClasses.unprintedContent}>
                    <Grid item xs={12} sm={4} md={3} lg={2} className={formClasses.labelInlineEditSpacer}>
                      <label>Email Address</label>
                    </Grid>
                    <Grid item xs={1} sm={false} />
                    <Grid item xs={11} sm={5} md={4} lg={3} className={formClasses.textEditSpacer}>
                      <FormControl fullWidth>
                        <TextField
                          id="email"
                          size="small"
                          hiddenLabel
                          placeholder="Email Address"
                          variant="outlined"
                          value={email}
                          onChange={(event) => setEmail(event.target.value)}
                          disabled={senior.user.id === 0}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                {!auth.roles.includes('ALUMNI') && (
                  <Grid container direction="row" className={formClasses.unprintedContent}>
                    <Grid item xs={12} sm={4} md={3} lg={2} className={formClasses.labelInlineEditSpacer}>
                      <label>Home State</label>
                    </Grid>
                    <Grid item xs={1} sm={false} />
                    <Grid item xs={11} sm={5} md={4} lg={3} className={formClasses.textEditSpacer}>
                      <FormControl fullWidth>
                        <Select
                          labelId="home-state-label"
                          id="home-state"
                          value={homeState}
                          onChange={(event) => setHomeState(event.target.value)}
                          disabled={store.alumni || auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')}
                        >
                          <MenuItem value=""></MenuItem>
                          {stateLabelValues.map((state) => {
                            return <MenuItem value={state.label}>{state.label}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                {!auth.roles.includes('ALUMNI') && (
                  <Grid container direction="row" className={formClasses.unprintedContent}>
                    <Grid item xs={12} sm={4} md={3} lg={2} className={formClasses.labelInlineEditSpacer}>
                      <label>Placement Status</label>
                    </Grid>
                    <Grid item xs={1} sm={false} />
                    <Grid item xs={11} sm={4} md={3} lg={2} className={formClasses.textEditSpacer}>
                      <FormControl fullWidth>
                        <Select
                          labelId="placement-status-label"
                          id="placement-status"
                          value={placementStatus}
                          onChange={(event) => setPlacementStatus(event.target.value)}
                          disabled={store.alumni || auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')}
                        >
                          <MenuItem value=""></MenuItem>
                          <MenuItem value={'State Ward'}>State Ward</MenuItem>
                          <MenuItem value={'Private'}>Private</MenuItem>
                          <MenuItem value={'Probation'}>Probation</MenuItem>
                          <MenuItem value={'Out of State'}>Out of State</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                {!auth.roles.includes('ALUMNI') && (
                  <Grid container direction="row" className={formClasses.unprintedContent}>
                    <Grid item xs={12} sm={4} md={3} lg={2} className={formClasses.labelInlineEditSpacer}>
                      <label>Successful Futures Specialist</label>
                    </Grid>
                    <Grid item xs={1} sm={false} />
                    <Grid item xs={11} sm={4} md={3} lg={2} className={formClasses.textEditSpacer}>
                      <FormControl fullWidth>
                        <Select
                          labelId="aftercare-specialist-label"
                          id="aftercare-specialist"
                          value={aftercareSpecialist}
                          onChange={(event) => setAftercareSpecialist(event.target.value)}
                          disabled={auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')}
                        >
                          <MenuItem value="0"></MenuItem>
                          {store.aftercareSpecialistList
                            .slice()
                            .sort((a, b) => {
                              return a.user.first_name > b.user.first_name ? 1 : -1;
                            })
                            .map((specialist) => {
                              return (
                                <MenuItem value={specialist.id}>
                                  {specialist.user.first_name} {specialist.user.last_name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                {!store.alumni && !auth.roles.includes('ALUMNI') && (
                  <React.Fragment>
                    <Grid container direction="row" className={formClasses.unprintedContent}>
                      <Grid item xs={12} sm={4} md={3} lg={2} className={formClasses.rowSpacer}>
                        <label>Site</label>
                      </Grid>
                      <Grid item xs={1} sm={false} />
                      <Grid item xs={11} sm={7} md={8} lg={8} className={formClasses.rowSpacer}>
                        {senior.site} {senior.site === 'Home Campus' && senior.community && senior.community.length > 0 && ' - ' + senior.community}
                      </Grid>
                    </Grid>
                    <Grid container direction="row" className={formClasses.unprintedContent}>
                      <Grid item xs={12} sm={4} md={4} lg={3} className={formClasses.rowSpacer}>
                        <label>Address</label>
                      </Grid>
                      <Grid item xs={12} sm={7} md={8} lg={8} className={formClasses.rowSpacer}>
                        {senior.home_address}
                      </Grid>
                    </Grid>
                    <Grid container direction="row" className={formClasses.unprintedContent}>
                      <Grid item xs={12} sm={4} md={4} lg={3} className={formClasses.rowSpacer}>
                        <label>Phone</label>
                      </Grid>
                      <Grid item xs={12} sm={7} md={8} lg={8} className={formClasses.rowSpacer}>
                        {senior.home_phone}
                      </Grid>
                    </Grid>
                    <Grid container direction="row" className={formClasses.unprintedContent}>
                      <Grid item xs={12} sm={4} md={3} lg={2} className={formClasses.rowSpacer}>
                        <label>Family Teachers</label>
                      </Grid>
                      <Grid item xs={1} sm={false} />
                      <Grid item xs={11} sm={7} md={8} lg={8} className={formClasses.rowSpacer}>
                        {senior.family_teaching_couple}
                      </Grid>
                    </Grid>
                    <Grid container direction="row" className={formClasses.unprintedContent}>
                      <Grid item xs={12} sm={4} md={3} lg={2} className={formClasses.rowSpacer}>
                        <label>Consultant</label>
                      </Grid>
                      <Grid item xs={1} sm={false} />
                      <Grid item xs={11} sm={7} md={8} lg={8} className={formClasses.rowSpacer}>
                        {senior.consultant}
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
                {!auth.roles.includes('STUDENT') && !store.alumni && !auth.roles.includes('ALUMNI') && (
                  <Grid container direction="row" className={formClasses.unprintedContent}>
                    <Grid item xs={6} sm={5} md={4} lg={3} className={formClasses.textRowLabelSpacer}>
                      <label>GPA</label>
                    </Grid>
                    <Grid item xs={5} sm={3} md={2} lg={2} className={formClasses.textRowSpacer}>
                      <FormControl fullWidth>
                        <TextField
                          id="gpa"
                          size="small"
                          hiddenLabel
                          placeholder="GPA"
                          variant="outlined"
                          value={gpa > 0 ? gpa : ''}
                          onChange={(event) => setGpa(event.target.value)}
                          disabled={isGPADisabled()}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                {!store.alumni && !auth.roles.includes('ALUMNI') && (
                  <React.Fragment>
                    <Grid item xs={1} md={false} />
                    <Grid container direction="row" className={formClasses.unprintedContent}>
                      <Grid item xs={6} sm={5} md={4} lg={3} className={formClasses.textRowLabelSpacer}>
                        <label>ACT Score</label>
                      </Grid>
                      <Grid item xs={5} sm={3} md={2} lg={2} className={formClasses.textRowSpacer}>
                        <FormControl fullWidth>
                          <TextField
                            id="act"
                            size="small"
                            hiddenLabel
                            placeholder="ACT score"
                            variant="outlined"
                            value={act > 0 ? act : ''}
                            onChange={(event) => setAct(event.target.value)}
                            disabled={!auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" className={formClasses.unprintedContent}>
                      <Grid item xs={6} sm={5} md={4} lg={3} className={formClasses.textRowLabelSpacer}>
                        <label>ASVAB Score</label>
                      </Grid>
                      <Grid item xs={5} sm={3} md={2} lg={2} className={formClasses.textRowSpacer}>
                        <FormControl fullWidth>
                          <TextField
                            id="asvab"
                            size="small"
                            hiddenLabel
                            placeholder="ASVAB Score"
                            variant="outlined"
                            value={asvab > 0 ? asvab : ''}
                            onChange={(event) => setAsvab(event.target.value)}
                            disabled={!auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
                <fieldset style={{ margin: '15px 0 10px 0', width: '95vw' }}>
                  <legend>{store.alumni || auth.roles.includes('ALUMNI') ? 'Contact Information' : 'Post-Graduation Contact Information'}</legend>
                  <Grid container direction="row" className={formClasses.unprintedContent}>
                    <Grid item xs={12} sm={12} md={5} lg={3} className={formClasses.textRowLabelSpacer}>
                      <label>Phone Number(s)</label>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={2} className={formClasses.textRowSpacer}>
                      <FormControl error={!phone1IsValid}>
                        <PhoneInput
                          id="phone"
                          variant="outlined"
                          value={alumniPhone1 || ''}
                          handleChange={(val, isValid) => {
                            setAlumniPhone1(val.replace(/\D/g, ''));
                            setPhone1IsValid(isValid);
                          }}
                        />
                        <FormHelperText id="my-helper-text">{!phone1IsValid && 'Please enter a valid phone number'}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={1} className={formClasses.textRowLabelSpacer} align="right">
                      <label style={{ marginRight: '10px' }}>Type</label>
                    </Grid>
                    <Grid item xs={5} sm={5} md={2} lg={2} className={formClasses.textEditSpacer}>
                      <FormControl fullWidth>
                        <Select
                          labelId="alumni-phone-1-type"
                          id="alumni-phone-1-type"
                          value={alumniPhone1Type}
                          className={formClasses.valignSelect}
                          onChange={(event) => setAlumniPhone1Type(event.target.value)}
                        >
                          <MenuItem value={''}></MenuItem>
                          {phoneTypes.slice().map((type) => {
                            return <MenuItem value={type}>{type}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" className={formClasses.unprintedContent}>
                    <Grid item xs={12} sm={12} md={5} lg={3} className={formClasses.textRowLabelSpacer}></Grid>
                    <Grid item xs={6} sm={6} md={3} lg={2} className={formClasses.textRowSpacer}>
                      <FormControl error={!phone1IsValid}>
                        <PhoneInput
                          id="phone"
                          variant="outlined"
                          value={alumniPhone2 || ''}
                          handleChange={(val, isValid) => {
                            setAlumniPhone2(val.replace(/\D/g, ''));
                            setPhone2IsValid(isValid);
                          }}
                        />
                        <FormHelperText id="my-helper-text">{!phone2IsValid && 'Please enter a valid phone number'}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={1} className={formClasses.textRowLabelSpacer} align="right">
                      <label style={{ marginRight: '10px' }}>Type</label>
                    </Grid>
                    <Grid item xs={5} sm={5} md={2} lg={2} className={formClasses.textEditSpacer}>
                      <FormControl fullWidth>
                        <Select
                          labelId="alumni-phone-2-type"
                          id="alumni-phone-2-type"
                          value={alumniPhone2Type}
                          className={formClasses.valignSelect}
                          onChange={(event) => setAlumniPhone2Type(event.target.value)}
                        >
                          <MenuItem value={''}></MenuItem>
                          {phoneTypes.slice().map((type) => {
                            return <MenuItem value={type}>{type}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" className={formClasses.unprintedContent}>
                    <Grid item xs={12} sm={12} md={5} lg={3} className={formClasses.textRowLabelSpacer}></Grid>
                    <Grid item xs={6} sm={6} md={3} lg={2} className={formClasses.textRowSpacer}>
                      <FormControl error={!phone1IsValid}>
                        <PhoneInput
                          id="phone"
                          variant="outlined"
                          value={alumniPhone3 || ''}
                          handleChange={(val, isValid) => {
                            setAlumniPhone3(val.replace(/\D/g, ''));
                            setPhone3IsValid(isValid);
                          }}
                        />
                        <FormHelperText id="my-helper-text">{!phone3IsValid && 'Please enter a valid phone number'}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={1} className={formClasses.textRowLabelSpacer} align="right">
                      <label style={{ marginRight: '10px' }}>Type</label>
                    </Grid>
                    <Grid item xs={5} sm={5} md={2} lg={2} className={formClasses.textEditSpacer}>
                      <FormControl fullWidth>
                        <Select
                          labelId="alumni-phone-3-type"
                          id="alumni-phone-3-type"
                          value={alumniPhone3Type}
                          className={formClasses.valignSelect}
                          onChange={(event) => setAlumniPhone3Type(event.target.value)}
                        >
                          <MenuItem value={''}></MenuItem>
                          {phoneTypes.slice().map((type) => {
                            return <MenuItem value={type}>{type}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container direction="row" className={formClasses.unprintedContent}>
                    <Grid item xs={12} sm={5} md={4} lg={3} className={formClasses.textRowLabelSpacer}>
                      <label>Address</label>
                    </Grid>
                    <Grid item xs={12} sm={6} md={5} lg={5} className={formClasses.textRowSpacer}>
                      <FormControl fullWidth>
                        <TextField
                          id="alumni-address"
                          size="small"
                          hiddenLabel
                          placeholder="Address"
                          variant="outlined"
                          value={alumniAddress ? alumniAddress : ''}
                          onChange={(event) => setAlumniAddress(event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" className={formClasses.unprintedContent}>
                    <Grid item xs={12} sm={5} md={4} lg={3} className={formClasses.textRowLabelSpacer}></Grid>
                    <Grid item xs={12} sm={6} md={5} lg={5} className={formClasses.textRowSpacer}>
                      <FormControl fullWidth>
                        <TextField
                          id="alumni-address2"
                          size="small"
                          hiddenLabel
                          placeholder="Address Line 2"
                          variant="outlined"
                          value={alumniAddress2 ? alumniAddress2 : ''}
                          onChange={(event) => setAlumniAddress2(event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" className={formClasses.unprintedContent}>
                    <Grid item xs={12} sm={5} md={4} lg={3} className={formClasses.textRowLabelSpacer}></Grid>
                    <Grid item xs={12} sm={5} md={3} lg={3} className={formClasses.textRowSpacer}>
                      <FormControl fullWidth>
                        <TextField
                          id="alumni-city"
                          size="small"
                          hiddenLabel
                          placeholder="City"
                          variant="outlined"
                          value={alumniCity ? alumniCity : ''}
                          onChange={(event) => setAlumniCity(event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={1} className={formClasses.textRowLabelSpacer} align="right">
                      <label style={{ marginRight: '10px' }}>State</label>
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={2} className={formClasses.textRowSpacer}>
                      <FormControl fullWidth>
                        <Select
                          id="alumni-state"
                          value={alumniState}
                          className={formClasses.valignSelect}
                          onChange={(event) => setAlumniState(event.target.value)}
                        >
                          <MenuItem value="  "></MenuItem>
                          {stateLabelValues.map((state) => {
                            return <MenuItem value={state.value}>{state.label}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={1} className={formClasses.textRowLabelSpacer} align="right">
                      <label style={{ marginRight: '10px' }}>Zip</label>
                    </Grid>
                    <Grid item xs={4} sm={3} md={1} lg={1} className={formClasses.textRowSpacer}>
                      <FormControl fullWidth>
                        <TextField
                          id="alumni-zip"
                          size="small"
                          hiddenLabel
                          placeholder="Zip Code"
                          variant="outlined"
                          value={alumniZip ? alumniZip : ''}
                          onChange={(event) => setAlumniZip(event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  {(store.alumni || auth.roles.includes('ALUMNI')) && (
                    <>
                      <Grid container direction="row" className={formClasses.unprintedContent}>
                        <Grid item xs={12} sm={5} md={4} lg={3} className={formClasses.textRowLabelSpacer}>
                          <label>Country</label>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={3} className={formClasses.textRowSpacer}>
                          <FormControl fullWidth>
                            <TextField
                              id="alumni-country"
                              size="small"
                              hiddenLabel
                              placeholder="Country"
                              variant="outlined"
                              value={alumniCountry ? alumniCountry : ''}
                              onChange={(event) => setAlumniCountry(event.target.value)}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <br />
                      <Grid container direction="row" className={formClasses.unprintedContent}>
                        <Grid item xs={12} sm={5} md={4} lg={3} className={formClasses.textRowLabelSpacer}>
                          <label>School</label>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={3} className={formClasses.textRowSpacer}>
                          <FormControl fullWidth>
                            <TextField
                              id="school-name"
                              size="small"
                              placeholder="Name of School"
                              variant="outlined"
                              value={school}
                              onChange={(event) => setSchool(event.target.value)}
                              InputProps={{ maxLength: 100 }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" className={formClasses.unprintedContent}>
                        <Grid item xs={12} sm={5} md={4} lg={3} className={formClasses.textRowLabelSpacer}>
                          <label>Employer</label>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={3} className={formClasses.textRowSpacer}>
                          <FormControl fullWidth>
                            <TextField
                              id="employer-name"
                              size="small"
                              placeholder="Name of Employer"
                              variant="outlined"
                              value={employer}
                              onChange={(event) => handleEmployer(event.target.value)}
                              InputProps={{ maxLength: 100 }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={5} md={4} lg={6} className={formClasses.textRowLabelSpacer} style={{ marginTop: -7, paddingLeft: 15 }}>
                          <FormControl fullWidth>
                            <RadioGroup
                              row
                              name="unemployed"
                              value={unemployed}
                              onChange={(event) => handleUnemployed(event.target.value)}
                              disabled={employer && employer.length > 0}
                            >
                              <FormControlLabel
                                value="lookingforwork"
                                control={<Radio color="default" disabled={employer && employer.length > 0} />}
                                label={
                                  <span className={formClasses.radioOption} style={{ marginRight: 10 }}>
                                    Unemployed, looking for work
                                  </span>
                                }
                              />
                              <FormControlLabel
                                value="notlookingforwork"
                                control={<Radio color="default" disabled={employer && employer.length > 0} />}
                                label={<span className={formClasses.radioOption}>Unemployed, NOT looking for work</span>}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </fieldset>
                {store.alumni && (
                  <Grid container direction="row">
                    <Grid item xs={12} className={formClasses.rowSpacer}>
                      <label className={formClasses.updatedInfo}>
                        {senior.contact_updated_by > 0
                          ? 'Last Updated ' + dateToS(senior.contact_updated_at) + (updatedByName ? ' by ' + updatedByName : '')
                          : ''}
                      </label>
                    </Grid>
                  </Grid>
                )}
                <Grid container direction="row">
                  <Grid item xs={false} sm={5} md={4} lg={3} className={formClasses.textRowLabelSpacer} />
                  <Grid item xs={12} sm={7} md={8} lg={9} className={formClasses.textRowSpacer}>
                    {errorMsg && errorMsg.length > 0 && <summary className={formClasses.error}>{errorMsg}</summary>}
                  </Grid>
                </Grid>
                <Grid container direction="row" className={formClasses.unprintedContent} style={{ width: '95vw' }}>
                  <Grid item xs={12} align="right" className={formClasses.buttonSpacer}>
                    <Button
                      className={formClasses.buttonWidth}
                      variant="contained"
                      onClick={(event) => handleSaveReturn()}
                      style={{ margin: '0 15px 0 0' }}
                    >
                      Cancel
                    </Button>
                    <Tooltip title={!auth.roles.includes('STUDENT') ? 'Only students who have an email address can be updated.' : ''} placement="top">
                      <span>
                        <Button
                          className={formClasses.buttonWidth}
                          color="primary"
                          variant="contained"
                          onClick={(event) => handleUpdateBasicInfo(senior)}
                          style={{ margin: '0 0 0 15px' }}
                        >
                          Save
                        </Button>
                      </span>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Box>
            )}
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
});

BasicInformation.propTypes = {
  handleSaveReturn: PropTypes.func,
};

export default BasicInformation;

import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { msalConfig } from '../services/msal';

const useAuthRedirect = (auth) => {
  const { instance } = useMsal();

  useEffect(() => {
    const interval = setInterval(() => {
      if (!auth.authToken) {
        instance.loginRedirect({ ...msalConfig.loginRequest, redirectStartPage: '/' });
        clearInterval(interval);
        console.log('>>>>> Token does not exist. Redirecting user to login page.');
      } else if (auth.isTokenExpired()) {
        instance.loginRedirect({ ...msalConfig.loginRequest, redirectStartPage: '/' });
        clearInterval(interval);
        console.log('>>>>> Token has expired. Redirecting user to login page.');
      }
    }, 6000);
    return () => clearInterval(interval); // clear the interval on unload to prevent memory leaks
  }, [auth, instance]);
};

export default useAuthRedirect;

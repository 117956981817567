import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import GeneralInformation from './RecommendationComponents/GeneralInformation';
import { callApi } from '../../util/apiWrapper';
import RequiredInfoDialog from '../Shared/RequiredInfoDialog';
import { logException, appInsights } from '../../services/appInsights';
import { dateToS, dateDiffDays } from '../../util/dateUtil';
import { isNonEmpty } from '../../util/stringUtil';
import SectionHeader from './RecommendationComponents/SectionHeader';
import useAuthRedirect from '../../hooks/authRedirectHook';

const formStyles = makeStyles((theme) => ({
  subTitle: {
    margin: '15px 0',
    fontSize: '1.05rem',
    fontWeight: 500,
  },
  rowSpacer: {
    height: 30,
  },
  textRowSpacer: {
    [theme.breakpoints.up('sm')]: {
      height: 50,
    },
  },
  error: {
    fontSize: '1.05rem',
    color: '#FA0000',
    fontWeight: 600,
  },
  sectionStyle: {
    fontFamily: 'sans-serif',
    fontSize: '1.438rem',
    fontWeight: '600',
    lineHeight: '40px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
}));

const RequestRecommendation = inject(
  'SeniorStore',
  'AuthStore'
)(
  observer((props) => {
    const store = props.SeniorStore;
    const auth = props.AuthStore;

    const senior = store?.senior;

    const isNotEmpty =
      isNonEmpty(senior?.post_graduation_plan) && isNonEmpty(senior?.accomplishments) && isNonEmpty(senior?.extracurricular_activities);

    const { handleSaveReturn } = props;

    const formClasses = formStyles();

    const [status, setStatus] = useState('created');

    // prettier-ignore
    const [form, setForm] = useState({
      created_at: null,
      email: '',
      expires_utc: null,
      first_name: '',
      last_date_requested: null,
      last_name: '',
      recommendation_id: 0,
      salutation: '',
      scholarship_app_created: null,
      scholarship_app_id: 0,
      status: 'created',
      student_first_name: '',
      student_id: 0,
      student_last_name: '',
      token: '',
      updated_at: null,
    });

    // prettier-ignore
    const [formExtra, setFormExtra] = useState({
      relationship: '',
      scholarships: false,
      tradeLife: false,
      housing: false,
    });

    const [requiredFields, setRequiredFields] = useState(null);
    const [requiredFieldsIntro, setRequiredFieldsIntro] = useState('');
    const [showRequiredFieldsDialog, setShowRequiredFieldsDialog] = useState(false);

    useEffect(() => {
      if (store.newrecommendation) {
        store.setRecommendation(null);
        store.setRecommendationId(0);
        setForm({
          created_at: null,
          email: '',
          expires_utc: null,
          first_name: '',
          last_date_requested: null,
          last_name: '',
          recommendation_id: 0,
          salutation: '',
          scholarship_app_created: null,
          scholarship_app_id: 0,
          status: 'created',
          student_first_name: '',
          student_id: 0,
          student_last_name: '',
          token: '',
          updated_at: null,
        });
      }
    }, [store, store.newrecommendation]);

    // Redirect user to login page if token is now longer available.
    useAuthRedirect(auth);

    useEffect(() => {
      if (store.recommendation && store.recommendation.recommendation_id > 0) {
        setStatus(store.recommendation.status);

        const tempForm = JSON.parse(JSON.stringify(store.recommendation) || null);
        setForm((f) => (f = tempForm));

        const extra = JSON.parse(store.recommendation.recommendation_json || null);
        setFormExtra((r) => (r = extra));
      }
    }, [store.recommendation]);

    const handleSendInvitation = () => {
      if (canSendInvite()) {
        async function sendInvitation() {
          try {
            store.setLoading(false);
            store.setSearching(true);

            const request = {
              salutation: form.salutation,
              first_name: form.first_name,
              last_name: form.last_name,
              email: form.email,
              rec_json: JSON.stringify(formExtra),
            };

            const app = await callApi(auth, 'recommendation/request', 'POST', request);

            if (app.result) {
              store.setRecommendation(app.result);
            }

            appInsights.trackTrace({ message: `Letter of Recommendation invitation submitted by ${auth.user.result.email}` });

            store.setSearching(false);
            store.setLoading(false);

            handleSaveReturn();
          } catch (error) {
            logException(error);
            store.setSearching(false);
            store.setLoading(false);
            console.error('Error submitting letter of recommendation invitation:', error);
          }
        }
        sendInvitation();
        store.setNewRecommendation(false);
      } else {
        setShowRequiredFieldsDialog(true);
      }
    };

    const handleResendInvitation = () => {
      if (canSendInvite()) {
        async function resendInvitation() {
          try {
            store.setLoading(false);
            store.setSearching(true);

            const request = {
              rec_id: form.recommendation_id,
            };

            const app = await callApi(auth, 'recommendation/request', 'PUT', request);

            if (app.result) {
              store.setRecommendation(app.result);
            }

            appInsights.trackTrace({ message: `Letter of Recommendation invitation submitted by ${auth.user.result.email}` });

            store.setSearching(false);
            store.setLoading(false);

            handleSaveReturn();
          } catch (error) {
            logException(error);
            store.setSearching(false);
            store.setLoading(false);
            console.error('Error resubmitting letter of recommendation invitation:', error);
          }
        }
        resendInvitation();
      } else {
        setShowRequiredFieldsDialog(true);
      }
    };

    const closeInvitation = () => {
      store.setNewRecommendation(false);
      handleSaveReturn();
    };

    const isEditDisabled = () => {
      return status === 'Requested' || status === 'Re-Requested' || status === 'Expired' || status === 'Complete';
    };

    const handleRequiredInfoClose = () => {
      setShowRequiredFieldsDialog(false);
    };

    const showSendButton = () => {
      if (auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')) {
        if (status === 'created') {
          return true;
        }
      }
      return false;
    };

    const showResendButton = (date) => {
      if (auth.roles.includes('STUDENT') || auth.roles.includes('ALUMNI')) {
        if (status === 'Complete') {
          return false;
        } else if (dateDiffDays(dateToS(date)) < 0) {
          return true;
        }
        return false;
      }
      return false;
    };

    const canSendInvite = () => {
      let rc = true;
      let requiredFields = [];

      if (!isNotEmpty) {
        requiredFields = [{ requirement: 'Please complete the About Me section.' }];
        rc = false;
      }

      if (!form.first_name || form.first_name.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please enter the first name of the invitee.' }];
        rc = false;
      }

      if (!form.last_name || form.last_name.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please enter the last name of the invitee.' }];
        rc = false;
      }

      if (!form.email || form.email.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please enter the email address for the invitee.' }];
        rc = false;
      }

      if (!formExtra.scholarships && !formExtra.tradeLife && !formExtra.housing) {
        requiredFields = [...requiredFields, { requirement: 'Please indicate the program(s) for which you are applying.' }];
        rc = false;
      }

      if (!formExtra.relationship || formExtra.relationship.length === 0) {
        requiredFields = [...requiredFields, { requirement: 'Please enter the relation of the invitee.' }];
        rc = false;
      }

      setRequiredFieldsIntro(
        'You have not completed all of the required information on the Letter of Recommendation request form. Please make these adjustments before sending the invitation.'
      );
      setRequiredFields(requiredFields);
      return rc;
    };

    return (
      <div style={{ height: '50vh' }}>
        <Box mt={4}>
          <SectionHeader title="Recipient Information" />
          {store.senior && (
            <Grid container key={store.senior.userId} align="left">
              <Grid item xs={12} sm={12} md={12} lg={12} className={formClasses.textRowSpacer}>
                <GeneralInformation
                  senior={store.senior}
                  form={form}
                  setForm={setForm}
                  formExtra={formExtra}
                  setFormExtra={setFormExtra}
                  disabled={isEditDisabled()}
                  roles={auth.roles}
                  showSendButton={showSendButton}
                  handleSendInvitation={handleSendInvitation}
                  showResendButton={showResendButton}
                  handleResendInvitation={handleResendInvitation}
                  closeInvitation={closeInvitation}
                />
              </Grid>
            </Grid>
          )}
        </Box>
        <RequiredInfoDialog
          title="Letter of Recommendation Request Errors"
          introduction={requiredFieldsIntro}
          content={requiredFields}
          showDialog={showRequiredFieldsDialog}
          handleClose={handleRequiredInfoClose}
        />
      </div>
    );
  })
);

RequestRecommendation.propTypes = {
  handleSaveReturn: PropTypes.func,
};

export default RequestRecommendation;

export const ABOUT_ME_FIELD_NAMES = {
  planText: 'I would like you to know that my plan after graduation is...',
  accomplishmentText: 'Some accomplishments I would like you to be aware of since coming to Boys Town include...',
  activityText: 'These are the activities that I have been involved in while at Boys Town....',
};

const SELECT_OPTION = [
  { value: -1, text: 'Select', disabled: true },
];

const SHEET_2_COMPLETED_OPTIONS = [
  ...SELECT_OPTION,
  { value: 8, text: 'Sheet was thoughtfully completed by student' },
  { value: 4, text: 'Sheet was completed by student but requires more work' },
  { value: 2, text: 'Sheet completion needs significant improvement' },
  { value: 0, text: 'Sheet was not completed' },
];

const SHEET_2_SUBMITTED_OPTIONS = [
  ...SELECT_OPTION,
  { value: 8, text: 'Sheet was submitted on time' },
  { value: 4, text: 'Sheet was submitted late (within 24 hours of the initial meeting)' },
  { value: 2, text: 'Sheet was submitted significantly late (between 2 to 3 days after the initial meeting)' },
  { value: 0, text: 'Sheet was not submitted (4 days late or not submitted)' },
];

const SHEET_2_SPEAK_OPTIONS = [
  ...SELECT_OPTION,
  { value: 9, text: 'Student can speak clearly to specifics regarding housing, school, work, or military/job corp' },
  { value: 6, text: 'Student was hesitant and did not speak to specifics of their plan, but accepted feedback well' },
  { value: 3, text: 'Student was hesitant and unsure of plan and did not accept feedback well' },
  { value: 0, text: 'Student could not speak about her/his post-graduation plan' },
];

const MODULE_4_SUBMITTED_OPTIONS = [...SELECT_OPTION,
  { value: 10, text: 'Module was submitted on time' },
  { value: 6, text: 'Module was submitted late (within 24 hours after initial meeting)' },
  { value: 3, text: 'Module was submitted significantly late (between 2 to 3 days after initial meeting)' },
  { value: 0, text: 'Module was 4 or more days late or not submitted' },
];

const MODULE_4_COMPLETED_OPTIONS = [...SELECT_OPTION,
  {
    value: 15,
    text: 'Module was thoughtfully completed by student (all 9 activities completely filled out with complete sentences, legible, and relevant answers)',
  },
  {
    value: 10,
    text: 'Module was completed by student but requires more work (7-8 activities completely filled out with complete sentences, legible, and relevant answers)',
  },
  {
    value: 5,
    text: 'Module completion needs significant improvement (6-4 activities completely filled out with needing work on sentence completion, and relevant answers)',
  },
  { value: 0, text: 'Module was not completed (3 or less activities completed or not turned in at all)' },
];

const MODULE_5_SUBMITTED_OPTIONS = [...SELECT_OPTION,
  { value: 10, text: 'Module was submitted on time' },
  { value: 6, text: 'Module was submitted late (within 24 hours after initial meeting)' },
  { value: 3, text: 'Module was submitted significantly late (between 2 to 3 days after initial meeting)' },
  { value: 0, text: 'Module was 4 or more days late or not submitted' },
];

const MODULE_5_COMPLETED_OPTIONS = [...SELECT_OPTION,
  {
    value: 15,
    text: 'Module was thoughtfully completed by student (all 4 activities completely filled out with complete sentences, legible, and relevant answers)',
  },
  {
    value: 10,
    text: 'Module was completed by student but requires more work (all 4 activities completely filled out with needing work on sentence completion, and relevant answers)',
  },
  { value: 5, text: 'Module completion needs significant improvement (only 3 activities completely filled out)' },
  { value: 0, text: 'Module was not completed (2 or less activities completed or not turned in at all)' },
];

const MODULE_6_SUBMITTED_OPTIONS = [...SELECT_OPTION,
  { value: 10, text: 'Module was submitted on time' },
  { value: 6, text: 'Module was submitted late (within 24 hours after initial meeting)' },
  { value: 3, text: 'Module was submitted significantly late (between 2 to 3 days after initial meeting)' },
  { value: 0, text: 'Module was 4 or more days late or not submitted' },
];

const MODULE_6_COMPLETED_OPTIONS = [...SELECT_OPTION,
  {
    value: 15,
    text: 'Module was thoughtfully completed by student (all 5 activities completely filled out with complete sentences, legible, and relevant answers)',
  },
  {
    value: 10,
    text: 'Module was completed by student but requires more work (all 5 activities completely filled out with needing work on sentence completion, and relevant answers)',
  },
  { value: 5, text: 'Module completion needs significant improvement (only 3 to 4 activities completely filled out)' },
  { value: 0, text: 'Module was not completed (2 or less activities completed or not turned in at all)' },
];

const PROFESSIONAL_JUDGMENT_OPTIONS = [
  { value: 0, text: 'Select Likelihood for Success', disabled: true },
  { value: 1, text: 'Highly unlikely' },
  { value: 2, text: 'Unlikely' },
  { value: 3, text: 'Likely' },
  { value: 4, text: 'Highly likely' },
];

export const SENIOR_PLANNING_2 = {
  SHEET_2: {
    COMPLETED: {
      OPTIONS: [...SHEET_2_COMPLETED_OPTIONS],
    },
    SUBMITTED: {
      OPTIONS: [...SHEET_2_SUBMITTED_OPTIONS],
    },
    SPEAK: {
      OPTIONS: [...SHEET_2_SPEAK_OPTIONS],
    },
  },
  MODULE_4: {
    SUBMITTED: {
      OPTIONS: [...MODULE_4_SUBMITTED_OPTIONS],
    },
    COMPLETED: {
      OPTIONS: [...MODULE_4_COMPLETED_OPTIONS],
    },
  },
  MODULE_5: {
    SUBMITTED: {
      OPTIONS: [...MODULE_5_SUBMITTED_OPTIONS],
    },
    COMPLETED: {
      OPTIONS: [...MODULE_5_COMPLETED_OPTIONS],
    },
  },
  MODULE_6: {
    SUBMITTED: {
      OPTIONS: [...MODULE_6_SUBMITTED_OPTIONS],
    },
    COMPLETED: {
      OPTIONS: [...MODULE_6_COMPLETED_OPTIONS],
    },
  },
  PROFESSIONAL_JUDGMENT: {
    OPTIONS: [...PROFESSIONAL_JUDGMENT_OPTIONS],
  },
};